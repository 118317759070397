import Question from "../models/question";
import DailyQuestion from "../models/dailyQuestion";
import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import Answer from "../models/answer";
import { ITag } from '@fluentui/react'
import { appInsights } from '../common/telemetry/appInsights';
import { utils } from '../common/functions/utils';

const baseURL = process.env.REACT_APP_API;

const GetDailyQuestionsAsync = async (): Promise<DailyQuestion[]> => {
    const getDailyQuestionsURL = baseURL + `/api/question/getDailyQuestions`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString(),
            'Language': utils.getCurrentLanguage(),

        }
    }

    return client.fetch(getDailyQuestionsURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToDailyQuestions(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException({ exception: error });
            return Promise.reject(error);
        });
}

const GetQuestionsAsync = async (): Promise<Question[]> => {
    const getQuestionsURL = baseURL + `/api/question/getAllQuestionsByTenantId`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        }
    }
    return await pnp.fetch(getQuestionsURL, obj).then(async (response) => {
        if (!response) {
            return null;
        }
        const data = await response.json().then((json) => {
            const user = mapToQuestions(json)
            return user;
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const AddQuestionAsync = async (question: Question): Promise<boolean> => {
    const addQuestionURL = baseURL + `/api/question/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(question)
    }

    return pnp.fetch(addQuestionURL, obj).then((response) =>
        response.json()
    );
}

const UploadQuestionAsync = async (formdata: FormData, topic: string): Promise<boolean> => {
    const UploadQuestionURL = baseURL + `/api/question/upload?topic=${topic}`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        body: formdata,
    }

    return pnp.fetch(UploadQuestionURL, obj).then((response) =>

        response.json()
    );
}




const EditQuestionAsync = async (question: Question): Promise<boolean> => {
    const editQuestionURL = baseURL + `/api/question/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(question)
    }

    return pnp.fetch(editQuestionURL, obj).then((response) =>
        response.json()
    );
}

const DeleteQuestionAsync = async (questionId: string): Promise<boolean> => {
    const deleteQuestionURL = baseURL + `/api/question/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionId)
    }
    return pnp.fetch(deleteQuestionURL, obj).then((response) =>
        response.json()
    );
}


const GetQuestionCategories = async (): Promise<ITag[]> => {
    const getQuestionCategoriesURL = baseURL + `/api/question/categories`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return await pnp.fetch(getQuestionCategoriesURL, obj).then(async (response) => {
        if (!response) {
            return null;
        }
        const data = await response.json().then((json) => {
            const user = mapToQuestionCategories(json)
            return user;
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const mapToQuestionCategories = (response: any[]): ITag[] => {
    let result = response != null ? response.map((e) => mapToQuestionCategory(e)) : [];
    return result;
}
const mapToQuestionCategory = (response: any): ITag => {
    return {
        key: response,
        name: response
    }
}
const mapToQuestions = (response: any[]): Question[] => {
    let result = response != null ? response.map((e) => mapToQuestion(e)) : [];
    return result;
}

const mapToQuestion = (response: any): Question => {
    return {
        Id: response.id,
        TenantId: response.tenantId,
        SurveyJSType: response.surveyJSType,
        Title: response.title,
        Answers: mapToAnswers(response.answers),
        CorrectAnswerId: response.correctAnswerId,
        Description: response.description,
        Category: response.category,
        Difficulty: response.difficulty,
        Locale: response.locale,
        Active: response.active,
        Topic: response.topic
    };
}

const mapToAnswers = (response: any[]): Answer[] => {
    let result = response != null ? response.map((e) => mapToAnswer(e)) : [];
    return result;
}

const mapToAnswer = (response: any): Answer => {
    return {
        Id: response.id,
        Value: response.value
    };
}

const mapToDailyQuestions = (response: any[]): DailyQuestion[] => {
    let result = response != null ? response.map((e) => mapToDailyQuestion(e)) : [];
    return result;
}

const mapToDailyQuestion = (response: any): DailyQuestion => {
    return {
        Id: response.id,
        Type: response.surveyJSType,
        Title: response.title,
        Category: response.category,
        Description: response.description,
        Options: mapToOptions(response.answers),
        CorrectAnswerId: response.correctAnswerId
    };
}

const mapToOptions = (response: any[]): Answer[] => {
    let result = response != null ? response.map((e) => mapToOption(e)) : [];
    return result;
}

const mapToOption = (response: any): Answer => {
    return {
        Id: response.id,
        Value: response.value
    };
}


export const questionApi = {
    GetQuestionsAsync,
    AddQuestionAsync,
    EditQuestionAsync,
    DeleteQuestionAsync,
    GetDailyQuestionsAsync,
    UploadQuestionAsync,
    GetQuestionCategories
}
