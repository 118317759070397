import * as React from "react";
import i18next from "i18next";
import { ProgressBar } from "react-bootstrap";
import pointIcon from '../../../../../img/points-icon.png';
import LevelSettings from "../../../../../models/levelSettings";
import UserConnection from "../../../../../models/userConnection";
import User from "../../../../../models/user";

interface IUserInfoProps {
    user: User,
    userLevel: LevelSettings,
    t: any

}


export const UserInfo = (props: IUserInfoProps) => {
    const { user, userLevel, t } = props;
    const userProfileName = userLevel?.name || "Amateur";
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];


    const getTodayParticipationClass = (
        userConnection: UserConnection,
        numberOfDay: number,
        classNames: string[]
    ) => {
        if (!userConnection || !userConnection?.Id || userConnection?.Id.length === 0) return "gris";
        let result: string;

        const participation = userConnection[weekDays[numberOfDay] + "Participation"];
        const haveNotAnsweredToday =
            numberOfDay >= new Date().getDay() && !participation;

        if (participation) {
            result = classNames[0];
        } else if (haveNotAnsweredToday) {
            result = classNames[1];
        } else {
            result = classNames[2];
        }
        return result;
    }

    return <div className="col-sm-8 user-info">
        <div>
            <div className="user-name">
                <div>{user.Name} </div>
            </div>
            <div className="user-image visible-md">
                <div className="level">{userProfileName}</div>
                <div className="user-position">
                    <i>
                        {t("Home.TextoPosicion")}{" "}
                        {user?.UserPosition?.Position}
                    </i>{" "}
                    {t("Home.TextoDe")}{" "}
                    {user?.UserPosition?.TotalUsers}
                </div>
            </div>
            <div className="user-points hidden-md">
                <img
                    src={pointIcon}
                    alt="points"
                />
                <span className="puntos">
                    {user.TotalPoints}{" "}
                    {t("Home.Puntos")}
                </span>
            </div>
            <div className="text">
                {t("Home.RetoDiarioComp")}
            </div>
        </div>
        <div>
            <span>{i18next.language === "ch" && "星期"}</span>
            <div className="daily">
                {
                    weekDays.map((day, index) => (
                        index === 0 ? null :
                            <div
                                key={index}
                                className={
                                    getTodayParticipationClass(
                                        user.UserConnection,
                                        index,
                                        ["done", "", "failed"]
                                    )
                                }
                            >
                                {t(`Home.Dias.${day}`)}
                            </div>
                    ))

                }

                <ProgressBar>
                    {
                        weekDays.map((day, index) => (
                            index < 2 ? null :
                                <ProgressBar
                                    className={
                                        getTodayParticipationClass(
                                            user.UserConnection,
                                            index,
                                            [
                                                "progress-bar-success",
                                                "gris",
                                                "progress-bar-ko",
                                            ]
                                        )
                                    }
                                    now={25}
                                    key={index}
                                />
                        ))
                    }
                </ProgressBar>
            </div>
        </div>

    </div>

}