import { alertsApi } from "../../api/alertsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";

export const getUserUnreadAlertsNumber = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    alertsApi.GetUserUnreadedAlertsNumber().then((result) => {
        dispatch(getUserUnreadAlertsNumberCompleted(result));
        dispatch(httpCallEndAction());
    }).catch(error => {
        //TOASTR?
    });
}

const getUserUnreadAlertsNumberCompleted = (levels: number) => ({
    type: actionsDefinition.alerts.GET_USER_UNREAD_ALERTS_NUMBER,
    payload: levels
});