export default interface UploadedBanners {
    allChecked: boolean;
    desktopBannerEs: boolean;
    desktopBannerEn: boolean;
    mobileBannerEs: boolean;
    mobileBannerEn: boolean;
}

export const InitUploadedBanners = () => {
    let newUploadedBanners: UploadedBanners = {
        allChecked: false,
        desktopBannerEs: false,
        desktopBannerEn: false,
        mobileBannerEs: false,
        mobileBannerEn: false
    };
    return newUploadedBanners;
};