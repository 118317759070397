import { actionsDefinition } from '../actions/actionsDefinition';
import User from '../models/user';

export const top5UsersReducer = (state: User[] = [], action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_TOP5_USERS:
            return handleTop5UsersCompleted(state, action.payload);
    }
    return state;
};

const handleTop5UsersCompleted = (state: User[], payload: User[]) => {
    return payload;
};