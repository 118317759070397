import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { userApi } from "../../api/userApi";
import User from '../../models/user';

export const loadCurrentUserAction = () =>  (dispatch: any) => {
    dispatch(httpCallStartAction());
     userApi.GetCurrentUserAsync().then(async (result) => {
        dispatch( loadCurrentUserCompleted(result));
    })

    const loadCurrentUserCompleted = (result: User) => ({
        type: actionsDefinition.LOAD_CURRENT_USER,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}