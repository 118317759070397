import i18next from "i18next";
import { userApi } from "./api/userApi";

import detector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationCH from './locales/ch/translation.json';
import translationPT from './locales/pt/translation.json';
export const LANG_RESOURCES =
{
	es: {
		resource: translationES,
		lng: {
			en: "Inglés",
			es: "Español",
			fr: "Francés",
			pt: "Portugués",
			ch: "Chino"
		}
	},
	en: {
		resource: translationEN,
		lng: {
			"en": "English",
			"es": "Spanish",
			"fr": "French",
			"pt": "Portuguese",
			"ch": "Chinese"
		}

	},
	fr: {
		resource: translationFR,
		lng: {
			"en": "Anglais",
			"es": "Espagnol",
			"fr": "Français",
			"pt": "Portugais",
			"ch": "Chinois"
		}
	},
	pt: {
		resource: translationPT,
		lng: {
			"en": "Inglês",
			"es": "Espanhol",
			"fr": "Francês",
			"pt": "Português",
			"ch": "Chinês"
		}
	},
	ch: {
		resource: translationCH,
		lng: {
			"en": "英语",
			"es": "西班牙语",
			"fr": "法语",
			"pt": "葡萄牙语",
			"ch": "中文"
		}
	}
}
export const LIST_LANGUAGES = Object.keys(LANG_RESOURCES);

const getLanguageResources = () => {
	const resources = {};
	LIST_LANGUAGES.forEach((lang) => {
		resources[lang] = {
			common: LANG_RESOURCES[lang].resource
		}
	});
	return resources;
}
i18next
	.use(detector)
	.init({
		whitelist: LIST_LANGUAGES,
		load: 'languageOnly',
		fallbackLng: 'en',
		resources: getLanguageResources(),
		detection: {
			order: ['querystring', 'cookie', 'localStorage', 'navigator'],
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			caches: ['localStorage', 'cookie']
		}
	});

const defaultLng: string = LIST_LANGUAGES[0];
let selectedLng: string = i18next.language !== null ? i18next.language : defaultLng;

if (selectedLng.length >= 2) {
	selectedLng = selectedLng.split('-')[0];

	if (LIST_LANGUAGES.some((lng: string) => lng === selectedLng)) {
		i18next.changeLanguage(selectedLng);
	} else {
		i18next.changeLanguage(defaultLng);
	}

} else {
	i18next.changeLanguage(defaultLng);
}
i18next.on('languageChanged', (lng: string) => {
	userApi.UpdateLanguage(lng);
});
export default i18next;