import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../../common/components/button/button";
import '../css/style.css';
import User from "../../../models/user";

export interface IEditUserModalProps {
  show: boolean;
  //onHide: any;
  user: User;
  header: IEditUserModalHeaderProps;
  body: IEditUserModalBodyProps;
  footer: IEditUserModalFooterProps;
}

export interface IEditUserModalHeaderProps {
    title: string;
    onClick: any;
  }

export interface IEditUserModalBodyProps {
    nameText: string;
    namePlhd: string;
    onChangeName:any;
    onChangeRol: any;
    emailText: string;
    rolText: string;
    optioUserText: string;
    optionAdminText: string;
  }


  export interface IEditUserModalFooterProps {
     onClickCancel: any;
    onClickAccept:any;
    textEdit: string;
    textCancel: string;
  }
  
  
export const EditUserModal = function (props: IEditUserModalProps) {
  return (
    <>
      <Modal
        show={props.show}
        >
        <Modal.Header>
          <Modal.Title className=" d-flex justify-content-between w-100">
            <div>{props.header.title}</div>
            <div className="botonCerrar">
              <Button icon="fa-times" clickEvent={props.header.onClick} text={""}/>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form>
          <div className="form-group">
            <label className="mx-2">{props.body.nameText}</label>
            <input
              id="name"
              type="text"
              className="form-control"
              placeholder={props.body.namePlhd}
              value={props.user.Name || ""}
              onChange={(c) => props.body.onChangeName(c) }
            />
          </div>
          <div className="form-group">
            <label className="mx-2">{props.body.emailText}</label>
            <input
              id="email"
              type="text"
              className="form-control"
              value={props.user.Email}
              disabled
            />
          </div>
          <div className="form-group">
            <label className="mx-2">
              {props.body.rolText}
            </label>
            <select
              id="role"
              className="custom-select"
              value={props.user.Role}
              onChange={(c) => props.body.onChangeRol(c) }
            >
              <option value="User">
                {props.body.optioUserText}
              </option>
              <option value="Admin">
              {props.body.optionAdminText}
              </option>
            </select>
          </div>
        </form>
      </Modal.Body>
      
        <Modal.Footer>
        <Button className="btn-secondary" clickEvent={props.footer.onClickCancel} text={props.footer.textCancel} icon={""}/>
        <Button className="btn-primary" clickEvent={props.footer.onClickAccept} disabled={props.user.Name === ""} icon={""} text={props.footer.textEdit}/>
        </Modal.Footer>
      </Modal>
    </>
  );
};
