import { ITag } from "@fluentui/react";
import * as React from "react";
import { I18nextProvider, TransProps, translate } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { LoadingComponent } from "../../common/components/Loading/loading";
import { Button } from "../../common/components/button/button";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { Select } from "../../common/components/select/select";
import { MessageAlertType } from "../../common/constants/enum";
import PaginationComponent from "../../common/pagination";
import Question from "../../models/question";
import Settings from "../../models/settings";
import Topic from "../../models/topic";
import { ModalConfirm } from "../adminUser/modal/confirmModal";
import "./css/style.css";
import { QuestionFormModal } from "./modal/questionForm";
import { UploadQuestionComponent } from "./modal/uploadQuestion";
import { QuestionComponent } from "./questionComponent";
import i18next, { LANG_RESOURCES, LIST_LANGUAGES } from "../../i18n";

export interface IProjectsProps extends TransProps {
  getQuestions: () => Promise<Question[]>;
  addQuestion: (question: Question, successMessage: string) => Promise<boolean>;
  editQuestion: (
    question: Question,
    successMessage: string
  ) => Promise<boolean>;
  deleteQuestion: (
    questionId: string,
    successMessage: string
  ) => Promise<boolean>;
  uploadQuestion: (
    formdata: FormData,
    successMessage: string,
    topic: string
  ) => Promise<boolean>;
  getQuestionCategories: () => Promise<string[]>;
  getSettings: () => void;
  settings: Settings;
  categories: ITag[];
  questions: Question[];
  t: any;
  GetTopics: (isPublic: boolean) => Promise<Topic[]>;
  topics: Topic[];
}

export interface IProjectsState {
  panelNewQuestionIsOpen: boolean;
  panelEditQuestionIsOpen: boolean;
  panelUploadIsOpen: boolean;
  panelDeleteQuestionOpen: boolean;

  panelQuestion: Question;

  t: any;
  pregunta: string;
  categoria: string;
  dificultad: number;
  estado: string;
  idioma: string;
  files;
  errors;
  totalPages: number;
  page: number;
  categories: ITag[];
  selectedTopic: string;
  selectedFile: string;
  topic: string;
}

class AdminQuestionsPage extends React.Component<
  RouteComponentProps & IProjectsProps,
  IProjectsState
> {
  isLoading = true;
  constructor(props: any) {
    super(props);

    this.state = {
      panelNewQuestionIsOpen: false,
      panelDeleteQuestionOpen: false,
      panelEditQuestionIsOpen: false,
      panelUploadIsOpen: false,
      panelQuestion: {
        Id: "",
        TenantId: "",
        SurveyJSType: "rating",
        Title: "",
        Answers: [
          { Id: "item1", Value: "" },
          { Id: "item2", Value: "" },
          { Id: "item3", Value: "" },
        ],
        CorrectAnswerId: "item1",
        Description: "",
        Category: "Teams",
        Difficulty: 1,
        Locale: "ES",
        Active: true,
        Topic: "",
      } as Question,
      t: I18nextProvider,
      pregunta: "",
      categoria: "Todas",
      dificultad: 0,
      estado: "X",
      idioma: "X",
      files: [],
      errors: [],
      totalPages: 1,
      page: 1,
      categories: [] as ITag[],
      selectedTopic: "",
      selectedFile: "",
      topic: "",
    };

    this.renderAddQuestionModal = this.renderAddQuestionModal.bind(this);
    this.renderEditQuestionModal = this.renderEditQuestionModal.bind(this);
    this.showNewQuestionModal = this.showNewQuestionModal.bind(this);
    this.showFileUploadModal = this.showFileUploadModal.bind(this);
    this.showEditQuestionModal = this.showEditQuestionModal.bind(this);
    this.renderDeleteQuestionModal = this.renderDeleteQuestionModal.bind(this);
    this.handleNewQuestion = this.handleNewQuestion.bind(this);
    this.handleEditQuestion = this.handleEditQuestion.bind(this);
    this.hideEditQuestionModal = this.hideEditQuestionModal.bind(this);
    this.showActiveQuestionModal = this.showActiveQuestionModal.bind(this);
    this.activeCategoria = this.activeCategoria.bind(this);
  }

  async componentDidMount() {
    await this.props.getQuestions();
    await this.props.getQuestionCategories();
    await this.props.getSettings();
    this.props.GetTopics(true);
    this.isLoading = false;
  }

  componentDidUpdate(prevProps: IProjectsProps, prevState: IProjectsState) {
    if (this.props !== prevProps) {
      if (
        this.props.questions &&
        this.props.questions !== prevProps.questions
      ) {
        let morePages = this.props.questions.length % 10 === 0;
        let pages = this.props.questions.length / 10;
        if (
          morePages &&
          (prevState.page === pages || prevState.page - 1 === pages)
        ) {
          this.setState({
            page: this.props.questions.length / 10,
          });
        }
      }
      if (
        this.props.categories &&
        this.props.categories !== prevProps.categories
      ) {
        let filterCategories = this.props.categories;
        filterCategories = filterCategories.map((e) => {
          return {
            key: e.key
              .toString()
              .replace(/\s+/g, " ")
              .replace(/^\s+|\s+$/, ""),
            name: e.name.replace(/\s+/g, " ").replace(/^\s+|\s+$/, ""),
          } as ITag;
        });
        filterCategories = this.eliminarObjetosDuplicados(
          filterCategories,
          "key"
        );
        filterCategories = filterCategories.sort((a, b) => {
          return a.key > b.key ? 1 : -1;
        });
        this.setState({
          categories: filterCategories,
        });
      }
    }
  }

  private eliminarObjetosDuplicados(arr, prop) {
    var nuevoArray = [];
    var lookup = {};

    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }

    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }

    return nuevoArray;
  }

  private showFileUploadModal(open: boolean) {
    this.setState({
      panelUploadIsOpen: open,
    });
    if (!open) {
      this.setState({
        selectedFile: ""
      })
    }
  }

  private showNewQuestionModal(open: boolean) {
    this.setState({
      panelNewQuestionIsOpen: open,
      panelQuestion: {
        Id: "",
        TenantId: "",
        SurveyJSType: "rating",
        Title: "",
        Answers: [
          { Id: "item1", Value: "" },
          { Id: "item2", Value: "" },
          { Id: "item3", Value: "" },
        ],
        CorrectAnswerId: "item1",
        Description: "",
        Category: "",
        Difficulty: 1,
        Locale: "ES",
        Active: true,
        Topic: "",
      } as Question,
    });
  }

  // private showDeleteCategoryModal= (open: boolean) =>{
  //     this.setState({
  //         panelDeleteCategoryOpen: open,
  //     })
  // }

  private showEditQuestionModal(q: Question) {
    this.setState({
      panelEditQuestionIsOpen: true,
      panelQuestion: {
        Id: q.Id,
        TenantId: q.TenantId,
        SurveyJSType: q.SurveyJSType,
        Title: q.Title,
        Answers: q.Answers,
        CorrectAnswerId: q.CorrectAnswerId,
        Description: q.Description,
        Category: q.Category,
        Difficulty: q.Difficulty,
        Locale: q.Locale,
        Active: q.Active,
        Topic: q.Topic,
      } as Question,
    });
  }

  private hideEditQuestionModal() {
    this.setState({
      panelEditQuestionIsOpen: false,
      panelQuestion: {
        Id: "",
        TenantId: "",
        SurveyJSType: "rating",
        Title: "",
        Answers: [
          { Id: "item1", Value: "" },
          { Id: "item2", Value: "" },
          { Id: "item3", Value: "" },
        ],
        CorrectAnswerId: "item1",
        Description: "",
        Category: "Teams",
        Difficulty: 1,
        Locale: "ES",
        Active: true,
        Topic: "",
      } as Question,
    });
  }

  private handleNewQuestion() {
    this.props.addQuestion(
      this.state.panelQuestion,
      this.props.t("ToastrMessages.Settings.AddQuestion")
    );
    this.showNewQuestionModal(false);
  }

  private handleEditQuestion() {
    this.props.editQuestion(
      this.state.panelQuestion,
      this.props.t("ToastrMessages.Settings.UpdateQuestion")
    );
    this.hideEditQuestionModal();
  }

  private renderDeleteQuestionModal() {
    const header = {
      title: this.props.t("AdminQuestion.DeleteQuestionTitle"),
      onClick: this.ManageDeleteQuestion,
    };

    const body = {
      text:
        "<div> " +
        this.props.t("AdminQuestion.DeleteQuestionText") +
        " <br /><br /> " +
        this.props.t("AdminQuestion.Question") +
        "<b>" +
        this.state.panelQuestion.Title +
        "</b></div>",
    };

    const footer = {
      onClick: this.ManageDeleteQuestion,
      textAccept: this.props.t("AdminQuestion.Botones.Borrar"),
      textCancel: this.props.t("AdminQuestion.Botones.Cancelar"),
    };

    return (
      <ModalConfirm
        show={this.state.panelDeleteQuestionOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  }

  private ManageDeleteQuestion = (resu) => {
    if (resu) {
      this.props.deleteQuestion(
        this.state.panelQuestion.Id,
        this.props.t("ToastrMessages.Settings.DeleteQuestion")
      );
    }
    this.setState({
      panelDeleteQuestionOpen: false,
    });
  };

  private showActiveQuestionModal(q: Question) {
    let editQuestion: Question = {
      Id: q.Id,
      TenantId: q.TenantId,
      SurveyJSType: q.SurveyJSType,
      Title: q.Title,
      Answers: [q.Answers[0], q.Answers[1], q.Answers[2]],
      CorrectAnswerId: q.CorrectAnswerId,
      Description: q.Description,
      Category: q.Category,
      Difficulty: q.Difficulty,
      Locale: q.Locale,
      Active: !q.Active,
      Topic: q.Topic,
    };
    this.props.editQuestion(
      editQuestion,
      this.props.t("ToastrMessages.Settings.UpdateQuestion")
    );
  }

  private _getTextFromItem(item: ITag): string {
    return item.name;
  }

  private _onFilterChanged = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? this.state.categories
        .filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
        )
        .filter((tag) => !this._listContainsDocument(tag, tagList))
      : [];
  };

  private _listContainsDocument(tag: ITag, tagList?: ITag[]) {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return (
      tagList.filter((compareTag) => compareTag.key === tag.key).length > 0
    );
  }

  private _onItemSelected = (item: ITag): ITag | null => {
    if (!!item) {
      this.setState({
        panelQuestion: {
          ...this.state.panelQuestion,
          Category: item.name,
        },
      });
    }
    return item;
  };

  private _onItemBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!!event.target.value) {
      this.setState({
        panelQuestion: {
          ...this.state.panelQuestion,
          Category: event.target.value,
        },
      });
    } else if (!event.target.innerText) {
      this.setState({
        panelQuestion: {
          ...this.state.panelQuestion,
          Category: "",
        },
      });
    }
  };

  private _onCategoryInputChange = (value: string): string => {
    if (!!value) {
      this.setState({
        panelQuestion: {
          ...this.state.panelQuestion,
          Category: value,
        },
      });
    }
    return value;
  };

  private _onChange = (item: ITag[]) => {
    if (item.length === 0) {
      this.setState({
        panelQuestion: {
          ...this.state.panelQuestion,
          Category: "",
        },
      });
    }
  };

  private readonly initPage = () => {
    this.setState({ page: 1 });
  };
  private readonly prevPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage - 1 });
  };
  private readonly paginate = (page: number) => {
    this.setState({ page: page });
  };
  private readonly nextPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage + 1 });
  };
  private readonly lastPage = () => {
    this.setState({ page: this.state.totalPages });
  };

  renderOption(item: string, index: number): JSX.Element {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    );
  }
  renderAddQuestionModal() {

    const lstDifficulty = [
      { value: "1", text: this.props.t("AdminQuestion.Dificultad.Opcion1") },
      { value: "2", text: this.props.t("AdminQuestion.Dificultad.Opcion2") },
      { value: "3", text: this.props.t("AdminQuestion.Dificultad.Opcion3") },
    ];
    const lstCorrectAnswer = [
      { value: "item1", text: this.props.t("AdminQuestion.Correcta.Opcion1") },
      { value: "item2", text: this.props.t("AdminQuestion.Correcta.Opcion2") },
      { value: "item3", text: this.props.t("AdminQuestion.Correcta.Opcion3") },
    ];

    const tagPickerConfig = {
      onInputChange: this._onCategoryInputChange,
      onResolveSuggestions: this._onFilterChanged,
      getTextFromItem: this._getTextFromItem,
      onItemSelected: this._onItemSelected,
      onBlur: this._onItemBlur,
      onChange: this._onChange,
      pickerSuggestionsProps: {
        suggestionsHeaderText: this.props.t(
          "AdminQuestion.Categorias.Suggested"
        ),
        noResultsFoundText: this.props.t(
          "AdminQuestion.Categorias.NoSuggestion"
        ),
      },
      itemLimit: 1,
    };

    const header = {
      title: this.props.t("AdminQuestion.TituloNuevaPreg"),
      onClick: this.ManageAddQuestion,
    };
    const footer = {
      onClick: this.ManageAddQuestion,
      textAccept: this.props.t("AdminQuestion.Botones.Guardar"),
      textCancel: this.props.t("AdminQuestion.Botones.Cancelar"),
    };
    const body = {
      langTitle: this.props.t("AdminQuestion.Idiomas.Titulo"),
      changeEventLang: this.changeEventLang,
      changeEventDiff: this.changeEventDiff,
      difficultyTitle: this.props.t("AdminQuestion.Dificultad.Titulo"),
      lstDifficulty: lstDifficulty,
      categoryTitle: this.props.t("AdminQuestion.Categorias.Titulo"),
      tagPickerConfig: tagPickerConfig,
      questionText: this.props.t("AdminQuestion.Pregunta"),
      questionTitle: this.state.panelQuestion.Title,
      changeventQuestion: this.changeventQuestion,

      plhdQuestion: this.props.t("AdminQuestion.QuestionPlaceholder"),

      questionOp1: this.props.t("AdminQuestion.Opcion1"),
      changeventQuestionOp1: this.changeventQuestionOp1,

      questionOp2: this.props.t("AdminQuestion.Opcion2"),
      changeventQuestionOp2: this.changeventQuestionOp2,

      questionOp3: this.props.t("AdminQuestion.Opcion3"),
      changeventQuestionOp3: this.changeventQuestionOp3,

      correctAnswerText: this.props.t("AdminQuestion.Correcta.Titulo"),
      correctAnswer: this.state.panelQuestion.CorrectAnswerId,

      changeEventCorrectAnswer: this.changeEventCorrectAnswer,

      lstCorrectAnswer: lstCorrectAnswer,
      descriptionText: this.props.t("AdminQuestion.Descripcion"),
      description: this.state.panelQuestion.Description,
      plhdDescrition: this.props.t("AdminQuestion.DescriptionPlaceholder"),

      changeventDescription: this.changeventDescription,

      topicText: this.props.t("AdminQuestion.Topic"),
      placeholderTopic: this.props.t("AdminQuestion.Topic"),
      changeventTopic: this.ChangEventTopic,
      topics: this.GetTopicsFiltered(),
    };
    return (
      <QuestionFormModal
        show={this.state.panelNewQuestionIsOpen}
        header={header}
        body={body}
        footer={footer}
        question={this.state.panelQuestion}
      />
    );
  }

  ManageAddQuestion = (resu) => {
    if (resu) {
      this.handleNewQuestion();
    } else {
      this.showNewQuestionModal(false);
    }
  };
  changeEventLang = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Locale: c.target.value,
      },
    });
  };
  changeEventDiff = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Difficulty: parseInt(c.target.value),
      },
    });
  };
  changeventQuestionOp1 = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Answers: [
          { Id: "item1", Value: c.target.value },
          this.state.panelQuestion.Answers[1],
          this.state.panelQuestion.Answers[2],
        ],
      },
    });
  };

  changeventQuestionOp2 = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Answers: [
          this.state.panelQuestion.Answers[0],
          { Id: "item2", Value: c.target.value },
          this.state.panelQuestion.Answers[2],
        ],
      },
    });
  };
  changeventQuestionOp3 = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Answers: [
          this.state.panelQuestion.Answers[0],
          this.state.panelQuestion.Answers[1],
          { Id: "item3", Value: c.target.value },
        ],
      },
    });
  };
  changeEventCorrectAnswer = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        CorrectAnswerId: c.target.value,
      },
    });
  };

  changeventQuestion = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Title: c.target.value,
      },
    });
  };
  changeventDescription = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Description: c.target.value,
      },
    });
  };

  ChangEventTopic = (c) => {
    this.setState({
      panelQuestion: {
        ...this.state.panelQuestion,
        Topic: c.target.value,
      },
    });
  };


  renderEditQuestionModal() {

    const lstDifficulty = [
      { value: "1", text: this.props.t("AdminQuestion.Dificultad.Opcion1") },
      { value: "2", text: this.props.t("AdminQuestion.Dificultad.Opcion2") },
      { value: "3", text: this.props.t("AdminQuestion.Dificultad.Opcion3") },
    ];
    const lstCorrectAnswer = [
      { value: "item1", text: this.props.t("AdminQuestion.Correcta.Opcion1") },
      { value: "item2", text: this.props.t("AdminQuestion.Correcta.Opcion2") },
      { value: "item3", text: this.props.t("AdminQuestion.Correcta.Opcion3") },
    ];

    const tagPickerConfig = {
      onInputChange: this._onCategoryInputChange,
      onResolveSuggestions: this._onFilterChanged,
      getTextFromItem: this._getTextFromItem,
      onItemSelected: this._onItemSelected,
      onBlur: this._onItemBlur,
      onChange: this._onChange,
      pickerSuggestionsProps: {
        suggestionsHeaderText: this.props.t(
          "AdminQuestion.Categorias.Suggested"
        ),
        noResultsFoundText: this.props.t(
          "AdminQuestion.Categorias.NoSuggestion"
        ),
      },
      itemLimit: 1,
    };

    const header = {
      title: this.props.t("AdminQuestion.TituloNuevaPreg"),
      onClick: this.ManageEditQuestion,
    };
    const footer = {
      onClick: this.ManageEditQuestion,
      textAccept: this.props.t("AdminQuestion.Botones.Guardar"),
      textCancel: this.props.t("AdminQuestion.Botones.Cancelar"),
    };
    const body = {
      langTitle: this.props.t("AdminQuestion.Idiomas.Titulo"),
      changeEventLang: this.changeEventLang,
      changeEventDiff: this.changeEventDiff,
      difficultyTitle: this.props.t("AdminQuestion.Dificultad.Titulo"),
      lstDifficulty: lstDifficulty,
      categoryTitle: this.props.t("AdminQuestion.Categorias.Titulo"),
      tagPickerConfig: tagPickerConfig,
      questionText: this.props.t("AdminQuestion.Pregunta"),
      questionTitle: this.state.panelQuestion.Title,
      changeventQuestion: this.changeventQuestion,

      plhdQuestion: this.props.t("AdminQuestion.QuestionPlaceholder"),

      questionOp1: this.props.t("AdminQuestion.Opcion1"),
      changeventQuestionOp1: this.changeventQuestionOp1,

      questionOp2: this.props.t("AdminQuestion.Opcion2"),
      changeventQuestionOp2: this.changeventQuestionOp2,

      questionOp3: this.props.t("AdminQuestion.Opcion3"),
      changeventQuestionOp3: this.changeventQuestionOp3,

      correctAnswerText: this.props.t("AdminQuestion.Correcta.Titulo"),
      correctAnswer: this.state.panelQuestion.CorrectAnswerId,

      changeEventCorrectAnswer: this.changeEventCorrectAnswer,

      lstCorrectAnswer: lstCorrectAnswer,
      descriptionText: this.props.t("AdminQuestion.Descripcion"),
      description: this.state.panelQuestion.Description,
      plhdDescrition: this.props.t("AdminQuestion.DescriptionPlaceholder"),

      changeventDescription: this.changeventDescription,

      topicText: this.props.t("AdminQuestion.Topic"),
      placeholderTopic: this.props.t("AdminQuestion.Topic"),
      changeventTopic: this.ChangEventTopic,
      topics: this.GetTopicsFiltered(),
    };
    return (
      <QuestionFormModal
        question={this.state.panelQuestion}
        show={this.state.panelEditQuestionIsOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  }

  private ManageEditQuestion = (resu) => {
    if (resu) {
      this.handleEditQuestion();
    }
    this.hideEditQuestionModal();
  };

  renderUpload() {
    var topics = this.GetTopicsFiltered();
    return (
      <UploadQuestionComponent
        onSelectTopic={this.SelectTopic}
        onSelectedFile={(e) => {
          this.setState({
            selectedFile: e.target.value,
          });
        }}
        topics={topics}
        panelUploadIsOpen={this.state.panelUploadIsOpen}
        showFileUploadModal={this.showFileUploadModal}
        t={this.props.t}
        btnDisabled={
          this.state.selectedTopic.length == 0 ||
          this.state.selectedFile.length == 0
        }
        selectedTopic={this.state.selectedTopic}
        uploadQuestion={this.props.uploadQuestion}
        selectedFile={this.state.selectedFile}
      />
    );
  }

  async AJAXSubmit() {
    const formData = new FormData(document.forms[0]);
    this.props.uploadQuestion(
      formData,
      this.props.t("ToastrMessages.Settings.UploadQuestions"),
      this.state.selectedTopic
    );
  }

  clearFilter() {
    this.setState({
      pregunta: "",
      categoria: "Todas",
      dificultad: 0,
      estado: "X",
      idioma: "X",
      page: 1,
    });
  }

  handleChangeEstado = (event) => {
    this.setState({
      estado: event.target.value,
      page: 1,
    });
  };
  handleChangeLanguage = (event) => {
    this.setState({
      idioma: event.target.value,
      page: 1,
    });
  };
  handleChangeDificultad = (event) => {
    var dif = parseInt(event.target.value);
    this.setState({
      dificultad: dif,
      page: 1,
    });
  };

  handleChangePregunta = (event) => {
    this.setState({
      pregunta: event.target.value,
      page: 1,
    });
  };
  handleChangeCategoria = (event) => {
    this.setState({
      categoria: event.target.value,
      page: 1,
    });
  };

  activeCategoria = (question: Question) => {
    var result: JSX.Element = null;
    if (question.Active) {
      let category = this.props.settings.categoriesDisabled?.find(
        (e) => e === question.Category
      );
      if (category) {
        result = (
          <span className="pl-2 text-warning">
            {this.props.t("AdminQuestion.Estado.CategoriaDesactivada")}
          </span>
        );
      } else {
        result = (
          <span className="pl-2 text-success">
            {this.props.t("AdminQuestion.Estado.Activo")}
          </span>
        );
      }
    } else {
      result = (
        <span className="pl-2 text-danger">
          {this.props.t("AdminQuestion.Estado.Inactivo")}
        </span>
      );
    }

    return result;
  };

  public render() {
    const questions = this.props.questions;
    if (questions != null) {
      questions.sort((a, b) => {
        return a.Title > b.Title ? 1 : -1;
      });
    }
    var topics = this.GetTopicsFiltered();
    return (
      <div>
        {this.isLoading ? (
          <LoadingComponent className="loading-center-in-component" />
        ) : questions != null ? (
          <div className="panelAdminQuestion">
            {this.renderAddQuestionModal()}
            {this.renderEditQuestionModal()}
            {this.renderUpload()}
            {this.renderDeleteQuestionModal()}

            <div className="row">
              <div className="col-12">
                <Button
                  className={"btn-primary custom  mb-1"}
                  clickEvent={() => {
                    this.showNewQuestionModal(true);
                  }}
                  icon={"fa-plus mr-2"}
                  text={this.props.t("AdminQuestion.Botones.Add")}
                />

                <Button
                  className={"btn-primary custom mb-1"}
                  clickEvent={() => {
                    this.showFileUploadModal(true);
                  }}
                  icon={"fa-file-upload mr-2"}
                  text={this.props.t("AdminQuestion.Botones.Importar")}
                />

                <Button
                  className={"btn-primary custom"}
                  clickEvent={() => {
                    window.open(this.props.t("QuestionTemplateUrl"));
                  }}
                  icon={"fa-file-download mr-2"}
                  text={this.props.t("AdminQuestion.DescargarPlantilla")}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-2 col-sm-6 col-md-4 mb-1">
                <p>{this.props.t("AdminQuestion.BuscarPregunta")}</p>
                <input
                  type="text"
                  value={this.state.pregunta}
                  className="form-control"
                  placeholder={this.props.t("AdminQuestion.BuscarPregunta")}
                  onChange={this.handleChangePregunta}
                />
              </div>
              <div className=" col-lg-2 col-sm-6 mb-1 col-md-4 ">
                <p>{this.props.t("AdminQuestion.Topic")}</p>
                <div className="isPublic-filter">
                  <Select
                    placeholder={this.props.t("AdminQuestion.Topic")}
                    className={"custom-select"}
                    changeEvent={(e) =>
                      this.setState({
                        topic: e.target.value,
                      })
                    }
                    lstOptions={topics}
                  />
                </div>
              </div>
              <div className=" col-lg-2 col-sm-6  col-md-4 mb-1">
                <p>{this.props.t("AdminQuestion.Categorias.Titulo")}</p>
                <select
                  title={this.props.t("AdminQuestion.Categorias.Titulo")}
                  value={this.state.categoria}
                  className="custom-select"
                  onChange={this.handleChangeCategoria}
                >
                  <option value="Todas">
                    {this.props.t("AdminQuestion.Categorias.Todas")}
                  </option>
                  {this.state.categories?.map((option: ITag, index: number) =>
                    this.renderOption(option.name, index)
                  )}
                </select>
              </div>
              <div className="col-lg-2 col-sm-6 col-md-4 ">
                <p>{this.props.t("AdminQuestion.Dificultad.Titulo")}</p>
                <select
                  value={this.state.dificultad}
                  className="custom-select"
                  onChange={this.handleChangeDificultad}
                >
                  <option value="0">
                    {this.props.t("AdminQuestion.Dificultad.Todas")}
                  </option>
                  <option value="1">
                    1 - {this.props.t("AdminQuestion.Dificultad.Opcion1")}
                  </option>
                  <option value="2">
                    2 - {this.props.t("AdminQuestion.Dificultad.Opcion2")}
                  </option>
                  <option value="3">
                    3 - {this.props.t("AdminQuestion.Dificultad.Opcion3")}
                  </option>
                </select>
              </div>
              <div className=" col-lg-1 col-sm-6 mb-1 col-md-4 ">
                <p>{this.props.t("AdminQuestion.Estado.Titulo")}</p>
                <select
                  value={this.state.estado}
                  className="custom-select"
                  onChange={this.handleChangeEstado}
                >
                  <option value="X">
                    {this.props.t("AdminQuestion.Estado.Todos")}
                  </option>
                  <option value="true">
                    {this.props.t("AdminQuestion.Estado.Activo")}
                  </option>
                  <option value="false">
                    {this.props.t("AdminQuestion.Estado.Inactivo")}
                  </option>
                </select>
              </div>
              <div className=" col-lg-1 col-sm-6 mb-1 col-md-4 ">
                <p>{this.props.t("AdminQuestion.Idioma.Titulo")}</p>
                <select
                  value={this.state.idioma}
                  className="custom-select"
                  onChange={this.handleChangeLanguage}
                >
                  <option value="X">
                    {this.props.t("AdminQuestion.Idioma.Todos")}
                  </option>
                  {
                    LIST_LANGUAGES.map((lang, index) => {
                      return <option key={index} value={lang}>
                        {LANG_RESOURCES[i18next.language].lng[lang]}</option>
                    })
                  }
                </select>
              </div>
              <div className=" col-lg-2 col-sm-6 mb-1 d-flex justify-content-center align-items-center col-md-4 ">
                <button
                  className="btn"
                  onClick={() => {
                    this.clearFilter();
                  }}
                >
                  <i className="fas fa-filter mr-2"></i>
                  {this.props.t("AdminQuestion.BorrarFiltros")}
                </button>
              </div>
            </div>
            {questions.length > 0 ? (
              <div>
                <table className="table questionTable">
                  <tbody>
                    <tr className="row pl-10">
                      <th className="col-6 col-sm-5 col-lg-2">
                        <label className="text-secondary ">
                          {this.props.t("AdminQuestion.Pregunta")}
                        </label>
                      </th>
                      <th className="col-2  d-none d-lg-block ">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Topic")}
                        </label>
                      </th>
                      <th className="col-2 d-none d-sm-block  col-sm-3 col-lg-2">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Categorias.Titulo")}
                        </label>
                      </th>
                      <th className="col-2 d-none d-lg-block">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Dificultad.Titulo")}
                        </label>
                      </th>
                      <th className="col-1 d-none d-lg-block">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Estado.Titulo")}
                        </label>
                      </th>
                      <th className="col-1  d-none d-lg-block ">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Idioma.Titulo")}
                        </label>
                      </th>

                      <th className="col-6  col-sm-4 col-lg-2">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Actions")}
                        </label>
                      </th>
                    </tr>

                    {this.renderAllQuestions(
                      this.filtrar(questions),
                      this.props.t
                    )}
                  </tbody>
                </table>

                {this.state.totalPages > 1 ? (
                  <PaginationComponent
                    totalPages={this.state.totalPages}
                    currentPage={this.state.page}
                    itemRange={4}
                    initPage={this.initPage}
                    prevPage={this.prevPage}
                    paginate={this.paginate}
                    nextPage={this.nextPage}
                    lastPage={this.lastPage}
                  />
                ) : null}
              </div>
            ) : (
              <MessagePanel
                type={MessageAlertType.Info}
                title=""
                message={this.props.t("AdminPanel.NoQuestions")}
              />
            )}
          </div>
        ) : (
          <MessagePanel
            type={MessageAlertType.Warning}
            title={this.props.t("AdminPanel.AccesoDen")}
            message={this.props.t("AdminPanel.AccesoDenMjs")}
          />
        )}
      </div>
    );
  }
  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  filtrar(questions: Question[]) {
    if (this.state.dificultad !== 0) {
      questions = questions.filter(
        (item) => item.Difficulty === this.state.dificultad
      );
    }
    if (this.state.categoria !== "Todas") {
      questions = questions.filter((item) =>
        item.Category.toLowerCase().includes(this.state.categoria.toLowerCase())
      );
    }
    if (this.state.estado !== "X") {
      questions = questions.filter((item) =>
        item.Active.toString()
          .toLowerCase()
          .includes(this.state.estado.toLowerCase())
      );
    }
    if (this.state.idioma !== "X") {
      questions = questions.filter((item) =>
        item.Locale.toString().toLocaleLowerCase() === this.state.idioma.toLocaleLowerCase()
      );
    }

    if (this.state.topic !== "" && this.state.topic.length > 0) {
      questions = questions.filter(
        (item) =>
          item.Topic !== null &&
          item.Topic !== undefined &&
          item.Topic.toLowerCase().includes(this.state.topic.toLowerCase())
      );
    }
    questions = questions.filter((item) =>
      this.removeAccents(item.Title.toLowerCase()).includes(
        this.removeAccents(this.state.pregunta.toLowerCase())
      )
    );

    if (
      Math.ceil(questions.length / 10) !== this.state.totalPages &&
      questions.length > 0
    ) {
      this.setState({
        totalPages: Math.ceil(questions.length / 10),
      });
    }
    return questions.slice(this.state.page * 10 - 10, this.state.page * 10);
  }

  private readonly changePage = (page: number) => {
    this.setState({ page: page });
  };

  private renderAllQuestions = (questions: Question[], t: any) => {
    return questions.map((q, index) => (
      <QuestionComponent
        key={index}
        question={q}
        text={t}
        settings={this.props.settings}
        showEditQuestionModal={this.showEditQuestionModal}
        showActiveQuestionModal={this.showActiveQuestionModal}
        showDeleteQuestionModal={this.ShowDeleteQuestionModal}
      />
    ));
  };

  ShowDeleteQuestionModal = (q) => {
    this.setState({
      panelQuestion: q,
      panelDeleteQuestionOpen: true,
    });
  };

  GetTopicsFiltered = () => {
    var topics = [
      {
        text: this.props.t("AdminQuestion.SelectTopic"),
        value: "",
      },
    ];

    this.props.topics.forEach((topic) => {
      var item = {
        value: topic.title,
        text: topic.title,
      };
      topics.push(item);
    });
    return topics;
  };

  SelectTopic = (value) => {
    if (value.length > 0) {
      this.setState({
        selectedTopic: value,
      });
    }
  };
}

export const AdminQuestions = translate("common")(AdminQuestionsPage);
