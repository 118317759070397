import { actionsDefinition } from '../actionsDefinition';

export const questionsComplete = () => (dispatch: any) => {
    dispatch(todayQuestionsCompleted());
}

const todayQuestionsCompleted = () => ({
    type: actionsDefinition.SET_QUESTION_COMPLETED,
    payload: true
});
