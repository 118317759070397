import { actionsDefinition } from '../actions/actionsDefinition';
import UploadedBanners, { InitUploadedBanners } from '../models/uploadedBanners';


export const uploadedBannersReducer = (state: UploadedBanners = InitUploadedBanners(), action: any): UploadedBanners => {
  switch (action.type) {
    case actionsDefinition.GET_UPLOADED_BANNERS:
      return handleGetUploadedBannersCompleted(state, action.payload);
    default:
      return state;
  }
};

const handleGetUploadedBannersCompleted = (state: UploadedBanners, payload: UploadedBanners) => {
  return payload;
}