export const actionsDefinition = {
	http: {
		HTTP_CALL_START: 'HTTP_CALL_START',
		HTTP_CALL_END: 'HTTP_CALL_END',
	},
	GET_TENANT_FIRST_CONNECTION: 'GET_TENANT_FIRST_CONNECTION',
	LOAD_QUESTIONS: 'LOAD_QUESTIONS',
	SAVE_QUESTION: 'SAVE_QUESTION',
	EDIT_QUESTION: 'EDIT_QUESTION',
	DELETE_QUESTION: 'DELETE_QUESTION',
	UPLOAD_QUESTION: 'UPLOAD_QUESTION',
	LOAD_USERS: 'LOAD_USERS',
	SAVE_USER: 'SAVE_USER',
	EDIT_USER: 'EDIT_USER',
	DELETE_USER: 'DELETE_USER',
	LOAD_DAILY_QUESTIONS: 'LOAD_DAILY_QUESTIONS',
	IS_QUESTION_EMPTY: 'IS_QUESTION_EMPTY',
	LOAD_CURRENT_USER: 'LOAD_CURRENT_USER',
	LOAD_TOP5_USERS: 'LOAD_TOP5_USERS',
	SAVE_USER_ANSWERS: 'SAVE_USER_ANSWERS',
	SAVE_USER_ANSWERS_POINTS: 'SAVE_USER_ANSWERS_POINTS',
	SAVE_LAST_PARTICIPATION: 'SAVE_LAST_PARTICIPATION',
	SEND_CHALLENGE: 'SEND_CHALLENGE',
	LOAD_QUESTION_CATEGORIES: 'LOAD_QUESTION_CATEGORIES',
	GET_BLOB_SOCIAL_NETWORK: 'GET_BLOB_SOCIAL_NETWORK',
	LOAD_TOPWEEK_USERS: 'LOAD_TOPWEEK_USERS',
	GET_UPLOADED_BANNERS: 'GET_UPLOADED_BANNERS',
	UPDATE_SCORE_USERS: 'UPDATE_SCORE_USERS',
	settings: {
		GET_SETTINGS_COMPLETED: 'GET_SETTINGS_COMPLETED',
		CLEAR_SETTINGS_STATE_COMPLETED: 'CLEAR_SETTINGS_STATE_COMPLETED',
		UPDATE_SETTINGS_COMPLETED: 'UPDATE_SETTINGS_COMPLETED',
		SETTINGS_HTTP_CALL_STARTED: 'SETTINGS_HTTP_CALL_STARTED',
		UPLOAD_BANNER_COMPLETED: 'UPLOAD_BANNER_COMPLETED'
	},
	levels: {
		GET_LEVELS_COMPLETED: 'GET_LEVELS_COMPLETED',
		LEVELS_HTTP_CALL_STARTED: 'LEVELS_HTTP_CALL_STARTED',
		CLEAR_LEVELS_STATE_COMPLETED: 'CLEAR_LEVELS_STATE_COMPLETED',
		CLEAR_UPSERT_LEVEL_COMPLETED: 'CLEAR_UPSERT_LEVEL_COMPLETED',
		UPDATE_LEVEL_PROPERTY: 'UPDATE_LEVEL_PROPERTY',
		LOAD_LEVEL_PROPERTYS: 'LOAD_LEVEL_PROPERTYS'
	},
	alerts: {
		GET_USER_ALERTS: 'GET_USER_ALERTS',
		GET_USER_UNREAD_ALERTS_NUMBER: 'GET_USER_UNREAD_ALERTS_NUMBER',
		UPDATE_ALERT: 'UPDATE_ALERT',
		SET_ALERTS_READ: "SET_ALERTS_READ"
	},
	GET_TOKEN: 'GET_TOKEN',
	SAVE_ADMIN_CONSENT_OPTION_SELECTED: 'SAVE_ADMIN_CONSENT_OPTION_SELECTED',
	LOAD_DEMO_VERSION: 'LOAD_DEMO_VERSION',
	SET_QUESTION_COMPLETED: 'SET_QUESTION_COMPLETED',
	CHECK_IF_ADMIN: 'CHECK_IF_ADMIN',
	GET_TOPICS: 'GET_TOPICS',
	LOADING_TOPICS: 'LOADING_TOPICS'
};
