import { PnPFetchClient, msalInstance } from '../common/msal/msal';

const baseURL = process.env.REACT_APP_API;

const UrlBlobSocialNetworkAsync = async (b64Data: string): Promise<string> => {
    const urlBlobSocialNetwork = baseURL + `/api/socialNetworks`;
    const pnp = new PnPFetchClient(msalInstance);
    const body = { imageData: b64Data}
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    }

    return pnp.fetch(urlBlobSocialNetwork, obj).then((response) => 
        response.json()
    );
}

export const socialNetworkApi = {
    UrlBlobSocialNetworkAsync
}