import * as React from 'react';

interface Props {
    totalPages: number;
    currentPage: number;
    itemRange: number;
    initPage: () => void;
    prevPage: () => void;
    paginate: (page: number) => void;
    nextPage: () => void;
    lastPage: () => void;
}

interface State {
}

export default class PaginationComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { totalPages, initPage, lastPage, nextPage, prevPage } = this.props;
        const pageNumbers: number[] = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return (
            <nav className="mt-1">
                <ul className="pagination justify-content-center">
                    {this.props.currentPage > this.props.itemRange ? <li className="page-item">
                        <span className="page-link" onClick={() => initPage()}><i className="fas fa-angle-double-left"></i></span>
                    </li> : <></>}

                    {this.props.currentPage !== 1 ? <li className="page-item">
                        <span className="page-link" onClick={() => prevPage()}><i className="fas fa-angle-left"></i></span>
                    </li> : <></>}

                    {pageNumbers.map(num => (
                        this.renderOption(num)
                    ))}
                    {pageNumbers.length > this.props.currentPage ? <li className="page-item">
                        <span className="page-link" onClick={() => nextPage()}><i className="fas fa-angle-right"></i></span>
                    </li> : <></>}
                    {pageNumbers.length >= this.props.currentPage + this.props.itemRange ? <li className="page-item">
                        <span className="page-link" onClick={() => lastPage()}><i className="fas fa-angle-double-right"></i></span>
                    </li> : <></>}
                </ul>
            </nav>
        )
    }
    renderOption(page: number): JSX.Element {

        if (page < this.props.currentPage && page > (this.props.currentPage - this.props.itemRange)) {
            return (<li className="page-item" key={page}>
                <span onClick={() => this.props.paginate(page)} className="page-link">{page}</span>
            </li>)
        }
        if (page === this.props.currentPage) {
            return (<li className="page-item active" key={page}>
                <span onClick={() => this.props.paginate(page)} className="page-link">{page}</span>
            </li>)
        }
        if (page > this.props.currentPage && page < (this.props.currentPage + this.props.itemRange)) {
            return (<li className="page-item" key={page}>
                <span onClick={() => this.props.paginate(page)} className="page-link">{page}</span>
            </li>)
        }
        return (
            <li className="page-item" key={page}></li>
        )
    }
}