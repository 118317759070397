import { settingsApi } from "../../api/settingsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import * as toastr from 'toastr';
import { checkUploadedBannersAction } from "./checkUploadedBanners";

export const uploadBannerAction = (file: FormData, language: string, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    dispatch(settingsApiCallStart());

    settingsApi.UploadBanner(file, language).then((result) => {
        dispatch(uploadBannerCompleted(result));
        dispatch(checkUploadedBannersAction());
        dispatch(httpCallEndAction());
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });
}

const uploadBannerCompleted = (result: boolean) => ({
    type: actionsDefinition.settings.UPLOAD_BANNER_COMPLETED,
    payload: result
});

const settingsApiCallStart = () => ({
    type: actionsDefinition.settings.SETTINGS_HTTP_CALL_STARTED
});
