import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminPanel } from './adminPanel';
import { loadCurrentUserAction } from '../../actions/Home';
import { LoadIfAdminAction } from '../../actions/Users';
import { Stats } from 'fs';


const mapStateToProps = (state: IStateReducer) => ({
    user: state.currentUser.currentUser,
    //isAdmin: state.isAdmin
});

const mapDispatchToProps = (dispatch: any) => ({
    getCurrentUser: () => dispatch(loadCurrentUserAction()),
    //getIfAdmin: () => dispatch(LoadIfAdminAction()),
});

export const AdminPanelContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminPanel);