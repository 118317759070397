import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import pointIcon from '../../img/points-icon.png';
import endExtraChallengeImg from '../../img/endExtraChallenge.svg';

export interface IProjectsProps extends TransProps {
    hideEndExtraChallenge: () => void;
    t: any;
    showExtraordinaryPanel: () => void;
    points: number;
}

export interface IProjectsState {
    t: any;
    showEndChallenge: boolean;
}
class EndExtraChallengePage extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            t: I18nextProvider,
            showEndChallenge: true,
        });
    }
    private readonly hideFirstChallenge = (open: boolean) => {
        this.setState({
            showEndChallenge: open
        })
    }


    public render() {
        return (
            <Modal show={this.state.showEndChallenge} onHide={() => { this.hideFirstChallenge(false) }}>
                <Modal.Body>
                    <div className="popUpImage">
                        <div className="d-flex flex-column text-center title-modal">
                            <figure>
                                <img className="modal-image" src={endExtraChallengeImg} alt="Medalla" />
                            </figure>
                            <span className="QueesPlayQuiz">{this.props.t("EndExtraChallenge.Titulo1")} </span>
                            <span className="QueesPlayQuiz">{this.props.t("EndExtraChallenge.Titulo2")} </span>
                        </div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <hr />
                    <div className="popUpButtons text-center">
                        <div>
                            <p>{new Date().getDay() >= 5 ? this.props.t('EndExtraChallenge.DescripcionLunes') : this.props.t('EndExtraChallenge.Descripcion')}</p>
                        </div>
                    </div>
                    <div className="points-container">
                        <span className="daily-points-end">
                        <div className="points-icon">
                            <img src={pointIcon} alt="points" />
                        </div>
                        <span className="points">+{this.props.points}</span>
                        </span>
                        <span className="points-text"> {this.props.t("EndExtraChallenge.Points")}</span>
                    </div>
                    <div>
                        <div className="footer-buttons center">
                            <div className="">
                                <button type="button" className="btn" onClick={() => { this.hideFirstChallenge(false) }}>
                                    {this.props.t("EndExtraChallenge.Finalizar")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const EndExtraChallenge = withAITracking(new ReactPlugin(), translate('common')((EndExtraChallengePage)));