import { combineReducers } from 'redux';
import { questionReducer } from './question';
import { currentUserReducer, currentUserState } from './currentUser';
import { userReducer } from './user';
import { dailyQuestionReducer, dailyQuestionState } from './dailyQuestion';
import { top5UsersReducer } from './top5Users';
import { topWeekUsersReducer } from './topWeekUsers';
import { questionCategoriesReducer } from './questionCategories';
import { socialNetworkReducer } from './socialNetwork';
import { LevelsState, levelsReducer } from './levels';
import { authReducer } from './auth';
import Question from '../models/question';
import User from '../models/user';
import Topic from '../models/topic';
import { sendChallengeReducer } from './sendChallengeReducer';
import SendChallengeModel from '../models/sendChallenge';
import UploadedBanners from '../models/uploadedBanners';
import { uploadedBannersReducer } from './uploadedBanners';
import { ITag } from '@fluentui/react';
import { SettingsState, settingsReducer } from './settings';
import { alertsReducer, AlertsState } from './alerts';
import { questionsCompletedTodayReducer } from './questionsCompletedToday';
import { isAdminReducer } from './isadmin';
import {TopicsReducer, TopicState} from "./TopicsReducer";

export interface IStateReducer {
    questions: Question[];
    users: User[];
    dailyQuestion: dailyQuestionState;
    currentUser: currentUserState;
    top5Users: User[];
    topWeekUsers: User[];
    sendChallenge: SendChallengeModel;
    categories: ITag[];
    settingsState: SettingsState;
    urlBlobSocialNetwork: string;
    token: string;
    levels: LevelsState;
    alerts: AlertsState;
    uploadedBanners: UploadedBanners;
    questionsCompletedToday: boolean;
    topics: TopicState;
}

export const state = combineReducers<IStateReducer>({
    questions: questionReducer,
    users: userReducer,
    dailyQuestion: dailyQuestionReducer,
    currentUser: currentUserReducer,
    top5Users: top5UsersReducer,
    topWeekUsers: topWeekUsersReducer,
    sendChallenge: sendChallengeReducer,
    categories: questionCategoriesReducer,
    settingsState: settingsReducer,
    urlBlobSocialNetwork: socialNetworkReducer,
    token: authReducer,
    levels: levelsReducer,
    alerts: alertsReducer,
    uploadedBanners: uploadedBannersReducer,
    questionsCompletedToday: questionsCompletedTodayReducer,
    topics: TopicsReducer,
});