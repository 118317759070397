import Answer, { initAnswer } from "./answer";

export default interface UserAnswer {
    Id: string;
    TenantId: string;
    UserId: string;
    QuestionId: string;
    CategoryId: string;
    AnswerSelected: Answer;
    DateQuestion: Date;
    IsCorrectAnswer: boolean;
}

export const createEmptyUserAnswer = () => {
    let newUserAnswer: UserAnswer = {
        Id: '',
        TenantId: '',
        UserId: '',
        QuestionId: '',
        CategoryId: '',
        AnswerSelected: initAnswer(),
        DateQuestion: null,
        IsCorrectAnswer: false
    };

    return newUserAnswer;
};