import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { RouteComponentProps } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { LoadingComponent } from "../../common/components/Loading/loading";

export class LoginEnd extends React.Component<RouteComponentProps<{}>, {}> {
    constructor(props)  {
        super(props);
    }

    componentDidMount() {
        microsoftTeams.initialize();
        localStorage.removeItem("TeamsQuiz.error");
    
        let hashParams = this._getHashParameters();
        if (hashParams["error"]) {
            // Authentication/authorization failed
            localStorage.setItem("TeamsQuiz.error", JSON.stringify(hashParams));
            microsoftTeams.authentication.notifyFailure(hashParams["error"]);
        } else if (hashParams["access_token"]) {
            let expectedState = localStorage.getItem("TeamsQuiz.state");
            if (expectedState !== hashParams["state"]) {
                localStorage.setItem("TeamsQuiz.error", JSON.stringify(hashParams));
                microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
            } else {
                let key = "TeamsQuiz.result";
                localStorage.setItem(key, JSON.stringify({
                    idToken: hashParams["id_token"],
                    accessToken: hashParams["access_token"],
                    tokenType: hashParams["token_type"],
                    expiresIn: hashParams["expires_in"]
                }));
                microsoftTeams.authentication.notifySuccess(key);
            }
        } else {
            localStorage.setItem("TeamsQuiz.error", JSON.stringify(hashParams));
            microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
        }    
    }    
    
    public render() {
        return (
          
                <LoadingComponent  className={"loading-container-login"}></LoadingComponent>
          
        )
    }

    _getHashParameters() {
        let hashParams = {};
        window.location.hash.substr(1).split("&").forEach(function(item) {
            let s = item.split("="),
            k = s[0],
            v = s[1] && decodeURIComponent(s[1]);
            hashParams[k] = v;
        });

        return hashParams;
    }      
}