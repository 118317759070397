import { levelsApi } from "../../api/levelsApi";
import { actionsDefinition } from '../../actions/actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import { getLevelsAction } from './getLevels';
import * as toastr from 'toastr';

export const deleteLevelAction = (levelId: string, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    dispatch(levelApiCallStart());

    levelsApi.DeleteLevel(levelId).then((result) => {
        dispatch(getLevelsAction());
        dispatch(httpCallEndAction());
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });
}


const levelApiCallStart = () => ({
    type: actionsDefinition.levels.LEVELS_HTTP_CALL_STARTED
});
