import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import Settings from "../../models/settings";
import User from "../../models/user";
import pointIcon from '../../img/points-icon.png';
import endChallengeImg from '../../img/endChallenge.svg';

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export interface IProjectsProps extends TransProps {
    hideEndDailyChallenge: () => void;
    t: any;
    showExtraordinaryPanel: () => void;
    points: number;
    user: User;
    settings: Settings;
}

export interface IProjectsState {
    t: any;
    showEndChallenge: boolean;
    extraQuestions: boolean;
}
class EndDailyChallengePage extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            t: I18nextProvider,
            showEndChallenge: true,
            extraQuestions: false
        });
    }
    private readonly hideFirstChallenge = (open: boolean) => {
        this.setState({
            showEndChallenge: open
        })
    }

    private readonly showPanel = () => {
        this.hideFirstChallenge(false)
        this.props.showExtraordinaryPanel();
    }

    componentDidMount() {
        this.setCookie("teamsquizshowagain", "true")
        let today = new Date();
        const day = today.getDay();
        if (this.props.user.UserConnection.ExtraordinaryQuestionDay === weekDays[day] && this.props.settings?.extraordinaryQuestionsSettings?.isActive) {
            this.setState({ extraQuestions: true });
        }
    }

    private setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;
        date.setHours(24,0,0,0);
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    }

    public render() {
        return (
            <Modal show={this.state.showEndChallenge} onHide={() => { this.hideFirstChallenge(false) }}>
                <Modal.Body>
                    <div className="popUpImage">
                        <div className="d-flex flex-column text-center title-modal">
                            <figure>
                                <img className="modal-image" src={endChallengeImg} alt="Trofeo" />
                            </figure>
                            <span className="QueesPlayQuiz">{this.props.t("EndDailyChallenge.Titulo1")} </span>
                            <span className="QueesPlayQuiz">{this.props.t("EndDailyChallenge.Titulo2")} </span>
                        </div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <hr />
                    <div className="popUpButtons text-center">
                        <div>
                            <p>{new Date().getDay() >= 5 ? this.props.t('EndDailyChallenge.DescripcionLunes') : this.props.t('EndDailyChallenge.Descripcion')}</p>
                        </div>
                    </div>
                    <div className="points-container">
                        <span className="daily-points-end">
                        <div className="points-icon">
                            <img src={pointIcon} alt="points" />
                        </div>
                        <span className="points">+{this.props.points}</span>
                        </span>
                        <span className="points-text"> {this.props.t("EndDailyChallenge.Points")}</span>
                    </div>

                    <div>
                        <div className="footer-buttons center">
                            <div className="">
                                <button type="button" className={ this.state.extraQuestions ? "btn secondary-button" : "btn btn-danger" } onClick={() => { this.hideFirstChallenge(false) }}>
                                    {this.props.t("EndDailyChallenge.Finalizar")}
                                </button>
                                {this.state.extraQuestions ?
                                    <button type="button" className="btn" onClick={this.showPanel}>
                                        {this.props.t("EndDailyChallenge.PreguntasExtraordinarias")}
                                    </button> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const EndDailyChallenge = withAITracking(new ReactPlugin(), translate('common')((EndDailyChallengePage)));