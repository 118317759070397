export default interface SocialNetworkSettings {   
    facebook: boolean;
    instagram: boolean;
    linkedin: boolean;
    teams: boolean;
    twitter: boolean;
    yammer: boolean;
}

export const createNewSocialNetworkSettings = (): SocialNetworkSettings => {
    return {
        facebook: true,
        instagram: true,
        linkedin: true,
        teams: true,
        twitter: true,
        yammer: true
    }
}