import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminConsentPage } from './adminConsent'
import { saveAdminConsentSelectedAction } from '../../actions/Admin'
import { loadCurrentUserAction } from '../../actions/Home'

const mapStateToProps = (state: IStateReducer) => ({
    user: state.currentUser.currentUser,
    adminConsentOptionSelected: state.currentUser.adminConsentOptionSelected
});

const mapDispatchToProps = (dispatch: any) => ({
    saveAdminConsentSelected: () => dispatch(saveAdminConsentSelectedAction()),
    loadCurrentUserAction: () => dispatch(loadCurrentUserAction()),
});

export const AdminConsentContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminConsentPage);
