import * as React from 'react';
import { HeaderContainer } from './header/headerContainer';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import footerImage from '../img/illustration-footer.svg';
import TeamsFunctions from '../common/functions/teamsFunctions';

export interface ILayoutProps {
	children?: React.ReactNode;
}

class Layout extends React.Component<ILayoutProps> {
	public render() {
		return (
			<div className="AppLayout">
				<div className="pageContainer">
					{
						!TeamsFunctions.IsInTeams() ? <HeaderContainer /> : <></>
					}

					<div className="mainContent">
						{this.props.children}
					</div>
					<div className="footerContainer" >
						<span>Copyright {new Date().getFullYear()} -&nbsp;</span>
						<a href="http://www.encamina.com" target="_blank" rel="noopener noreferrer">ENCAMINA</a>
						{/* <span>&nbsp;|&nbsp;</span>
						<a href="http://www.teamschamp.com" target="_blank" rel="noopener noreferrer">Conoce TeamsChamp</a> */}
						{/* <span>&nbsp;|&nbsp;</span>
						<a href="http://www.teamsquiz.com" target="_blank" rel="noopener noreferrer">Visita PlayQuiz</a> */}
						<img className="imageFooter" src={footerImage} alt="footer-image" />
					</div>
				</div>
			</div>
		);
	}
}

export default withAITracking(new ReactPlugin(), Layout);
