import * as React from "react";

export interface ISelectProps {
  className?: string;
  changeEvent?: any;
  selectedValue?: any;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  lstOptions: any;
}

export const Select = function (props: ISelectProps) {

  return (
    <>
      <select
      defaultValue={props.selectedValue}
        
        className={props.className}
        onChange={props.changeEvent}
      >
        {props.lstOptions.map((item, index) => 
          <option key={index} value={item.value}>
            {item.text}
          </option>
        )}
      </select>
    </>
  );
};
