export default interface UserPosition {
    Position: number;
    TotalUsers: number;
}

export const createEmptyUserPosition = () => {
    let newUserPosition: UserPosition = {
        Position: 0,
        TotalUsers: 0
    };

    return newUserPosition;
};