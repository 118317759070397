import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import { appInsights } from '../common/telemetry/appInsights';
import LevelSettings from '../models/levelSettings';

const baseURL = process.env.REACT_APP_API + '/api/levels';

const GetLevels = async (): Promise<LevelSettings[]> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToLevelSettings(result);
            }
            var message = await response.text();
            //return Promise.reject(message);
            return mapToLevelSettings([]);
        }).catch(error => {
            appInsights.trackException(error);
            //return Promise.reject(error);
            return null;
        });
}

const UpdateLevel = async (level: LevelSettings): Promise<LevelSettings> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(level)
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToLevelSetting(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const AddLevel = async (level: LevelSettings): Promise<LevelSettings> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(level)
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToLevelSetting(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const DeleteLevel = async (levelId: string): Promise<boolean> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(levelId)
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                return true;
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const mapToLevelSettings = (response: any[]): LevelSettings[] => {
    let result = response != null ? response.map((e) => mapToLevelSetting(e)) : [];
    return result;
}

const mapToLevelSetting = (response: any): LevelSettings => {
    return {
        id: response.id,
        number: response.number,
        name: response.name,
        scoreInit: response.scoreInit,
        scoreEnd: response.scoreEnd,
        color: response.color
    };
}

export const levelsApi = {
    GetLevels,
    UpdateLevel,
    AddLevel,
    DeleteLevel
}
