import { connect } from "react-redux";
import { IStateReducer } from "../../reducers";
import { AdminTopicComponent } from "./AdminTopics";
import {
  DeleteTopicAction,
  GetTopicsAction,
  NewTopicAction,
  SaveTopicAction,
} from "../../actions/Admin/topics";

const mapStateToProps = (state: IStateReducer) => ({
  topics: state.topics.topics,
  isLoading: state.topics.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  GetTopics: () => dispatch(GetTopicsAction()),
  NewTopic: async (topic, pageText) => dispatch(await NewTopicAction(topic, pageText)),
  DeleteTopic: async (topic, pageText) => dispatch(await DeleteTopicAction(topic, pageText)),
  UpdateTopic: async (topic, pageText) => dispatch(await SaveTopicAction(topic, pageText)),
});

export const AdminTopicContainer: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTopicComponent);
