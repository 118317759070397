import { actionsDefinition } from '../actions/actionsDefinition';
import Alert from '../models/alert';

export interface AlertsState {
  alerts: Alert[];
  unreadAlerts: number;
  userAlertsHttpCallComplete: boolean;
  userUnreadAlertsHttpCallComplete: boolean;
}

function createNewAlertState(): AlertsState {
  return {
    alerts: [],
    unreadAlerts: 0,
    userAlertsHttpCallComplete: false,
    userUnreadAlertsHttpCallComplete: true
  };
}

export const alertsReducer = (state: AlertsState = createNewAlertState(), action): AlertsState => {
  switch (action.type) {
    case actionsDefinition.alerts.GET_USER_ALERTS:
      return handleGetUserAlertsCompleted(state, action.payload);
    case actionsDefinition.alerts.GET_USER_UNREAD_ALERTS_NUMBER:
      return handleGetUserUnreadAlertsCompleted(state, action.payload);
    case actionsDefinition.alerts.UPDATE_ALERT:
      return handleUpdateAlertCompleted(state);
    case actionsDefinition.alerts.SET_ALERTS_READ:
      return {
        ...state,
        unreadAlerts: 0
      }
    default:
      return state;
  }
};

const handleGetUserAlertsCompleted = (state: AlertsState = createNewAlertState(), payload: Alert[]): AlertsState => {
  return {
    ...state,
    alerts: payload,
    userAlertsHttpCallComplete: true
  }
};

const handleGetUserUnreadAlertsCompleted = (state: AlertsState = createNewAlertState(), payload: number): AlertsState => {
  return {
    ...state,
    unreadAlerts: payload,
    userUnreadAlertsHttpCallComplete: true
  }
};

const handleUpdateAlertCompleted = (state: AlertsState = createNewAlertState()): AlertsState => {
  return {
    ...state,
  };
};

