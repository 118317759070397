import * as React from "react";
import { msalConfig } from "../msal/msalConfig";
import { LoginError } from "../login/loginError";
import { UserAgentApplication, AuthError } from "msal";
import { Providers, ProviderState } from "@microsoft/mgt";
import { I18nextProvider, TransProps, translate } from "react-i18next";
import { Link } from "react-router-dom";
import { appInsights } from "../telemetry/appInsights";
import TeamsFunctions from "../functions/teamsFunctions";
import { LoadingComponent } from "../components/Loading/loading";
import i18next from "i18next";

export const msalInstance = new UserAgentApplication(msalConfig);

export interface IProps extends TransProps {
  t: any;
}
interface IState {
  authenticated: boolean;
  renewIframe: boolean;
  errorMessage: string;
  hasError: boolean;
  t: any;
}

export function withAuth<TOriginalProps>(
  WrappedComponent:
    | React.ComponentClass<TOriginalProps>
    | React.FunctionComponent<TOriginalProps>
): React.ComponentClass<TOriginalProps> {
  return class Auth extends React.Component<TOriginalProps & IProps, IState> {
    constructor(props: TOriginalProps & IProps) {
      super(props);

      this.state = {
        authenticated: false,
        renewIframe: false,
        hasError: false,
        errorMessage: "",
        t: I18nextProvider,
      };
    }

    public componentWillMount(): void {
      msalInstance.handleRedirectCallback(
        () => {
          // on success
          this.setState({
            authenticated: true,
          });
          Providers.globalProvider.setState(ProviderState.SignedIn);
        },
        (authErr: AuthError, accountState: string) => {
          // on fail
          Providers.globalProvider.setState(ProviderState.SignedOut);
          this.setState({
            hasError: true,
            errorMessage: authErr.errorMessage,
          });
        }
      );

      if (msalInstance.isCallback(window.location.hash)) {
        this.setState({
          renewIframe: true,
        });
        return;
      }
      //msalInstance.getAccount()
      if (!msalInstance.getAccount() && !TeamsFunctions.IsInTeams()) {
        msalInstance.loginRedirect({});
        Providers.globalProvider.setState(ProviderState.Loading);
        return;
      } else {
        Providers.globalProvider.setState(ProviderState.SignedIn);
        this.setState({
          authenticated: true,
        });
      }
    }
    private cambiarPagina(pagina: string) {
      let newAppInsights = appInsights;
      newAppInsights.trackPageView({ name: pagina });
    }
    public render(): JSX.Element {
      const langObj = {
        es: {
          msg: "¡Te has registrado con éxito en PlayQuiz! Para empezar a responder preguntas, ha click en el siguiente botón​",
          btn: "Aceptar"
        },

        en: {
          msg: "You've successfully signed up for PlayQuiz! To start answering questions, sign in to the next button​",
          btn: "Accept"
        }
      };
      if (this.state.renewIframe) {
        return (
          <div>
            <div>{langObj[i18next.language || "en"]?.msg}</div>
            <Link
              className="btn btn-primary"
              onClick={() => this.cambiarPagina("home")}
              to="/"
            >
              {langObj[i18next.language || "en"]?.btn}
            </Link>
          </div>
        );
      }

      if (this.state.authenticated) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.state.hasError) {
        return <LoginError message={this.state.errorMessage} />;
      }

      return <LoadingComponent className={"loading-container shadow"} />;
    }
  };
}