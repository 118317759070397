import { userApi } from "../../api/userApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { loadUsersAction } from "./loadUsers";
import { loadTop5UsersAction, loadCurrentUserAction, loadTopWeekUsers } from "../Home";
import * as toastr from 'toastr';

export const resetScoreUsersAction = (successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.UpdateResetScoreUsers().then((result) => {
        dispatch(loadUsersAction());
        dispatch(loadCurrentUserAction());
        dispatch(loadTop5UsersAction());
        dispatch(loadTopWeekUsers());
        dispatch(updateresetScoreUsersActionCompleted(result));
        if (successMessage !== '') {
            toastr.success(successMessage, '', { timeOut: 3000 });
        }
    }).catch(error => {
        toastr.error(error);
    });

    const updateresetScoreUsersActionCompleted = (result) => ({
        type: actionsDefinition.UPDATE_SCORE_USERS,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}