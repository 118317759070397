import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../../common/components/button/button";


export interface IModalProps {
  show: boolean;
  header: IModalHeaderProps;
  body: IModalBodyProps;
  footer: IModalFooterProps;
}

export interface IModalHeaderProps {
    title: string;
    onClick: any;
  }

export interface IModalBodyProps {
    text: string;
  }


  export interface IModalFooterProps {
    onClick: any;
    textAccept: string;
    textCancel: string;
  }
  
export const ModalConfirm = function (props: IModalProps) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.header.onClick(false)}
      >
        <Modal.Header>
          <Modal.Title className=" d-flex justify-content-between w-100">
            <div>{props.header.title}</div>
            <div className="botonCerrar">
              <Button clickEvent={() => props.header.onClick(false)} text="" icon="fa-times"/>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div dangerouslySetInnerHTML={{__html: props.body.text}} ></div>
     
        </Modal.Body>
        <Modal.Footer>
        <Button className="btn-secondary" clickEvent={() => props.footer.onClick(false)} text={props.footer.textCancel}/>
        <Button className="btn-primary" clickEvent={() => props.footer.onClick(true)}  text={props.footer.textAccept}/>
        </Modal.Footer>
      </Modal>
    </>
  );
};
