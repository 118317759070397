import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AdminQuestionsContainer } from "../adminQuestions/adminQuestionsContainer";
import { AdminSettingsContainer } from "../adminSettings/adminSettingsContainer";
import { AdminUserContainer } from "../adminUser/adminUserContainer";
import { AdminLevelsContainer } from "../adminLevels/adminLevelsContainer";
import { AdminBannerContainer } from "../adminBanner/adminBannerContainer";
import "../../css/style.css";
import { translate, TransProps, I18nextProvider } from "react-i18next";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { Route } from "react-router-dom";
import User, { createEmptyUser } from "../../models/user";
import { AdminPanelComponent } from "./adminPanelComponent";
import { AdminTopicContainer } from "./../adminTopics/AdminTopicContainer";

export interface IAdminPanelProps extends TransProps {
  user: User;
  t: any;
  getCurrentUser: () => Promise<void>;
  isAdmin: boolean;
}

export interface IAdminPanelState {
  children?: React.ReactNode;
  userActive: boolean;
  questionActive: boolean;
  settingsActive: boolean;
  levelsActive: boolean;
  bannerActive: boolean;
  topicActive: boolean;
  t: any;
  isLoading: boolean;
  users?: any;
}

class AdminPanelPage extends React.Component<
  RouteComponentProps & IAdminPanelProps,
  IAdminPanelState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      children: <Route component={AdminUserContainer}></Route>,
      userActive: true,
      questionActive: false,
      levelsActive: false,
      settingsActive: false,
      bannerActive: false,
      topicActive: false,
      t: I18nextProvider,
      isLoading: false,
      users: undefined,
    };
  }

  async componentDidMount() {
    if (JSON.stringify(this.props.user) === JSON.stringify(createEmptyUser())) {
      this.props.getCurrentUser();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({ isLoading: false });
    }
  }

  public render() {
    const lstTabs = GetLstTabs(this.props);
    return (
      <AdminPanelComponent
        isLoading={this.state.isLoading}
        loadingText={this.props.t("AdminPanel.Loading")}
        title={this.props.t("AdminPanel.PanelAdmin")}
        user={this.props.user}
        children={this.state.children}
        messagePanelTitle={this.props.t("AdminPanel.AccesoDen")}
        messagePanelMessage={this.props.t("AdminPanel.AccesoDenMjs")}
        parent={this}
        lstTabs={lstTabs}
      />
    );
  }
}

export const AdminPanel = withAITracking(
  new ReactPlugin(),
  translate("common")(AdminPanelPage)
);

const GetLstTabs = (props) => {
  const lst = [
    {
      id: 1,
      title: props.t("AdminPanel.Usuarios"),
      pageName: "User",
      child: <AdminUserContainer />,
      active: "userActive",
      icon: "People",
    },
    {
      id: 2,
      title: props.t("AdminPanel.Preguntas"),
      pageName: "Question",
      child: <AdminQuestionsContainer />,
      active: "questionActive",
      icon: "Help",
    },
    {
      id: 3,
      title: props.t("AdminPanel.Settings"),
      pageName: "Settings",
      child: <AdminSettingsContainer />,
      active: "settingsActive",
      icon: "Settings",
    },
    {
      id: 4,
      title: props.t("AdminPanel.Levels"),
      pageName: "Levels",
      child: <AdminLevelsContainer />,
      active: "levelsActive",
      icon: "Ribbon",
    },
    {
      id: 5,
      title: props.t("AdminPanel.Banner"),
      pageName: "Banner",
      child: <AdminBannerContainer />,
      active: "bannerActive",
      icon: "FileImage",
    },
    {
      id: 6,
      title: props.t("AdminPanel.Topics"),
      pageName: "Topic",
      child: <AdminTopicContainer />,
      active: "topicActive",
      icon: "Help",
    },

  ];

  return lst;
}