import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import UserAdmin from "../models/userAdmin";
const baseURL = process.env.REACT_APP_API;

const SendConfigureAsync = async (configure: UserAdmin[]): Promise<boolean> => {
    const sendConfigure = baseURL + `/api/user/configAdmin`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(configure)
    }
     await pnp.fetch(sendConfigure, obj);
    return true ;
}

export const configureApi = {
    SendConfigureAsync
}