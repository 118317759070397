import * as React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { getValueLevelPercentage } from "../../../../common/functions/utils";
import pointIcon from '../../../../img/points-icon.png';
import LevelSettings from "../../../../models/levelSettings";
import User from "../../../../models/user";
import { UserInfo } from "./userInfo/userInfo";
import { Person } from "mgt-react";

interface IProfileCardProps {
    user: User,
    userLevel: LevelSettings,
    t: any

}
export const ProfileCard = (props: IProfileCardProps) => {
    const { user, userLevel, t } = props;
    const pathColorClass =
        "circular aura-user-" +
        userLevel?.color;
    const valueLevelPercentage = getValueLevelPercentage(userLevel, user.TotalPoints);
    const userProfileName = userLevel?.name || "Amateur";


    return <div className="profile-card">
        {user.UserConnection.LastParticipation && (
            <div className="row">
                <div className="col-xs-6 col-sm-4 user-image">
                    <CircularProgressbarWithChildren
                        className={pathColorClass}
                        strokeWidth={8}
                        value={valueLevelPercentage}
                    >
                        <div className="site-user userDiv mx-3">
                            <div className="d-flex">
                                <Person personDetails={{ displayName: user.Name }}></Person>
                            </div>
                        </div>
                    </CircularProgressbarWithChildren>
                    <div className="level hidden-md">{userProfileName}</div>
                    <div className="user-position hidden-md">
                        <i>
                            {t("Home.TextoPosicion")}{" "}
                            {user?.UserPosition?.Position}
                        </i>{" "}
                        {t("Home.TextoDe")}{" "}
                        {user?.UserPosition?.TotalUsers}
                    </div>
                </div>
                <div className="col-xs-6 user-info user-info-points visible-md">
                    <div className="user-points">
                        <img
                            src={pointIcon}
                            alt="points"
                        />
                        <div className="puntos">
                            {user.TotalPoints} <br />{" "}
                            {t("Home.Puntos")}
                        </div>
                    </div>
                </div>
                <UserInfo user={user} t={t} userLevel={userLevel} />
            </div>
        )}
    </div>
}