import { questionApi } from "../../api/questionApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { ITag } from '@fluentui/react'
export const loadQuestionCategoriesAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());
    questionApi.GetQuestionCategories().then((result) => {
        dispatch(loadQuestionCategoriesCompleted(result));
    })

    const loadQuestionCategoriesCompleted = (result: ITag[]) => ({
        type: actionsDefinition.LOAD_QUESTION_CATEGORIES,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}