import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { Checkbox } from '@fluentui/react';
import User from "../../models/user";
import userSetting from "../../models/userSetting";
import startChallengeImg from "../../img/startChallenge.svg";
import pointIcon from '../../img/points-icon.png';
export interface IProjectsProps extends TransProps {
    hideStartDailyChallenge: () => void;
    t: any;
    showSurvey: (isExtraQuestions: boolean) => void;
    user: User;
    changeUser: (user: User) => Promise<void>;
    lng: string;
}

export interface IProjectsState {
    t: any;
    showStartChallenge: boolean;
}
class StartDailyChallengePage extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            t: I18nextProvider,
            showStartChallenge: true
        });
    }
    private readonly hideFirstChallenge = (open: boolean) => {
        this.setState({
            showStartChallenge: open
        })
    }

    private readonly showSurvey = () => {
        this.hideFirstChallenge(false)
        this.props.showSurvey(false);
    }

    componentDidMount() {
        if (this.props.user.UserSettings?.PlayQuizPopup) {
            this.setState({ showStartChallenge: false })
        }
    }

    private onChangeCheckbox = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
        let user = this.props.user;
        if (!user.UserSettings) {
            user.UserSettings = {} as userSetting;
            user.UserSettings.Language = this.props.lng;
        }
        user.UserSettings.PlayQuizPopup = checked;
        this.props.changeUser(user);
    }

    public render() {
        return (
            <Modal show={this.state.showStartChallenge} onHide={() => { this.hideFirstChallenge(false) }}>
                <Modal.Body>
                    <div className="botonCerrar float-right">
                        <button className="btn" onClick={() => { this.hideFirstChallenge(false) }}><i className="fas fa-times"></i></button>
                    </div>
                    {<div className="checkShowAgain">
                        <Checkbox
                            label={this.props.t("Home.DontShowAgain")}
                            checked={this.props.user.UserSettings?.PlayQuizPopup}
                            id={"extra-questions-active"}
                            onChange={this.onChangeCheckbox}
                        />
                    </div>}
                    <div className="popUpImage">
                        <div className="d-flex flex-column text-center">
                            <figure>
                                <img className="modal-image" src={startChallengeImg} alt="Personajes bienvenida" />
                            </figure>
                            <span className="QueesPlayQuizz text-left">{this.props.t("StartDailyChallenge.Titulo")} </span>
                        </div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <hr />
                    <div className="popUpButtons">
                        <div>
                            <p>{this.props.t("StartDailyChallenge.Descripcion")}</p>
                        </div>
                    </div>
                    <div className="daily-points" >
                        <div className="points-icon">
                            <img src={pointIcon} alt="points" />
                        </div>
                        <span className="points"> +{(3 * parseInt(process.env.REACT_APP_QUESTION_SUCCESS_POINTS)) + parseInt(process.env.REACT_APP_QUESTION_EXTRA_DAILY_POINTS)}</span>
                    </div>
                    <div>
                        <div className="footer-buttons center">
                            <div className="">
                                <button type="button" className="btn" onClick={this.showSurvey}>
                                    {this.props.t("StartDailyChallenge.Aceptar")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const StartDailyChallenge = withAITracking(new ReactPlugin(), translate('common')((StartDailyChallengePage)));