import { Icon } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { settingsApi } from "../../../api/settingsApi";
import { ModalConfirm } from "../../../components/adminUser/modal/confirmModal";
import "./imagePickerStyle.css";
import noImg from "../../../img/no-image.png";

interface IImagePickerProps {
  changeEvent: Function;
  imgDefault: string;
  uploadText: string;
  modalText: any;
  deleteText: string;
}

const ImagePickerComponent = (props: IImagePickerProps) => {
  const [img, setImg] = useState(props.imgDefault || noImg);
  const [panelDeleteOpen, setPanelDeleteOpen] = useState(false);

  const UploadImage = async (e) => {
    var file = e.target.files[0];

    const stringFile = await toBase64(file);

    const base64File = stringFile.toString();

    const objReq = {
      name: file.name,
      size: file.size,
      type: file.type,
      file: base64File.substring(
        base64File.indexOf("base64") + 7,
        base64File.length
      ),
    };

    settingsApi.UploadFile(objReq).then((resultado) => {
      const img = resultado + "?date=" + new Date().getTime();
      setImg(img);
      props.changeEvent(img);
    });
  };

  const ShowConfirmDialog = () => {
    setPanelDeleteOpen(true);
  };
  const ManageResult = (resu) => {
    if (resu) {
      setImg(noImg);
      props.changeEvent("");
      setPanelDeleteOpen(false);
    } else {
      setPanelDeleteOpen(false);
    }
  };

  const renderDeleteModal = () => {
    const header = {
      title: props.modalText.DeleteLogo,
      onClick: ManageResult,
    };

    const body = {
      text: "<div> " + props.modalText.DeleteLogoText + "</div>",
    };

    const footer = {
      onClick: ManageResult,
      textAccept: props.modalText.BtnAccept,
      textCancel: props.modalText.BtnCancel,
    };

    return (
      <ModalConfirm
        show={panelDeleteOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  };

  return (
    <>
      <div>
        <div className="imageZone">
          <img src={img} />
          <div className="selectionZone">
            <label htmlFor="fileUpload" title={props.uploadText}>
              <Icon className="iconUpload" iconName="CloudUpload" />
            </label>
            <input
              accept="image/*"
              multiple={false}
              id="fileUpload"
              type="file"
              onChange={(e) => UploadImage(e)}
            />
            {img.length > 0 && img != noImg ? (
              <Icon
                title={props.deleteText}
                className="iconUpload"
                iconName="Delete"
                onClick={ShowConfirmDialog}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        {renderDeleteModal()}
      </div>
    </>
  );
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default ImagePickerComponent;
