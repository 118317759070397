import { actionsDefinition } from '../actions/actionsDefinition';
import LevelSettings from '../models/levelSettings';
import GenericPayload from '../models/genericPayload';

export interface LevelsState {
  levels: LevelSettings[];
  upsertLevel: LevelSettings;
  levelsHttpCallComplete: boolean;
}

function createNewLevelState(): LevelsState {
  return {
    levels: [],
    upsertLevel: {} as LevelSettings,
    levelsHttpCallComplete: false
  };
}

export const levelsReducer = (state: LevelsState = createNewLevelState(), action): LevelsState => {
  switch (action.type) {
    case actionsDefinition.levels.GET_LEVELS_COMPLETED:
      return handleGetLevelCompleted(state, action.payload);
    case actionsDefinition.levels.CLEAR_LEVELS_STATE_COMPLETED:
      return handleClearLevelsStateCompleted();
    case actionsDefinition.levels.CLEAR_UPSERT_LEVEL_COMPLETED:
      return handleClearUpserLevelCompleted(state);
    case actionsDefinition.levels.LEVELS_HTTP_CALL_STARTED:
      return handleLevelsHttpCallStart(state);
    case actionsDefinition.levels.UPDATE_LEVEL_PROPERTY:
      return updateLevelProperty(state, action.payload);
    case actionsDefinition.levels.LOAD_LEVEL_PROPERTYS:
      return LoadLevelPropertys(state, action.payload);
    default:
      return state;
  }
};

const handleGetLevelCompleted = (state: LevelsState = createNewLevelState(), payload: LevelSettings[]): LevelsState => {
  return {
    ...state,
    levels: payload,
    levelsHttpCallComplete: true
  }
};

const handleLevelsHttpCallStart = (state: LevelsState = createNewLevelState()): LevelsState => {
  return {
    ...state,
    levelsHttpCallComplete: false
  };
};

const handleClearLevelsStateCompleted = (): LevelsState => {
  return createNewLevelState();
};

const handleClearUpserLevelCompleted = (state: LevelsState = createNewLevelState()): LevelsState => {
  return {
    ...state,
    upsertLevel: {} as LevelSettings
  }
};

const updateLevelProperty = (state: LevelsState, payload: GenericPayload) => {
  return {
    ...state,
    upsertLevel: {
      ...state.upsertLevel,
      [payload.name]: payload.value,
    },
  };
};

const LoadLevelPropertys = (state: LevelsState, level: LevelSettings) => {
  return {
    ...state,
    upsertLevel: {
      id: level.id,
      number: level.number,
      name: level.name,
      scoreInit: level.scoreInit,
      scoreEnd: level.scoreEnd,
      color: level.color
    },
  };
};