import { questionApi } from "../../api/questionApi";
import Question from "../../models/question";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { loadQuestionsAction } from "./loadQuestions";
import { loadQuestionCategoriesAction } from "./loadQuestionCategories";
import * as toastr from 'toastr';

export const editQuestionAction = (question: Question, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    questionApi.EditQuestionAsync(question).then((result) => {
        dispatch(loadQuestionsAction());
        dispatch(loadQuestionCategoriesAction());
        dispatch(editQuestionCompleted(result));
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });

    const editQuestionCompleted = (result: boolean) => ({
        type: actionsDefinition.SAVE_QUESTION,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}