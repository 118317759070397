import * as React from "react";
import * as Survey from "survey-react";
import { RouteComponentProps } from "react-router";
import DailyQuestion from "../../models/dailyQuestion";
import User from "../../models/user";
import Settings from "../../models/settings";
import UserAnswer, { createEmptyUserAnswer } from "../../models/userAnswer";
import Modal from "react-bootstrap/Modal";
import "survey-react/survey.css";
import "../../css/style.css";
import { translate, TransProps, I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import UserAnswerPoints from "../../models/userAnswerPoints";
import { utils } from "../../common/functions/utils";
import { LoadingComponent } from "../../common/components/Loading/loading";
import { userApi } from "../../api/userApi";
import { timeStamp } from "console";

export interface IProjectsProps extends TransProps {
  getDailyQuestions: () => Promise<void>;
  dailyQuestions: DailyQuestion[];
  extraordinaryQuestions: DailyQuestion[];
  loadingQuestions: boolean;
  user: User;
  showSurvey: boolean;
  closePanel: (dailyPoints: number, extraPoints: number) => void;
  cancel: () => void;
  questionsComplete: () => void;
  GetTopWeekUsers: () => void;
  GetTopUsers: () => void;
  t: any;
  isExtraQuestions: boolean;
  settings: Settings;
  questionsCompletedToday: boolean;
}

export interface IProjectsState {
  dailyQuestions: DailyQuestion[];
  completedHtml: string;
  dailyQuestionsJson: any;
  extraordinaryQuestionsJson: any;
  t: any;
  allDailyQuestionsCorrect: boolean;
  dailyQuestionsTotalPoints: number;
  isFirstAnswerToDailyQuestion: boolean;
  userEmail: string;
  userAnswers: UserAnswer[];
  basicPointsObtained: number;
  showDailyQuestions: boolean;

  extraordinaryTotalPoints: number;
  showExtraordinaryQuestions: boolean;
  showExtraordinaryQuestionsDialog: boolean;
  extraordinaryQuestionsCompletedHtml: string;
  extraordinaryQuestionsAnswers: UserAnswer[];
  isFirstAnswerToExtraordinaryQuestion: boolean;
  extraordinaryQuestionCompleted: boolean;
  submittingAnswers: boolean;
}

class DailyQuestionsPage extends React.Component<
  RouteComponentProps<{}> & IProjectsProps,
  IProjectsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      dailyQuestions: [],
      dailyQuestionsJson: null,
      completedHtml: "",
      t: I18nextProvider,
      showDailyQuestions: false,
      extraordinaryTotalPoints: 0,
      showExtraordinaryQuestions: false,
      extraordinaryQuestionsJson: null,
      showExtraordinaryQuestionsDialog: false,
      isFirstAnswerToExtraordinaryQuestion: true,
      extraordinaryQuestionsCompletedHtml: "",
      extraordinaryQuestionsAnswers: [],
      allDailyQuestionsCorrect: true,
      dailyQuestionsTotalPoints: 0,
      basicPointsObtained: 0,
      isFirstAnswerToDailyQuestion: true,
      userAnswers: [],
      userEmail: "",
      extraordinaryQuestionCompleted: false,
      submittingAnswers: false,
    };

    this.onValueChanged = this.onValueChanged.bind(this);
    this.saveQuestions = this.saveQuestions.bind(this);
  }

  componentDidMount() {
    if (this.props.dailyQuestions.length === 0) {
      if (!this.props.loadingQuestions) {
        this.props.getDailyQuestions();
      }
    } else {
      const json = this.createJson(this.props.dailyQuestions);
      const extraQuestionsjson = this.createJson(
        this.props.extraordinaryQuestions
      );
      this.setState({
        dailyQuestionsJson: json,
        showDailyQuestions: true,
        extraordinaryQuestionsJson: extraQuestionsjson,
        dailyQuestions: this.props.dailyQuestions,
      });
    }
  }

  componentDidUpdate(prevProps: IProjectsProps, prevState: IProjectsState) {
    if (prevProps !== this.props) {
      if (this.props.questionsCompletedToday && !this.props.isExtraQuestions) {
        this.props.closePanel(
          this.state.dailyQuestionsTotalPoints,
          this.state.extraordinaryTotalPoints
        );
      } else if (
        this.props.questionsCompletedToday &&
        this.state.extraordinaryQuestionCompleted
      ) {
        this.props.closePanel(
          this.state.dailyQuestionsTotalPoints,
          this.state.extraordinaryTotalPoints
        );
      }
      if (prevProps.dailyQuestions !== this.props.dailyQuestions) {
        const json = this.createJson(this.props.dailyQuestions);
        this.setState({
          dailyQuestionsJson: json,
          dailyQuestions: this.props.dailyQuestions,
          showDailyQuestions: true,
        });
      }
      if (
        prevProps.extraordinaryQuestions !== this.props.extraordinaryQuestions
      ) {
        const json = this.createJson(this.props.extraordinaryQuestions);
        this.setState({
          extraordinaryQuestionsJson: json,
          showExtraordinaryQuestionsDialog: true,
        });
      }
    }
  }

  private createJson = (dailyQuestions: DailyQuestion[]): object => {
    var countQuestions = 1;

    const json = {
      completeText: this.props.t("EndDailyChallenge.Finalizar"),
      locale: i18n.language,
      pages: dailyQuestions.map((q, index: number) => {
        var questionPage = {
          pageIndex: index,
          key: index,
          name: "Pregunta " + countQuestions++,
          questions: [
            {
              type: q.Type,
              name: q.Id,
              title: q.Title,
              description: "",
              descriptionLocation: "underInput",
              isRequired: true,
              correctAnswer: q.CorrectAnswerId,
              questionIndex: index,
              rateValues: q.Options.map((opt) => {
                return {
                  value: opt.Id,
                  text: opt.Value,
                };
              }),
            },
          ],
        };
        return questionPage;
      }),
    };

    return json;
  };

  private onValueChanged = (e: Survey.ReactSurveyModel, options: any) => {
    var index = options.question.visibleIndex;
    var userAnswers = utils.cloneObject(this.state.userAnswers);
    var correctAnswer: string = options.question.correctAnswer + "";
    var value: string = options.question.value + "";
    let userAnswer = createEmptyUserAnswer();

    if (
      this.state.userAnswers[index] === undefined ||
      this.state.userAnswers[index] === null
    ) {
      const questions = this.props.isExtraQuestions
        ? this.props.extraordinaryQuestions
        : this.props.dailyQuestions;

      userAnswer.QuestionId = questions[userAnswers.length].Id;
      userAnswer.CategoryId = questions[userAnswers.length].Category;
      userAnswer.DateQuestion = new Date();
      userAnswer.AnswerSelected.Id = options.question.value;
      userAnswer.AnswerSelected.Value = options.question.displayValue;
      userAnswer.IsCorrectAnswer = correctAnswer === value;
      this.state.userAnswers[index] = userAnswer;
    }

    if (correctAnswer === value) {
      options.question.css.rating.selected = "active correcta";
      options.question.description = this.props.t("DailyQuestions.Correcto");
      options.question.enableIf = "false";
      e.showNavigationButtons = "bottom";
    } else {
      options.question.css.rating.selected = "active erronea";
      options.question.description = this.props.t("DailyQuestions.Incorreto");
      e.showNavigationButtons = "hideNavigationButtons";
    }
    return;
  };

  private onComplete = async (e: Survey.ReactSurveyModel) => {
    await this.saveQuestions();
  };

  private async saveQuestions() {
    this.setState({
      submittingAnswers: true,
    });
    userApi
      .EvaluateSurvey(this.state.userAnswers, this.props.isExtraQuestions)
      .then(async (result) => {
        await this.props.questionsComplete();

        if (this.props.isExtraQuestions) {
          this.props.closePanel(0, result.points);

          this.setState({
            extraordinaryQuestionCompleted: true,
          });
        } else {
          this.props.closePanel(result.points, 0);
        }
        this.setState({
          submittingAnswers: false,
        });

        await this.props.GetTopWeekUsers();
        await this.props.GetTopUsers();
      });

    return;
  }

  public render() {
    const showModal = this.props.questionsCompletedToday
      ? this.props.isExtraQuestions
        ? this.state.extraordinaryQuestionCompleted
        : true
      : false;
    return (
      <Modal show={this.props.showSurvey} onHide={() => { }} size="lg">
        <Modal.Body>
          {showModal ? (
            <div>
              <LoadingComponent className="loading-container" />
            </div>
          ) : this.props.loadingQuestions || this.state.submittingAnswers ? (
            <div>
              <LoadingComponent
                className="loading-questions"
                text={this.props.t("DailyQuestions.Loading")}
              />
            </div>
          ) : (
            <div className="SurveyContainer">
              {this.state.userAnswers.length === 0 ? (
                <div className="botonCerrar">
                  <button className="btn" onClick={() => this.props.cancel()}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              ) : null}
              {this.state.showDailyQuestions ? (
                <Survey.Survey
                  completedHtml="</div></div>"
                  json={
                    this.props.isExtraQuestions
                      ? this.state.extraordinaryQuestionsJson
                      : this.state.dailyQuestionsJson
                  }
                  showPrevButton={false}
                  showProgressBar={"bottom"}
                  onValueChanged={this.onValueChanged}
                  onComplete={this.onComplete}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
export const DailyQuestions = translate("common")(DailyQuestionsPage);
