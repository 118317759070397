import * as React from "react";
import "./css/style.css";

export interface IButtonProps {
  className?: string;
  clickEvent?: any;
  text: string;
  icon?: string;
  disabled?: boolean;
  title?:string;
}

export const Button = function (props: IButtonProps) {
  return (
    <>
      <button
        type="button"
        className={`btn ${props.className}`}
        onClick={props.clickEvent}
        disabled={props.disabled}
        title={props.title}
      >
        {props.icon != undefined ? (
          props.icon.length > 0 ? (
            <i className={`fas ${props.icon}`}></i>
          ) : null
        ) : (
          <></>
        )}
        
        {props.text}
        
        {}
      </button>
    </>
  );
};
