import * as React from "react";
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import User from "../../models/user";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import balloonImg from "../../img/globos.png"
import chkImg from "../../img/check-icon.png"

export interface IProjectsProps extends TransProps {
  t: any;
  user: User;
}

export interface IProjectsState {
  t: any;
}

class RetoCompletado extends React.Component<IProjectsProps, IProjectsState> {

  constructor(props: any) {
    super(props);
    this.state = ({
      t: I18nextProvider
    })
  }
  public render() {
    return (
      <div className="row">
        <div className="col">
          <img src={balloonImg} alt="reto" />
          <div>
            <h4>{this.props.t("Home.RetoSuperado")}</h4>
            <p>{new Date().getDay() >= 5 || new Date().getDay() === 0 ? this.props.t('Home.VuelveLun') : this.props.t('Home.ComeBackTomorrow')}</p>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <img src={chkImg} alt="check" />
            <h4>{this.props.user.DailyPoints}</h4>
          </div>
        </div>
      </div>
    );
  }
}
export const RetoCumplido = withAITracking(new ReactPlugin(), translate('common')((RetoCompletado)), 'RetoCumplido', 'empezarReto container');