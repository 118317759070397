import { Icon } from "@fluentui/react";
import * as React from "react";
import { translate, TransProps } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Button } from "../../common/components/button/button";
import { Input } from "../../common/components/input/input";
import { Select } from "../../common/components/select/select";
import Topic, { CreateEmptyTopic } from "../../models/topic";
import { ModalConfirm } from "../../common/components/modals/confirmModal";
import TopicModal from "./Modals/TopicModal";

import "./style/style.css";
import { LoadingComponent } from "../../common/components/Loading/loading";
export interface IAdminTopicProps extends TransProps {
  t: any;
  topics: Topic[];
  isLoading: boolean;

  GetTopics: () => Promise<Topic[]>;
  NewTopic: (newTopic: Topic, pageText: any) => void;
  UpdateTopic: (newTopic: Topic, pageText: any) => void;
  DeleteTopic: (newTopic: Topic, pageText: any) => void;
}

export interface IAdminTopicState {
  topics: Topic[];
  showModal: boolean;
  showDeleteModal: boolean;
  mode: string;
  selectedTopic: Topic;
  filteredTopics: Topic[];
  checkedIsPublic: boolean;
  checkedIsPrivate: boolean;
  findName: string;
  markedAsDefault: boolean;
}

class AdminTopic extends React.Component<IAdminTopicProps, IAdminTopicState> {
  constructor(props: any) {
    super(props);
    this.state = {
      topics: [],
      showModal: false,
      mode: "add",
      selectedTopic: CreateEmptyTopic(),
      showDeleteModal: false,
      filteredTopics: [],
      checkedIsPublic: false,
      checkedIsPrivate: false,
      findName: "",
      markedAsDefault: false,
    };

    this.DeleteTopic = this.DeleteTopic.bind(this);
    this.ManageTopic = this.ManageTopic.bind(this);
  }

  async componentDidMount() {
    await this.props.GetTopics();
    this.InitFilteredTopics();
  }

  public render() {
    return this.props.isLoading ? (
      <LoadingComponent className="loading-center-in-component" />
    ) : (
      <>
        <div className="topic-container">
          <div className="button-zone">
            <Button
              className={"btn-primary custom  mb-1"}
              clickEvent={() => {
                this.setState({
                  showModal: true,
                  mode: "add",
                });
              }}
              icon={"fa-plus mr-2"}
              text={this.props.t("Topic.Add")}
            />
          </div>
          <div className="filter-zone">
            <div className="title-filter">
              <Input
                type="text"
                className="form-control"
                placeholder={this.props.t("Topic.FindText_Title")}
                contentValue={this.state.findName}
                changeEvent={(e) => this.Filter(e.target.value, null)}
              />
            </div>
            <div className="isPublic-filter">
              <div>
                <input
                  id="isPublic"
                  type="radio"
                  name="isPublic"
                  value="true"
                  checked={this.state.checkedIsPublic}
                  onChange={(e) => this.Filter(null, e.target.value)}
                />
                <label htmlFor="isPublic">
                  {this.props.t("Topic.IsPublic")}
                </label>
              </div>

              <div>
                <input
                  id="isPrivate"
                  type="radio"
                  name="isPublic"
                  value="false"
                  checked={this.state.checkedIsPrivate}
                  onChange={(e) => this.Filter(null, e.target.value)}
                />
                <label htmlFor="isPrivate">
                  {this.props.t("Topic.IsPrivate")}
                </label>
              </div>
            </div>
            <div className="clear-filter">
              <Button
                clickEvent={() => this.clearFilter()}
                text={this.props.t("AdminUser.BorrarFiltros")}
                icon="fa-filter mr-2"
              />
            </div>
          </div>
          <div className="topic-content">
            <table>
              <tbody>
                <tr>
                  <th>
                    <label className="text-secondary">
                      {this.props.t("Topic.Table.Title")}
                    </label>
                  </th>

                  <th>
                    <label className="text-secondary">
                      {this.props.t("Topic.Table.IsPublic")}
                    </label>
                  </th>
                  <th>
                    <label className="text-secondary">
                      {this.props.t("Topic.Table.IsByDefault")}
                    </label>
                  </th>
                  <th className="action-cell">
                    <label className="text-secondary">
                      {this.props.t("Topic.Table.Actions")}
                    </label>
                  </th>
                </tr>
                {this.RenderTopics()}
              </tbody>
            </table>
          </div>
        </div>
        {this.RenderAddModal()}
        {this.RenderDeleteModal()}
      </>
    );
  }

  public RenderTopics() {
    return (
      <>
        {this.state.filteredTopics != null &&
          this.state.filteredTopics != undefined &&
          this.state.filteredTopics.length > 0
          ? this.state.filteredTopics.map((item: Topic, index: number) => (
            <tr className="topic-row" key={index}>
              <td>
                <p className="pl-2 text-truncate" title={item.title}>
                  {item.title}
                </p>
              </td>
              <td>
                {item.isPublic ? (
                  <div className="isPublic">
                    {this.props.t("Topic.IsPublic")}
                  </div>
                ) : (
                  <div className="isPrivate">
                    {this.props.t("Topic.IsPrivate")}
                  </div>
                )}
              </td>
              <td>
                {item.isByDefault ? (
                  <div className="isByDefault">
                    <Icon iconName="CheckMark" />
                  </div>
                ) : (
                  <div className="isNotByDefault">
                    <Icon iconName="Cancel" />
                  </div>
                )}
              </td>
              <td>
                <div className="action-zone">
                  <Icon
                    iconName="Trash"
                    onClick={() => {
                      this.setState({
                        showDeleteModal: true,
                        mode: "delete",
                        selectedTopic: item,
                        markedAsDefault: item.isByDefault,
                      });
                    }}
                  />
                  <Icon
                    iconName="Edit"
                    onClick={() => {
                      this.setState({
                        selectedTopic: item,
                        mode: "update",
                        showModal: true,
                        markedAsDefault: item.isByDefault,
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
          ))
          : null}
      </>
    );
  }
  public RenderAddModal() {
    const pageText = this.props.t("Topic.Modal", { returnObjects: true });
    return (
      <TopicModal
        close={() =>
          this.setState({
            showModal: false,
            selectedTopic: CreateEmptyTopic(),
          })
        }
        isShown={this.state.showModal}
        onHide={() =>
          this.setState({
            showModal: false,
            selectedTopic: CreateEmptyTopic(),
          })
        }
        pageText={pageText}
        selectedTopic={this.state.selectedTopic}
        ReturnFn={this.ManageTopic}
        actionButtonText={this.GetButtonActionText()}
        markedAsDefault={this.state.markedAsDefault}
      />
    );
  }

  public async ManageTopic(title, isPublic, isByDefault) {
    var topic: Topic = {
      title: title,
      isPublic: isPublic,
      id: this.state.selectedTopic.id || "3bce4931-6c75-41ab-afe0-2ec108a30860",
      tenantId:
        this.state.selectedTopic.tenantId ||
        "3bce4931-6c75-41ab-afe0-2ec108a30860",
      isByDefault: isByDefault,
    };

    switch (this.state.mode) {
      case "add":
        await this.props.NewTopic(
          topic,
          this.props.t("Topic.ToastrMsg", { returnObjects: true })
        );

        break;
      case "update":
        await this.props.UpdateTopic(
          topic,
          this.props.t("Topic.ToastrMsg", { returnObjects: true })
        );
        break;
    }

    this.setState({
      showModal: false,
      showDeleteModal: false,
      selectedTopic: CreateEmptyTopic(),
    });
    await this.props.GetTopics();
    this.InitFilteredTopics();
  }
  public GetButtonActionText() {
    switch (this.state.mode) {
      case "add":
        return this.props.t("Topic.Add");

      case "update":
        return this.props.t("Topic.Save");

      case "delete":
        return this.props.t("Topic.Delete");
    }
    return "";
  }

  public RenderDeleteModal() {
    const header = {
      title: this.props.t("Topic.ConfirmDeleteTitle"),
      onClick: this.DeleteTopic,
    };

    const body = {
      text:
        "<div> " +
        this.props.t("Topic.ConfirmDeleteText") +
        " <br /><br /> " +
        this.props.t("Topic.Modal.Title") +
        ": " +
        this.state.selectedTopic.title +
        "<br />" +
        this.props.t("Topic.Modal.FieldPublic") +
        ": " +
        (this.state.selectedTopic.isPublic
          ? this.props.t("Topic.Modal.IsPublic")
          : this.props.t("Topic.Modal.IsNotPublic")) +
        "</div>",
    };

    const footer = {
      onClick: this.DeleteTopic,
      textAccept: this.props.t("Topic.Delete"),
      textCancel: this.props.t("Topic.Cancel"),
    };

    return (
      <ModalConfirm
        show={this.state.showDeleteModal}
        header={header}
        body={body}
        footer={footer}
      />
    );
  }
  public async DeleteTopic(isToDelete: boolean) {
    this.setState({
      showDeleteModal: false,
      selectedTopic: CreateEmptyTopic(),
    });

    if (isToDelete) {
      await this.props.DeleteTopic(
        this.state.selectedTopic,
        this.props.t("Topic.ToastrMsg", { returnObjects: true })
      );

      await this.props.GetTopics();
      this.InitFilteredTopics();
    }
  }
  public Filter(find: string = null, isPublic = null) {
    var filterTopics = [];

    if (find != null) {
      this.setState({
        findName: find,
      });
      filterTopics = this.props.topics.filter(
        (x) => x.title.toLowerCase().indexOf(find.toLowerCase()) >= 0
      );
    }

    if (isPublic != null) {
      this.setState({
        checkedIsPrivate: isPublic == "false",
        checkedIsPublic: isPublic == "true",
      });
      filterTopics = this.props.topics.filter(
        (x) => x.isPublic == (isPublic == "true")
      );
    }

    this.setState({
      filteredTopics: filterTopics,
    });
  }

  clearFilter() {
    this.setState({
      checkedIsPublic: false,
      checkedIsPrivate: false,
      filteredTopics: this.props.topics,
      findName: "",
    });
  }
  InitFilteredTopics() {
    this.setState({
      filteredTopics: this.props.topics,
    });
  }
}

export const AdminTopicComponent = translate("common")(AdminTopic);
