import { headersRequest, methodRequest } from '../common/constants/api';
import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import SendChallenge from '../models/sendChallenge';

const baseURL = process.env.REACT_APP_API + `/api/user/sendchallenge/`;
const pnp = new PnPFetchClient(msalInstance);

const SendChallengeAsync = async (sendChallenge: SendChallenge): Promise<boolean> => {
    const sendChallengeUrl = `${baseURL}`;
    const obj = {
        method: methodRequest.POST,
        headers: headersRequest.ACCEPT_CONTENT_TYPE_JSON,
        body: JSON.stringify(sendChallenge)
    };
    let response = await pnp.fetch(sendChallengeUrl, obj);
    return response.status === 200;
};

export const challengeApi = {
    SendChallengeAsync,
}