import { levelsApi } from "../../api/levelsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import LevelSettings from "../../models/levelSettings";

export const getLevelsAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    levelsApi.GetLevels().then((result) => {
        dispatch(getLeventsCompleted(result));
        dispatch(httpCallEndAction());
    }).catch(error => {
        //TOASTR?
    });
}

const getLeventsCompleted = (levels: LevelSettings[]) => ({
    type: actionsDefinition.levels.GET_LEVELS_COMPLETED,
    payload: levels
});
