import { settingsApi } from "../../api/settingsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import UploadedBanners from "../../models/uploadedBanners";

export const checkUploadedBannersAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    settingsApi.CheckUploadedBanners().then((result) => {
        dispatch(checkUploadedBannersCompleted(result));
    }).catch(error => {

    });
}

const checkUploadedBannersCompleted = (result: UploadedBanners) => ({
    type: actionsDefinition.GET_UPLOADED_BANNERS,
    payload: result
});