import * as React from "react";
import { translate, TransProps } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import history from "../../history/history";
import "./css/style.css";
import User from "../../models/user";
import { utils } from "../../common/functions/utils";
import { LoadingComponent } from "../../common/components/Loading/loading";
import iconPointBlue from "../../img/points-icon-blue.png";
import adminConsentTeamPlayImg from "../../img/adminConsentTeamPlay.svg";

export interface IProps extends TransProps {
  t: any;
  user: User;
  adminConsentOptionSelected: boolean;
  saveAdminConsentSelected: () => void;
  loadCurrentUserAction: () => void;
}
export interface IState {
  loadingUser: boolean;
}

class AdminConsentComponent extends React.Component<
  RouteComponentProps & IProps,
  IState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadingUser: false,
    };
  }
  private readonly giveConsentRedirect = () => {
    if (window.location.href.includes("/teams")) {
      window.open(process.env.REACT_APP_API);
    } else {
      let clientId = process.env.REACT_APP_MSALCLIENTID;
      this.props.saveAdminConsentSelected();
      window.open(
        process.env.REACT_APP_MSALAUTHORITY +
        `/adminconsent?client_id=${clientId}`
      );
    }
  };

  private readonly openDemoVersion = () => {
    this.props.saveAdminConsentSelected();
    history.push("/?demo=true");
  };

  componentDidMount() {
    if (utils.adminConsentGranted()) {
      // history.push("/");
      this.props.saveAdminConsentSelected();
    }
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (utils.adminConsentGranted() && !this.props.adminConsentOptionSelected) {
      this.props.saveAdminConsentSelected();
    }
    if (
      prevProps.adminConsentOptionSelected !==
      this.props.adminConsentOptionSelected &&
      !this.state.loadingUser
    ) {
      this.props.loadCurrentUserAction();
      this.setState({
        loadingUser: true,
      });
    }
    if (this.props.user !== null) {
      if (prevProps.user.Id !== this.props.user.Id) {
        history.push("/");
      }
    }
  }
  render() {
    return (
      <LoadingComponent className={"loading-container"}></LoadingComponent>
    );

    return (
      <>
        {this.state.loadingUser ? (
          <LoadingComponent />
        ) : (
          <div className="admin-consent-main d-flex flex-wrap">
            <div className="admin-consent-description col-md-12 col-lg-8">
              <h2 className="title">
                <span></span>
                {this.props.t("AdminConsent.Title")}
              </h2>
              <p className="font-weight-bold">
                {this.props.t("AdminConsent.Hello")}
              </p>
              <ul>
                <li>
                  <span className="list-number">1</span>
                  <p>
                    <span className="font-weight-bold">
                      {this.props.t("AdminConsent.FirstInfoPart1")}
                    </span>
                    {this.props.t("AdminConsent.FirstInfoPart2")}
                  </p>
                </li>
                <li>
                  <span className="list-number">2</span>
                  <p>
                    {this.props.t("AdminConsent.SecondInfoPart1")}
                    <span className="font-weight-bold d-block">
                      {" "}
                      <img src={iconPointBlue} alt="Star Icon"></img>
                      {this.props.t("AdminConsent.SecondInfoPart2")}
                    </span>
                  </p>
                </li>
                <li>
                  <span className="list-number">3</span>
                  <p>{this.props.t("AdminConsent.ThirdInfo")}</p>
                </li>
              </ul>
              <div className="admin-consent-warning">
                <p className="consent-warning-text">
                  {this.props.t("AdminConsent.WarningPart1")}
                </p>
                <p>
                  <span className="font-weight-bold">
                    {this.props.t("AdminConsent.WarningPart2")}
                  </span>
                  {this.props.t("AdminConsent.WarningPart3")}
                </p>
              </div>
              <div className="admin-consent-no-consent">
                <p>
                  <span className="font-weight-bold">
                    {this.props.t("AdminConsent.WithoutConsentPart1")}
                  </span>
                  {this.props.t("AdminConsent.WithoutConsentPart2")}
                  <span className="font-weight-bold">
                    {this.props.t("AdminConsent.WithoutConsentPart3")}
                  </span>
                  {this.props.t("AdminConsent.WithoutConsentPart4")}
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 consent-options">
              <div>
                <img src={adminConsentTeamPlayImg} alt="Team Play"></img>
              </div>
              <div>
                <button
                  className="btn btn-accept-consent"
                  onClick={this.giveConsentRedirect}
                >
                  {this.props.t("AdminConsent.Buttons.GiveConsent")}
                </button>
                {/* <button className="btn btn-go-demo" onClick={this.openDemoVersion}>{this.props.t("AdminConsent.Buttons.Demo")}</button> */}
                <div>
                  <p>{this.props.t("AdminConsent.AdditionalInfo")}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export const AdminConsentPage = withAITracking(
  new ReactPlugin(),
  translate("common")(AdminConsentComponent)
);
