import { Store, createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import logger from 'redux-logger';
import { state, IStateReducer } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

var middlewares;
var env = process.env.REACT_APP_ENVIRONMENT;

if (env === 'LOCAL' || env === 'DEVELOPMENT' || env === 'PRE') {
  middlewares = [reduxThunk];
}
else {
  middlewares = [reduxThunk];
}

export const store: Store<IStateReducer> = createStore(
  state,
  composeWithDevTools(applyMiddleware(...middlewares))
);