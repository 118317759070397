import { actionsDefinition } from '../actions/actionsDefinition';

export const socialNetworkReducer = (state: string = "", action: any) => {
    switch (action.type) {
        case actionsDefinition.GET_BLOB_SOCIAL_NETWORK:
            return handleSocialNetworkCompleted(state, action.payload);
    }
    return state;
};

const handleSocialNetworkCompleted = (state: string, payload: string) => {
    return payload;
};