export default interface Topic {
    id: string;
    title: string;
    isPublic: boolean;
    tenantId: string;
    isByDefault: boolean;
}

export const CreateEmptyTopic = (): Topic => {
    return {
        id: "",
        tenantId: "",
        title: "",
        isPublic: true,
        isByDefault: false
    };
}