import { actionsDefinition } from '../actions/actionsDefinition';
import Settings, { createNewSettings } from '../models/settings';

export interface SettingsState {
  settings: Settings;
  settingsHttpCallComplete: boolean;
  bannerHttpCallComplete: boolean;
}

function createNewSettingsState(): SettingsState {
  return {
    settings: createNewSettings(),
    settingsHttpCallComplete: false,
    bannerHttpCallComplete: true
  };
}

export const settingsReducer = (state: SettingsState = createNewSettingsState(), action): SettingsState => {
  switch (action.type) {
    case actionsDefinition.settings.GET_SETTINGS_COMPLETED:
      return handleGetSettingsCompleted(state, action.payload);
    case actionsDefinition.settings.UPDATE_SETTINGS_COMPLETED:
      return handleUpdateSettingsCompleted(state, action.payload);
    case actionsDefinition.settings.CLEAR_SETTINGS_STATE_COMPLETED:
      return handleClearSettingsStateCompleted();
    case actionsDefinition.settings.SETTINGS_HTTP_CALL_STARTED:
      return handleSettingsHttpCallStart(state);
      case actionsDefinition.settings.UPLOAD_BANNER_COMPLETED:
      return handleUploadBannerCompleted(state);
    default:
      return state;
  }
};

const handleGetSettingsCompleted = (state: SettingsState = createNewSettingsState(), payload: Settings): SettingsState => {
  return {
    ...state,
    settings: payload,
    settingsHttpCallComplete: true
  }
};

const handleUpdateSettingsCompleted = (state: SettingsState = createNewSettingsState(), payload: Settings): SettingsState => {
  return {
    ...state,
    settings: payload,
    settingsHttpCallComplete: true
  }
};

const handleSettingsHttpCallStart = (state: SettingsState = createNewSettingsState()): SettingsState => {
  return {
    ...state,
    settingsHttpCallComplete: false,
    bannerHttpCallComplete: false
  };
};

const handleUploadBannerCompleted = (state: SettingsState = createNewSettingsState()): SettingsState => {
  return {
    ...state,
    bannerHttpCallComplete: true
  };
};

const handleClearSettingsStateCompleted = (): SettingsState => {
  return createNewSettingsState();
};

