import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { RouteComponentProps } from "react-router";
import installImg from "../../img/sso/usercontext-pq-panel.svg";
import checkImg from "../../img/sso/check.svg";
import { connect } from "react-redux";
import UserConsentPageText from "../../locales/es/translation.json";

import "./css/init.css";
import { translate, TransProps } from "react-i18next";

interface IState extends TransProps {
    withError: boolean;
    error: string;
}

export interface IProps extends TransProps {
    t: any;
  }

export class UserConsent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
    constructor(props)  {
        super(props);
        this.state = { withError: false, error: ''};
    }

    public render() {
        let content = this.state.withError ? (
            <>
                <p>We have an error</p>
                <p>{this.state.error}</p>
            </>
        ) : (
              <div className="container">
            <div className="offset-lg-1 col-lg-10"  id="pq-panel">
              <div className="row text-center wzr-header n-mp">
                <div className="col-6 d-none d-md-block div-install-pq">
                  <p className="install-pq disabled">
                  <span className="checked"><img src={checkImg}></img></span>
                
                    {this.props.t("UserConsentPage.Step1Text")} 
                  </p>
                </div>
                <div className="col-sm-12 col-md-6  n-mp">
                  <p className="install-pq">
                    <span>2</span>
                    {this.props.t("UserConsentPage.Step2Text")} 
                  </p>
                </div>
              </div>
              <div className="row wzr-content">
                <div className="col-6 d-none d-md-block align-self-center text-center">
                  <div className="pt-2-p">
                    <img src={installImg} />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <h2>
                  {this.props.t("UserConsentPage.Title")} 
                  </h2>
                  <p>
                  {this.props.t("UserConsentPage.Content-pt1")} 
                  </p>
                  <ul id="lst-pq">
                    <li>{this.props.t("UserConsentPage.Content-pt2.li1")} </li>
                    <li>{this.props.t("UserConsentPage.Content-pt2.li2")} </li>
                  </ul>
                  
                  <p dangerouslySetInnerHTML={{__html: this.props.t("UserConsentPage.Content-pt3")}}>
                  
                  </p>
                  <p>
                  {this.props.t("UserConsentPage.Content-pt4")} 
                  </p>
                  <button onClick={() => this._getUserConsent()} className="btn btn-danger">
                  {this.props.t("UserConsentPage.BtnPermission")} 
                  </button>
                </div>
              </div>
              </div>
          </div>
           
        );
      
        return (
        <>
            {content}
        </>
        );
    }

    private _getUserConsent() {
        microsoftTeams.initialize();
        microsoftTeams.authentication.authenticate({
          url: window.location.origin + "/teams/login",
          width: 565,
          height: 600,
          successCallback: function (result) {
              window.location.assign(window.location.origin + "/teams");      
          },
          failureCallback: function (reason) {
              this.state = { withError: true, error: reason};
              //this.setState({ withError: true, error: reason});
          }      
        });    
    }
}

const mapStateToProps = () => ({});

export const UserConsentPage = translate("common")(UserConsent);

export const UserConsentContainer: any = connect(
    mapStateToProps
  )(UserConsentPage);