export default interface UserConnection {
    Id: string;
    TenantId: string;
    UserId: string;
    LastConnection: Date;
    FirstConnection: Date;
    LastParticipation: Date;
    MondayParticipation: boolean;
    TuesdayParticipation: boolean;
    WednesdayParticipation: boolean;
    ThursdayParticipation: boolean;
    FridayParticipation: boolean;
    StreakActive: boolean;
    ExtraordinaryQuestionDay: string;
    ExtraordinaryQuestionAnswered: boolean;

}

export const createEmptyUserConnection = () => {
    let newUserConnection: UserConnection = {
        Id: '',
        TenantId: '',
        UserId: '',
        LastConnection: null,
        FirstConnection: null,
        LastParticipation: null,
        MondayParticipation: false,
        TuesdayParticipation: false,
        WednesdayParticipation: false,
        ThursdayParticipation: false,
        FridayParticipation: false,
        StreakActive: false,
        ExtraordinaryQuestionDay: '',
        ExtraordinaryQuestionAnswered: false
    };

    return newUserConnection;
};