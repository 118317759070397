import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import groupImg from "../../img/Grupo.png";
export interface IProjectsProps extends TransProps {
    hideWhatIsPQ: () => void;

    t: any;
}

export interface IProjectsState {
    whatIsPlayQuiz: boolean;
    t: any;
}

class WhatIsPlayQuizPage extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            whatIsPlayQuiz: false,
            t: I18nextProvider
        });
    }

    public render() {
        return (
            <Modal show={true} onHide={() => { }}>
                <Modal.Body>
                    <div className="popUpImage">
                        <div className="d-flex flex-column text-center">
                            <figure>
                                <img className="Personajes-bienvenida" src={groupImg} alt="Personajes bienvenida" />
                            </figure>
                            <span className="QueesPlayQuiz text-left">{this.props.t("WhatIsPlayQuiz.Titulo")} </span>
                        </div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <hr />
                    <div className="popUpButtons">
                        <div>
                            <p>{this.props.t("WhatIsPlayQuiz.Descripcion")}</p>
                            <p>{this.props.t("WhatIsPlayQuiz.Descripcion2")}</p>
                        </div>
                    </div>
                    <div className="">
                        <div className="footer-buttons">
                            <div></div>
                            <div className="text-right">
                                <button type="button" className="btn" onClick={this.props.hideWhatIsPQ}>
                                    {this.props.t("WhatIsPlayQuiz.Aceptar")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const WhatIsPlayQuiz = withAITracking(new ReactPlugin(), translate('common')((WhatIsPlayQuizPage)));