import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { userApi } from "../../api/userApi";
import history from '../../history/history';
import { store } from '../../store';

export const loadTenantFirstConnectionAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.GetTenantFirstConnection().then((result) => {
        dispatch(loadTenantFirstConnectionCompleted(result));
    })

    const loadTenantFirstConnectionCompleted = (result: boolean) => ({
        type: actionsDefinition.GET_TENANT_FIRST_CONNECTION,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}