import * as React from "react";

export interface IInputProps {
  className?: string;
  changeEvent?: any;
  type: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  contentValue?: string;
}

export const Input = function (props: IInputProps) {
  return (
    <>
      <input
        defaultValue={props.value}
        type={props.type}
        value={props.contentValue}
        onChange={props.changeEvent}
        className={props.className}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </>
  );
};
