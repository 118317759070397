export default interface ExtraordinaryQuestionsSettings {   
    isActive: boolean;
    numberQuestions: number;
    extraPointsSuccess: number;
    extraPointsFailure: number;
}

export const createNewExtraordinaryQuestionsSettings = (): ExtraordinaryQuestionsSettings => {
    const defaultNumberQuestions = parseInt(process.env.DefaultSettings_ExtraOrdinaryQuestions) || 3;
    const defaultExtraPoints = parseInt(process.env.DefaultSettings_ExtraPoints) || 8;
    const defaultAnswerPoints = parseInt(process.env.DefaultSettings_AnswerPoints) || 5;

    return {
        isActive: false,
        numberQuestions: defaultNumberQuestions,
        extraPointsSuccess: defaultExtraPoints,
        extraPointsFailure: defaultAnswerPoints
    }
}