import { questionApi } from "../../api/questionApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import * as toastr from 'toastr';

export const uploadQuestionsAction = (formdata: FormData, successMessage: string, topic: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    questionApi.UploadQuestionAsync(formdata, topic).then((result) => {
        dispatch(uploadQuestionsCompleted(result));
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });


    const uploadQuestionsCompleted = (result: boolean) => ({
        type: actionsDefinition.UPLOAD_QUESTION,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}