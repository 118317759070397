import { actionsDefinition } from '../actions/actionsDefinition';

export const questionsCompletedTodayReducer = (state: boolean = false, action: any) => {
    switch (action.type) {
        case actionsDefinition.SET_QUESTION_COMPLETED:
            return handleQuestionsCompletedTodayCompleted(state, action.payload);
    }
    return state;
};

const handleQuestionsCompletedTodayCompleted = (state: boolean, payload: boolean) => {
    return payload;
};