import { actionsDefinition } from '../actions/actionsDefinition';
import Question from '../models/question';

export const questionReducer = (state: Question[] = [], action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_QUESTIONS:
            return handleLoadQuestionsCompleted(state, action.payload);
    }
    return state;
};

const handleLoadQuestionsCompleted = (state: Question[], payload: Question[]) => {
    return payload;
}