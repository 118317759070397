import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminUser } from './adminUser';
import { loadUsersAction, saveUserAction, deleteUserAction, editUserAction, resetScoreUsersAction } from '../../actions/Users';
import User from '../../models/user';

const mapStateToProps = (state: IStateReducer) => ({
    users: state.users,
    user: state.currentUser.currentUser
});

const mapDispatchToProps = (dispatch: any) => ({
    getUsers: () => dispatch(loadUsersAction()),
    addUser: (user: User, successMessage: string) => dispatch(saveUserAction(user, successMessage)),
    deleteUser: (userId: string, successMessage: string) => dispatch(deleteUserAction(userId, successMessage)),
    editUser: (user: User, successMessage: string) => dispatch(editUserAction(user, successMessage)),
    resetScoreUsers: (successMessage: string)=> dispatch(resetScoreUsersAction(successMessage))
});

export const AdminUserContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUser);