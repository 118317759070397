import * as React from "react";
import { RouteComponentProps } from "react-router";
import { translate, TransProps } from "react-i18next";
import LevelSettings from "../../models/levelSettings";
import PaginationComponent from "../../common/pagination";
import Modal from "react-bootstrap/Modal";
import GenericPayload from "../../models/genericPayload";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { MessageAlertType } from "../../common/constants/enum";
import { LoadingComponent } from "../../common/components/Loading/loading";
import "./css/style.css";

export interface IAdminLevelsProps extends TransProps {
  t: any;
  levels: LevelSettings[];
  upsertLevel: LevelSettings;
  levelsCallCompleted: boolean;

  getLevels: () => void;
  updateLevels: (successMessage: string) => void;
  addLevels: (successMessage: string) => void;
  deleteLevels: (levelsId: string, successMessage: string) => void;
  clearUpsertLevel: () => void;
  updateLevelProperty: (genericPayload: GenericPayload) => void;
  loadLevelPropertys: (level: LevelSettings) => void;
}

export interface IAdminLevelsState {
  panelNewLevel: boolean;
  panelEditLevel: boolean;
  panelDeleteUserIsOpen: boolean;
  disanbledButton: boolean;
  totalPages: number;
  page: number;
}

class AdminLevelsPage extends React.Component<
  RouteComponentProps & IAdminLevelsProps,
  IAdminLevelsState
> {
  isLoading = true;
  constructor(props: any) {
    super(props);
    this.state = {
      panelNewLevel: false,
      panelEditLevel: false,
      panelDeleteUserIsOpen: false,
      disanbledButton: false,
      totalPages: 1,
      page: 1,
    };
    this.checkObligatoryInputs = this.checkObligatoryInputs.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  async componentDidMount() {
    await this.props.getLevels();
    this.isLoading = false;
  }

  componentDidUpdate(
    prevProps: IAdminLevelsProps,
    prevState: IAdminLevelsState
  ) {
    if (this.props !== prevProps) {
      if (this.props.levels && this.props.levels !== prevProps.levels) {
        this.setState({
          page: 1,
        });
      }
      if (this.props.upsertLevel !== prevProps.upsertLevel) {
        const disabled = this.checkObligatoryInputs();
        if (disabled !== this.state.disanbledButton) {
          this.setState({
            disanbledButton: disabled,
          });
        }
      }
    }
  }

  renderLevelModal() {
    return (
      <Modal
        show={this.state.panelNewLevel || this.state.panelEditLevel}
        onHide={this.hidenShowLevelModal}
      >
        <Modal.Header>
          <Modal.Title className=" d-flex justify-content-between w-100">
            <div>
              {this.state.panelNewLevel
                ? this.props.t("Settings.Levels.New")
                : this.props.t("Settings.Levels.Edit")}
            </div>
            <div className="botonCerrar">
              <button className="btn" onClick={this.hidenShowLevelModal}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row form-group">
              <div className="col-12">
                <label className="mx-2">
                  {this.props.t("Settings.Levels.Name")}
                </label>
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  placeholder={this.props.t("Settings.Levels.Name") + "..."}
                  value={this.props.upsertLevel.name || ''}
                  onChange={(e) => this.onInputChange(e)}
                />
              </div>
              <div className="col-12">
                <label className="mx-2">
                  {this.props.t("Settings.Levels.Color")}
                </label>
                <input
                  id="color"
                  type="text"
                  className="form-control"
                  placeholder={this.props.t("Settings.Levels.Color") + "..."}
                  value={this.props.upsertLevel.color|| ''}
                  onChange={(e) => this.onInputChange(e)}
                />
              </div>
              <div className="col-12 d-inline-flex">
                <div className="col-6 pl-0">
                  <label className="mx-2">
                    {this.props.t("Settings.Levels.InitValue")}
                  </label>
                  <input
                    id="scoreInit"
                    type="number"
                    min="0"
                    className="form-control"
                    placeholder={
                      this.props.t("Settings.Levels.InitValue") + "..."
                    }
                    value={this.props.upsertLevel?.scoreInit || 0}
                    onChange={(e) => this.onInputChange(e)}
                  />
                </div>
                <div className="col-6 pr-0">
                  <label className="mx-2">
                    {this.props.t("Settings.Levels.EndValue")}
                  </label>
                  <input
                    id="scoreEnd"
                    type="number"
                    className="form-control"
                    placeholder={
                      this.props.t("Settings.Levels.EndValue") + "..."
                    }
                    value={this.props.upsertLevel?.scoreEnd || 0}
                    onChange={(e) => this.onInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.hidenShowLevelModal}
          >
            {this.props.t("AdminQuestion.Botones.Cancelar")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={this.state.disanbledButton}
            onClick={this.handleNewQuestion}
          >
            {this.props.t("Settings.Levels.Guardar")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  private readonly renderDeleteLevelModal = () => {
    return (
      <Modal
        show={this.state.panelDeleteUserIsOpen}
        onHide={() => {
          this.setState({ panelDeleteUserIsOpen: false });
        }}
      >
        <Modal.Header>
          <Modal.Title className=" d-flex justify-content-between w-100">
            <div>{this.props.t("Settings.Levels.Del")}</div>
            <div className="botonCerrar">
              <button
                className="btn"
                onClick={() => {
                  this.setState({ panelDeleteUserIsOpen: false });
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.props.t("Settings.Levels.MessageDel")} <br />
            <br />
            {this.props.t("Settings.Levels.Name")}:{" "}
            {this.props.upsertLevel.name}
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.setState({ panelDeleteUserIsOpen: false });
            }}
          >
            {this.props.t("AdminUser.Botones.Cancelar")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.setState({ panelDeleteUserIsOpen: false });
              this.props.deleteLevels(
                this.props.upsertLevel.id,
                this.props.t("ToastrMessages.Settings.DeleteLevel")
              );
            }}
          >
            {this.props.t("Settings.Levels.Del")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  private checkObligatoryInputs() {
    let valid = true;
    valid = valid
      ? this.props.upsertLevel?.name
        ? this.props.upsertLevel.name !== ""
        : false
      : false;
    valid = valid
      ? this.props.upsertLevel?.color
        ? this.props.upsertLevel.color !== ""
        : false
      : false;
    valid = valid
      ? this.props.upsertLevel?.scoreInit
        ? this.props.upsertLevel.scoreInit >= 0
        : false
      : false;
    valid = valid
      ? this.props.upsertLevel?.scoreEnd
        ? this.props.upsertLevel.scoreEnd >= 0
        : false
      : false;
    valid = valid
      ? this.props.upsertLevel?.scoreInit < this.props.upsertLevel.scoreEnd
      : false;

    let exist = true;
    this.props.levels.forEach((l) => {
      if (
        l.scoreInit < this.props.upsertLevel?.scoreInit &&
        l.scoreEnd > this.props.upsertLevel?.scoreInit
      ) {
        if (l.id !== this.props.upsertLevel?.id) {
          exist = false;
        }
      }
      if (exist && l.color === this.props.upsertLevel?.color) {
        if (l.id !== this.props.upsertLevel?.id) {
          exist = false;
        }
      }
    });
    valid = valid ? exist : false;
    return !valid;
  }

  private readonly handleNewQuestion = () => {
    if (this.state.panelNewLevel) {
      this.props.addLevels(this.props.t("ToastrMessages.Settings.AddLevel"));
    } else {
      this.props.updateLevels(
        this.props.t("ToastrMessages.Settings.UpdateLevel")
      );
    }
    this.setState({
      panelEditLevel: false,
      panelNewLevel: false,
    });
  };

  private readonly onInputChange = (event) => {
    const payload = this.getGenericPayloadFromEvent(event);
    this.props.updateLevelProperty(payload);
  };

  private readonly getGenericPayloadFromEvent = (
    event: any
  ): GenericPayload => {
    if (event.target) {
      return this.getGenericPayload(event.target.id, event.target.value);
    } else {
      return null;
    }
  };

  private readonly getGenericPayload = (
    name: string,
    value: any
  ): GenericPayload => {
    return {
      name: name,
      value:
        value !== ""
          ? Number.isNaN(Number(value))
            ? value
            : Number(value)
          : "",
    };
  };

  public render() {
    const levels = this.props.levels;
    if (levels != null && levels.length > 0) {
      levels.sort((a, b) => {
        return a.number > b.number ? 1 : -1;
      });
    }

    return (
      <div>
        {this.isLoading ? (
          <LoadingComponent className="loading-center-in-component" /> 
        ) : levels != null ? (
          <div className="panelAdminQuestion">
            {this.renderLevelModal()}
            {this.renderDeleteLevelModal()}
            <div className="row">
              <div className="col-7 mb-3">
                <button
                  className="btn btn-primary"
                  onClick={this.changeShowNewLevelModal}
                >
                  <i className="fas fa-plus mr-2"></i>
                  {this.props.t("Settings.Levels.Add")}
                </button>
              </div>
            </div>
            {levels.length > 0 ? (
              <div className="row">
                <table className="table questionTable">
                <tbody>
                  <tr className="row pl-10" >
                    <th className="col-4">
                      <label className="text-secondary">
                        {this.props.t("Settings.Levels.Name")}
                      </label>
                    </th>
                    <th className="col-2">
                      <label className="text-secondary">
                        {this.props.t("Settings.Levels.Color")}
                      </label>
                    </th>
                    <th className="col-2">
                      <label className="text-secondary">
                        {this.props.t("Settings.Levels.InitValue")}
                      </label>
                    </th>
                    <th className="col-2">
                      <label className="text-secondary">
                        {this.props.t("Settings.Levels.EndValue")}
                      </label>
                    </th>
                    <th className="col-2">
                      <label className="text-secondary">
                        {this.props.t("AdminQuestion.Actions")}
                      </label>
                    </th>
                  </tr>

                  {this.renderAllLevels(
                    this.filterPages(levels),
                    this.props.t,
                    this.changeShowEditLevelModal,
                    this.showDelLevelModal
                  )}
                  </tbody>
                </table>
              </div>
            ) : (
              <MessagePanel
                type={MessageAlertType.Info}
                title=""
                message={this.props.t("AdminPanel.NoLevels")}
              />
            )}
            {this.state.totalPages > 1 ? (
              <PaginationComponent
                totalPages={this.state.totalPages}
                currentPage={this.state.page}
                itemRange={4}
                initPage={this.initPage}
                prevPage={this.prevPage}
                paginate={this.paginate}
                nextPage={this.nextPage}
                lastPage={this.lastPage}
              />
            ) : null}
          </div>
        ) : (
          <MessagePanel
            type={MessageAlertType.Warning}
            title={this.props.t("AdminPanel.AccesoDen")}
            message={this.props.t("AdminPanel.AccesoDenMjs")}
          />
        )}
      </div>
    );
  }

  private readonly changeShowNewLevelModal = () => {
    this.props.clearUpsertLevel();
    this.setState({
      panelNewLevel: true,
      panelEditLevel: false,
    });
  };

  private readonly hidenShowLevelModal = () => {
    this.props.clearUpsertLevel();
    this.setState({
      panelNewLevel: false,
      panelEditLevel: false,
    });
  };

  private readonly changeShowEditLevelModal = (level: LevelSettings) => {
    this.props.loadLevelPropertys(level);

    this.setState({
      panelEditLevel: true,
      panelNewLevel: false,
    });
  };

  private readonly showDelLevelModal = (level: LevelSettings) => {
    this.props.loadLevelPropertys(level);

    this.setState({
      panelEditLevel: false,
      panelNewLevel: false,
      panelDeleteUserIsOpen: true,
    });
  };

  private readonly initPage = () => {
    this.setState({ page: 1 });
  };
  private readonly prevPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage - 1 });
  };
  private readonly paginate = (page: number) => {
    this.setState({ page: page });
  };
  private readonly nextPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage + 1 });
  };
  private readonly lastPage = () => {
    this.setState({ page: this.state.totalPages });
  };

  private readonly filterPages = (levels: LevelSettings[]) => {
    if (levels != null && levels.length > 0) {
      if (
        Math.ceil(levels.length / 10) !== this.state.totalPages &&
        levels.length > 0
      ) {
        this.setState({
          totalPages: Math.ceil(levels.length / 10),
        });
      }

      return levels.slice(this.state.page * 10 - 10, this.state.page * 10);
    } else {
      return levels;
    }
  };

  private readonly renderAllLevels = (
    levels: LevelSettings[],
    t: any,
    showEdidLevelModal,
    showDelLevelModal
  ) => {
    return levels.map((q, index) => (
      <tr className="row" key={index}>
        <th className="col-4">
          <p className="pl-2 questionText">{q.name}</p>
        </th>
        <th className="col-2 d-flex align-items-center">
          <span className="pl-2">{q.color}</span>
        </th>
        <th className="col-2 d-flex align-items-center">
          <span className="pl-2">{q.scoreInit}</span>
        </th>
        <th className="col-2 d-flex align-items-center">
          <span className="pl-2">{q.scoreEnd}</span>
        </th>
        <th className="col-2 d-flex justify-content-around">
          {q.id !== "" ? (
            <button
              className="btn"
              title={t("AdminQuestion.Botones.Titulos.Editar")}
              onClick={() => {
                showEdidLevelModal(q);
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
          ) : (
            <button
              className="btn disabled"
              title={t("AdminQuestion.Botones.Titulos.Editar")}
            >
              <i className="fas fa-edit"></i>
            </button>
          )}
          {q.id !== "" ? (
            <button
              className="btn"
              title={t("AdminQuestion.Botones.Titulos.Borrar")}
              onClick={() => {
                showDelLevelModal(q);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          ) : (
            <button
              className="btn disabled"
              title={t("AdminQuestion.Botones.Titulos.NoBorrar")}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          )}
        </th>
      </tr>
    ));
  };
}

export const AdminLevels = translate("common")(AdminLevelsPage);
