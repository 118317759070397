import { levelsApi } from "../../api/levelsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import { getLevelsAction } from './getLevels';
import { store } from '../../store';
import * as toastr from 'toastr';

export const addLevelAction = (successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    dispatch(levelApiCallStart());

    const upsertLevel = store.getState().levels.upsertLevel;
    levelsApi.AddLevel(upsertLevel).then((result) => {
        dispatch(getLevelsAction());
        dispatch(httpCallEndAction());
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });
}

const levelApiCallStart = () => ({
    type: actionsDefinition.levels.LEVELS_HTTP_CALL_STARTED
});
