import * as React from "react";
import fbIcon from '../../../../img/facebook-icon.svg';
import twIcon from '../../../../img/twitter-icon.svg';
import teamsIcon from '../../../../img/teams-icon.svg';
import yammerIcon from '../../../../img/yammer-icon.svg';
import lnIcon from '../../../../img/linkedin-icon.svg';
import { utils } from '../../../../common/functions/utils';
import Settings from '../../../../models/settings';

interface ISocialNetworkProps {
    settings: Settings,
    urlBlobSocialNetwork: string
}
export const SocialNetwork = (props: ISocialNetworkProps) => {
    const { settings, urlBlobSocialNetwork } = props;
    const height = 600;
    const width = 700;
    const shareInSocialNetwork = (socialNetwork: string) => {
        switch (socialNetwork) {
            case "twitter":
                window.open(
                    `https://twitter.com/intent/tweet?url=${urlBlobSocialNetwork}`,
                    "share-popup",
                    `width=${width},height=${height}`
                );
                break;
            case "facebook":
                window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${urlBlobSocialNetwork}`,
                    "share-popup",
                    `width=${width},height=${height}`
                );
                break;
            case "linkedin":
                window.open(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${urlBlobSocialNetwork}`,
                    "share-popup",
                    `width=${width},height=${height}`
                );
                break;
            case "teams":
                window.open(
                    `https://teams.microsoft.com/share?href=${urlBlobSocialNetwork}`,
                    "share-popup",
                    `width=${width},height=${height}`
                );
                break;
            case "yammer":
                window.open(
                    `https://www.yammer.com/messages/new?login=true&trk_event=yammer_share&status=Look at my score on PlayQuiz: ${urlBlobSocialNetwork}&group_id=null`,
                    "share-popup",
                    `width=${width},height=${height}`
                );
                break;
        }
    }
    return <div className="shareRRSS">
        {settings && !utils.isDemo() ? (
            <div>
                {settings.socialNetworkSettings.facebook ? (
                    <img
                        className="mr-3 iconRRSS"
                        src={fbIcon}
                        alt="Facebook"
                        onClick={() => shareInSocialNetwork("facebook")}
                    />
                ) : null}
                {settings.socialNetworkSettings.teams ? (
                    <img
                        className="mr-3 iconRRSS"
                        src={teamsIcon}
                        alt="Teams"
                        onClick={() => shareInSocialNetwork("teams")}
                    />
                ) : null}
                {settings.socialNetworkSettings.twitter ? (
                    <img
                        className="mr-3 iconRRSS"
                        src={twIcon}
                        alt="Twitter"
                        onClick={() => shareInSocialNetwork("twitter")}
                    />
                ) : null}
                {settings.socialNetworkSettings.linkedin ? (
                    <img
                        className="mr-3 iconRRSS"
                        src={lnIcon}
                        alt="Linkedin"
                        onClick={() => shareInSocialNetwork("linkedin")}
                    />
                ) : null}
                {settings.socialNetworkSettings.yammer ? (
                    <img
                        className="mr-3 iconRRSS"
                        src={yammerIcon}
                        alt="Yammer"
                        onClick={() => shareInSocialNetwork("yammer")}
                    />
                ) : null}
            </div>
        ) : null}
    </div>
}