import React from 'react';
import { ProviderState, Providers, SimpleProvider } from '@microsoft/mgt';
import * as microsoftTeams from "@microsoft/teams-js";
import { initializeIcons } from '@uifabric/icons';
import 'bootstrap/dist/css/bootstrap.css';
import { AuthenticationParameters } from 'msal';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import '../node_modules/@webcomponents/webcomponentsjs/webcomponents-bundle.js';
import TeamsFunctions from './common/functions/teamsFunctions';
import { msalInstance } from './common/msal/msal';
import "./css/colors.css";
import "./css/fonts.css";
import './css/toastr.min.css';
import './font-awesome/css/all.css';
import history from './history/history';
import './index.css';
import { routes } from './routes';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import i18next from 'i18next';

microsoftTeams.initialize();

Providers.globalProvider = new SimpleProvider(async (scopes) => {

	if (!TeamsFunctions.IsInTeams()) {
		const request: AuthenticationParameters = {
		};
		request.scopes = scopes;
		return msalInstance.acquireTokenSilent(request).then((response) => {
			return response.accessToken;
		});
	}
	else {

		var token = localStorage.getItem("token");

		var adal_44 = localStorage.getItem("adal_44");

		return token == null || token == undefined ? adal_44 : token
	}
});



const renderApp = () => {

	ReactDOM.render(
		<AppContainer>
			<I18nextProvider i18n={i18next}>
				<Provider store={store}>
					<Router children={routes} history={history} />
				</Provider>
			</I18nextProvider>
		</AppContainer>,
		document.getElementById('root')
	);
}


Providers.globalProvider.setState(ProviderState.Loading);
initializeIcons();
initializeIcons(undefined, { disableWarnings: true });

renderApp();
if (navigator.userAgent.indexOf("Firefox") > 0) {
	serviceWorker.register();
}
else {
	serviceWorker.unregister();
}

