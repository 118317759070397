import React from "react";
import  "./titles.css";

interface ITitleProps {
   title: string;
}

export const TitleH1 = (props: ITitleProps) => {
   return <>
      <h1 className={"titleH1"}>{props.title}</h1>
   </>
}

export const TitleH2 = (props: ITitleProps) => {
      return <>
      <h2 className={"titleH2"}>{props.title}</h2>
   </>
}

