import * as React from "react";
import { LoadingComponent } from "../../common/components/Loading/loading";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { useState, useEffect, ReactNode } from "react";
import User from "../../models/user";
import { MessageAlertType } from "../../common/constants/enum";
import { TabComponent } from "./tabComponent";
import { CheckIfAdmin } from "../../api/userApi";

export interface IAdminPanelComponentProps {
  isLoading: boolean;
  loadingText: string;
  title: string;
  children: ReactNode;
  user: User;
  messagePanelTitle: string;
  messagePanelMessage: string;
  parent: any;
  lstTabs: any;
}

export const AdminPanelComponent = function (props: IAdminPanelComponentProps) {
  const [isAdmin, setIfAdmin] = useState(null);

  useEffect(() => {
    if (isAdmin == null) {
      CheckIfAdmin(setIfAdmin);
    }
  }, [isAdmin]);

  return (
    <>
      {props.isLoading || isAdmin == null ? (
        <LoadingComponent
          className={"loading-container shadow"}
          text={props.loadingText}
        />
      ) : isAdmin ? (
        <div className="admin-panel-container w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="admin-panel shadow">
            <div className="admin-panel-header mb-4">
              <h2>{props.title}</h2>
            </div>
            <div className="admin-panel-Menu">
              <ul className="nav nav-tabs">
                {props.lstTabs.map((tab, index) =>
                  (tab.pageName !== "Levels" &&
                    tab.pageName !== "Banner" &&
                    tab.pageName !== "Topic") ||
                  ((tab.pageName === "Levels" || tab.pageName === "Banner") &&
                    (props.user.TenantId ===
                      process.env.REACT_APP_TENANT_ID_1 ||
                      props.user.TenantId ===
                        process.env.REACT_APP_TENANT_ID_2)) ||
                  (tab.pageName === "Topic" &&
                    (props.user.TenantId ===
                      process.env.REACT_APP_TENANT_ID_1 ||
                      props.user.TenantId ===
                        process.env.REACT_APP_TENANT_ID_2)) ? (
                    <TabComponent
                      key={index}
                      parent={props.parent}
                      className={props.parent.state[tab.active] ? "active" : ""}
                      page={tab.pageName}
                      icon={tab.icon}
                      text={tab.title}
                      child={tab.child}
                      claseActiva={props[tab.active]}
                    />
                  ) : null
                )}
              </ul>
            </div>
            <div className="admin-panel-Body">{props.children}</div>
          </div>
        </div>
      ) : (
        <MessagePanel
          type={MessageAlertType.Warning}
          title={props.messagePanelTitle}
          message={props.messagePanelMessage}
        />
      )}
    </>
  );
};
