import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { SendChallenge } from './sendChallenge';
import { sendChallengeAction } from '../../actions/Home';
import SendChallengeModel from '../../models/sendChallenge';

const mapStateToProps = (state: IStateReducer) => ({
    challenge: state.sendChallenge
});

const mapDispatchToProps = (dispatch: any) => ({
    sendChallenge: (sendChallenge: SendChallengeModel) => dispatch(sendChallengeAction(sendChallenge))
});

export const SendChallengeContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(SendChallenge);
