import { actionsDefinition } from '../actions/actionsDefinition';

export const authReducer = (state: string = '', action: any) => {
    switch (action.type) {
        case actionsDefinition.GET_TOKEN:
            return handleTokenCompleted(state, action.payload);
    }
    return state;
};

const handleTokenCompleted = (state: string, payload: string) => {
    return payload;
};