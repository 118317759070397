import * as React from "react";
import { RouteComponentProps } from "react-router";
import SendChallengeModel, {
  InitSendChallenge,
} from "../../models/sendChallenge";
import Modal from "react-bootstrap/Modal";
import { translate, TransProps, I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import "./css/Challenge.css";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsights } from "../../common/telemetry/appInsights";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import User from "../../models/user";
import { concatStyleSetsWithProps } from "office-ui-fabric-react";

declare global {
  /* eslint-disable-next-line */
  namespace JSX {
    interface IntrinsicElements {
      "mgt-login": any;
      "mgt-person": any;
      "mgt-msal-provider": any;
      "mgt-tasks": any;
      "mgt-people-picker": any;
    }
  }
}

export interface ISendChallengeProps extends TransProps {
  sendChallenge: (sendChallenge: SendChallengeModel) => Promise<boolean>;
  hideSendChallenge: (isCloseButton: boolean) => void;
  t: any;
  isFirstConnection: () => boolean;
  user: User;
}

export interface ISendChallengeState {
  sendChallengeNew: SendChallengeModel;
  t: any;
  users: { Mail: string; Name: string }[];
  showInfoCurrentUser: boolean;
  usersInput: string[];
  errorInfo: string;
}

class SendChallengePage extends React.Component<
  RouteComponentProps<{}> & ISendChallengeProps,
  ISendChallengeState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      sendChallengeNew: InitSendChallenge(),
      t: I18nextProvider,
      users: [],
      showInfoCurrentUser: false,
      usersInput: [],
      errorInfo: "",
    };
    this.emailsChallenge_Change = this.emailsChallenge_Change.bind(this);
  }

  private showInfoCurrentUser(show: boolean) {
    this.setState({
      showInfoCurrentUser: show,
    });
  }

  onChangeUserInput = (e, index) => {
    const value = e.target.value;

    let newUsersInput = [...this.state.usersInput];
    newUsersInput[index] = value;
    this.setState({
      usersInput: newUsersInput,
    });
  };

  public render() {
    const numEmails = 5;
    let emailsPanel = [];

    for (let i = 0; i < numEmails; i++) {
      emailsPanel.push(
        <div className=" divInputUser" key={i}>
          <input
            className="inputUser"
            onChange={(e) => this.onChangeUserInput(e, i)}
            placeholder={this.props.t("SendChallenge.Placeholder")}
          ></input>
        </div>
      );
    }

    return (
      <div className="contenedor oculto">
        <Modal
          show={this.state.showInfoCurrentUser}
          onHide={() => {
            this.showInfoCurrentUser(false);
          }}
        >
          <Modal.Header>
            <Modal.Title className=" d-flex justify-content-between w-100">
              <div>{this.props.t("SendChallenge.TituloInfo")}</div>
              <div className="botonCerrar">
                <button
                  className="btn"
                  onClick={() => {
                    this.showInfoCurrentUser(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{this.props.t("SendChallenge.InfoCurrentUser")}</div>
          </Modal.Body>
        </Modal>
        <Modal show={!this.state.showInfoCurrentUser} onHide={() => { }}>
          <Modal.Body>
            <div className="botonCerrar">
              <button
                className="btn"
                onClick={() => this.props.hideSendChallenge(true)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="popUpImage">
              <div className="d-flex flex-column text-center">
                <span className="QueesPlayQuiz text-left">
                  {this.props.t("SendChallenge.Titulo")}
                </span>
              </div>
            </div>
            <div>
              <hr />
              <div className="popUpButtons">
                <div>
                  <p>{this.props.t("SendChallenge.Invitar")}</p>
                  <p>{this.props.t("SendChallenge.Invitar2")}</p>
                </div>
              </div>
              {this.state.errorInfo.length > 0 ? (
                <div className="errorZone">
                  <p>{this.state.errorInfo}</p>
                </div>
              ) : (
                <></>
              )}

              <div>
                <div className="peoplePicker">
                  <div className="row d-flex justify-content-between align-items-end">
                    {emailsPanel}
                  </div>
                  <div>
                    <div className="d-flex justify-content-between gris">
                      {this.state.users.length !== 0
                        ? this.props.t("SendChallenge.UsrSeleccionados") +
                        "(" +
                        this.state.users.length +
                        ")"
                        : null}
                      {this.state.users.length !== 0 ? (
                        <p onClick={(e) => this.setState({ users: [] })}>
                          {this.props.t("SendChallenge.Botones.BorrarTodos")}
                        </p>
                      ) : null}
                    </div>
                    {this.state.users.map((user, index) => (
                      <div className="row" key={index}>
                        <div className="col-10">
                          <mgt-person
                            person-query={user.Mail}
                            show-name
                            show-email
                          ></mgt-person>
                        </div>
                        <div className="col-2">
                          <button
                            title={this.props.t("SendChallenge.Botones.Borrar")}
                            className="btn"
                            onClick={(e) => this.deleteUser(user)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  {!this.props.isFirstConnection ? (
                    <p
                      onClick={() => this.props.hideSendChallenge(false)}
                      className="aSinDec"
                    >
                      {this.props.t("SendChallenge.Botones.Saltar")}
                    </p>
                  ) : (
                    <div></div>
                  )}

                  {this.state.usersInput.some((x) => x) ? (
                    <button
                      title={this.props.t(
                        "SendChallenge.Botones.Usuarios.Titulo"
                      )}
                      onClick={(e) => this.emailsChallenge_Change(e)}
                      className="btn btn-danger"
                    >
                      {this.props.t("SendChallenge.Botones.Usuarios.Add")}
                    </button>
                  ) : (
                    <button
                      disabled
                      title={this.props.t(
                        "SendChallenge.Botones.Usuarios.Titulo"
                      )}
                      onClick={(e) => this.emailsChallenge_Change(e)}
                      className="btn btn-secondary btn-lg"
                    >
                      {this.props.t("SendChallenge.Botones.Usuarios.Add")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
  private addUsers(e: any) {
    var peoplePicker: any = document.querySelector("mgt-people-picker");
    var users = this.state.users;
    peoplePicker.selectedPeople.forEach((people) => {
      if (
        !JSON.stringify(users).includes(
          JSON.stringify({
            Mail: people.scoredEmailAddresses[0].address,
            Name: people.displayName,
          })
        )
      ) {
        if (this.props.user.Email === people.scoredEmailAddresses[0].address) {
          this.showInfoCurrentUser(true);
        } else {
          users.push({
            Mail: people.scoredEmailAddresses[0].address,
            Name: people.displayName,
          });
        }
      }
    });
    peoplePicker.selectedPeople = [];
    this.setState({
      users: users,
    });
  }

  private deleteUser(user: { Mail: string; Name: string }) {
    let datos = this.state.users;
    for (let i = 0; i < datos.length; i++) {
      if (datos[i].Mail === user.Mail) datos.splice(i, 1);
    }
    this.setState({
      users: datos,
    });
  }
  private emailsChallenge_Change(e: any) {
    let isValid = true;
    let newSendChallenge: SendChallengeModel = InitSendChallenge();
    const mappedUsers = [];
    this.setState({ errorInfo: "" });
    this.state.usersInput.forEach((user) => {
      if (this.IsValidEmail(user)) {
        if (user) {
          mappedUsers.push({
            Mail: user,
            Name: user.substring(0, user.indexOf("@")),
          });
        }
      } else {
        isValid = false;
        this.setState({
          errorInfo: this.props.t("SendChallenge.EmailError"),
        });
      }
    });
    newSendChallenge.EmailsChallenge = mappedUsers;
    newSendChallenge.Language = i18n.language;

    if (isValid) {

      let newAppInsights = appInsights;
      this.props.sendChallenge(newSendChallenge);
      newAppInsights.trackEvent({
        name: "Invitar usuarios",
        properties: {
          user: this.props.user.Name,
          tenant: this.props.user.TenantId,
          users: this.state.usersInput,
        },
      });

      this.props.hideSendChallenge(false);
    }
  }
  IsValidEmail(value): boolean {
    if (value == undefined || value == null) {
      return true;
    }
    if (
      (value.indexOf("@") > 0 &&
        value.indexOf(".") > 0 &&
        value.indexOf(" ") < 0) ||
      value.length == 0
    ) {
      return true;
    }
    return false;
  }
}

export const SendChallenge = withAITracking(
  new ReactPlugin(),
  translate("common")(SendChallengePage)
);
