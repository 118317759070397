import * as React from "react";
import { Toggle } from "@fluentui/react";
import Settings, { createNewSettingsFromOther } from "../../../../models/settings";

interface CategoryItemProps {
    settings: Settings;
    updateSettings: (settings: Settings, successMessage: string) => void;
    settingsCallCompleted: boolean;
    t: (key: string) => string;
    categoryItem: CategoryItem;
}

interface CategoryItem {
    name: string;
    key: string | number;
    active?: boolean;
}
export const CategoryItem = (props: CategoryItemProps) => {


    const onChangeCategoryToggle = (
        ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
        checked?: boolean
    ) => {
        let id = ev.currentTarget.id;

        let newSettings = createNewSettingsFromOther(props.settings);

        if (!newSettings.categoriesDisabled) {
            newSettings.categoriesDisabled = [] as string[];
        }
        const successMessage = props.t(
            "ToastrMessages.Settings.UpdateSettings"
        );
        id = id.split("#-#")[1];

        if (id) {
            if (checked) {
                if (newSettings.categoriesDisabled?.find((e) => e == id)) {
                    newSettings.categoriesDisabled = newSettings.categoriesDisabled.map(
                        (e) => {
                            if (e != id) {
                                return e;
                            }
                        }
                    );
                }
            } else {
                if (!newSettings.categoriesDisabled?.find((e) => e == id)) {
                    newSettings.categoriesDisabled.push(id);
                }
            }
        }

        newSettings.categoriesDisabled = newSettings.categoriesDisabled?.filter(
            (e) => e
        );
        props.updateSettings(newSettings, successMessage);
    }

    return <div
        className="categoriesItem col-6 col-md-3 col-sm-4"
    >
        <div>
            <Toggle
                checked={props.categoryItem.active}
                id={`category#-#${props.categoryItem.key}`}
                onChange={onChangeCategoryToggle}
                disabled={props.settingsCallCompleted}
            />
        </div>
        <div>{props.categoryItem.name}</div>
    </div>
}