import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { TransProps, translate } from "react-i18next";
import { TitleH2, TitleH1 } from "../Titles/titles";

export interface INoquestionProps extends TransProps {
  t: any;
}

class NoQuestion extends React.Component<INoquestionProps> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div className="row empezarReto">
        <div className="noQuestions">
          <TitleH1 title={this.props.t("Home.DailyQuestionEmptyTitle")} />
        
          <div>{this.props.t("Home.DailyQuestionEmptyText1")}</div>
          <div>{this.props.t("Home.DailyQuestionEmptyText2")}</div>
          <div>{this.props.t("Home.DailyQuestionEmptyText3")}</div>
          <TitleH2 title={this.props.t("Home.DailyQuestionEmptyTextDeserve")} />
        </div>
      </div>
    );
  }
}

export const NoQuestionComponent = withAITracking(
  new ReactPlugin(),
  translate("common")(NoQuestion)
);
