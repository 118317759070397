import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import domtoimage from "dom-to-image";
import * as React from "react";
import "react-circular-progressbar/dist/styles.css";
import { translate, TransProps } from "react-i18next";
import { GetUserLevel } from "../../../common/functions/utils";
import LevelSettings from "../../../models/levelSettings";
import Settings from "../../../models/settings";
import User from "../../../models/user";
import { ProfileCard } from "./profileCard/profileCard";
import { SocialNetwork } from "./socialNetworks/socialNetWorks";

const height = 600;
const width = 700;

export interface UserProfileProps extends TransProps {
  user: User;
  urlBlobSocialNetwork: string;
  settings: Settings;
  t: any;
  levels: LevelSettings[];
  employee?: any;

  getUrlBlobSocialNetWork: (b64Data: string) => Promise<string>;
}

export interface UserProfileState {
  rrss: string;
}

class UserProfilePage extends React.Component<
  UserProfileProps,
  UserProfileState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      rrss: "",
    };

    this.shareInSocialNetwork = this.shareInSocialNetwork.bind(this);
    this.getBlobSocialNetwork = this.getBlobSocialNetwork.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.getBlobSocialNetwork();
    }, 2500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.urlBlobSocialNetwork !== "" && this.state.rrss !== "") {

      this.setState({
        rrss: "",
      });
    }

    if (this.props.user.TotalPoints !== prevProps.user.TotalPoints) {
      this.getBlobSocialNetwork();
    }
  }

  private shareInSocialNetwork(rrss: string) {
    this.setState({
      rrss: rrss,
    });
  }

  private async getBlobSocialNetwork() {
    var node = document.getElementById("capture");
    if (node != null && node != undefined) {
      var scale = 2;
      var base64Data = await domtoimage.toPng(node, {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: node.offsetWidth + "px",
          height: node.offsetHeight + "px",
        },
      });
      await this.props.getUrlBlobSocialNetWork(base64Data);
    }
  }

  public fecha() {
    if (this.props.user.UserConnection.LastParticipation != null) {
      var date = new Date(this.props.user.UserConnection.LastParticipation);
      return date.getUTCDay();
    }
  }

  public getTodayParticipationClass(
    participation: boolean,
    numberOfDay: number,
    classNames: string[]
  ) {
    let result: string;
    const haveNotAnsweredToday =
      numberOfDay >= new Date().getDay() && !participation;

    if (participation) {
      result = classNames[0];
    } else if (haveNotAnsweredToday) {
      result = classNames[1];
    } else {
      result = classNames[2];
    }
    return result;
  }


  public initials() {
    return this.props.user.Name
      ? this.props.user.Name.split("")[0] +
      this.props.user.Name.split("")[this.props.user.Name.indexOf(" ") + 1]
      : "";
  }

  public render() {
    const userLevel: LevelSettings = GetUserLevel(this.props.levels, this.props.user.TotalPoints);

    return (
      <div className="container-profile-card">

        <SocialNetwork settings={this.props.settings} urlBlobSocialNetwork={this.props.urlBlobSocialNetwork} />
        <ProfileCard user={this.props.user} userLevel={userLevel} t={this.props.t} />
      </div>
    );
  }
}
export const UserProfile = withAITracking(
  new ReactPlugin(),
  translate("common")(UserProfilePage),
  "UserProfilePage",
  "perfil container"
);
