import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../../common/components/button/button";
import { Select } from "../../../common/components/select/select";
import { Input } from "../../../common/components/input/input";
import "../css/style.css";
import { TagPicker, ITag } from "@fluentui/react";
import { LIST_LANGUAGES } from "../../../i18n";

export interface IModalProps {
  show: boolean;
  header: IModalHeaderProps;
  body: IModalBodyProps;
  footer: IModalFooterProps;
  question: any;
}

export interface IModalHeaderProps {
  title: string;
  onClick: any;
}

export interface IModalBodyProps {
  langTitle: string;
  changeEventLang: any;
  changeEventDiff: any;
  difficultyTitle: string;
  lstDifficulty: any;
  categoryTitle: string;
  tagPickerConfig: any;
  questionText: string;
  questionTitle: string;
  changeventQuestion: any;
  plhdQuestion: string;

  questionOp1: string;
  changeventQuestionOp1: any;

  questionOp2: string;
  changeventQuestionOp2: any;

  questionOp3: string;
  changeventQuestionOp3: any;

  correctAnswerText: string;
  correctAnswer: string;

  changeEventCorrectAnswer: any;
  lstCorrectAnswer: any;

  descriptionText: any;
  description: string;
  plhdDescrition: string;

  changeventDescription: any;

  topicText: string;
  placeholderTopic: string;
  changeventTopic: any;
  topics: any;
}

export interface IModalFooterProps {
  onClick: any;
  textAccept: string;
  textCancel: string;
}

export const QuestionFormModal = function (props: IModalProps) {
  const langOptions = LIST_LANGUAGES.map((lang) => {
    return {
      key: lang,
      text: lang.toLocaleUpperCase()
    };
  });
  return (
    <>
      <Modal show={props.show} onHide={() => props.header.onClick(false)}>
        <Modal.Header>
          <Modal.Title className=" d-flex justify-content-between w-100">
            <div>{props.header.title}</div>
            <div className="botonCerrar">
              <Button
                clickEvent={() => props.header.onClick(false)}
                text=""
                icon="fa-times"
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row form-group">
              <div className="col-6">
                <label className="mx-2">{props.body.langTitle}</label>
                <Select
                  selectedValue={
                    props.question != undefined
                      ? props.question.Locale
                      : LIST_LANGUAGES[0].toLocaleUpperCase()
                  }
                  className={"custom-select"}
                  changeEvent={(c) => props.body.changeEventLang(c)}
                  lstOptions={langOptions}
                />
              </div>
              <div className="col-6">
                <label className="mx-2">{props.body.difficultyTitle}</label>
                <Select
                  selectedValue={
                    props.question != null ? props.question.Difficulty : ""
                  }
                  className={"custom-select"}
                  changeEvent={(c) => props.body.changeEventDiff(c)}
                  lstOptions={props.body.lstDifficulty}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mx-2">{props.body.categoryTitle}</label>
              <TagPicker
                onInputChange={props.body.tagPickerConfig.onInputChange}
                onResolveSuggestions={
                  props.body.tagPickerConfig.onResolveSuggestions
                }
                getTextFromItem={props.body.tagPickerConfig.getTextFromItem}
                className="categoryPicker"
                onItemSelected={
                  props.body.tagPickerConfig.onItemSelected
                }
                onBlur={props.body.tagPickerConfig.onBlur}
                onChange={props.body.tagPickerConfig.onChange}
                pickerSuggestionsProps={
                  props.body.tagPickerConfig.pickerSuggestionsProps
                }
                itemLimit={props.body.tagPickerConfig.itemLimit}
                defaultSelectedItems={
                  props.question.Category.length > 0
                    ? [
                      {
                        key: props.question?.Category,
                        name: props.question?.Category,
                      },
                    ]
                    : null
                }
              />
              <label className="mx-2">{props.body.questionText}</label>
              <Input
                value={
                  props.question == null
                    ? props.body.questionTitle
                    : props.question.Title
                }
                type="text"
                changeEvent={(c) => props.body.changeventQuestion(c)}
                className="form-control"
                placeholder={props.body.plhdQuestion}
              />

              <label className="mx-2">{props.body.questionOp1}</label>

              <Input
                value={
                  props.question == null ? "" : props.question.Answers[0].Value
                }
                type="text"
                changeEvent={(c) => props.body.changeventQuestionOp1(c)}
                className="form-control"
              />
              <label className="mx-2">{props.body.questionOp2}</label>

              <Input
                value={
                  props.question == null ? "" : props.question.Answers[1].Value
                }
                type="text"
                changeEvent={(c) => props.body.changeventQuestionOp2(c)}
                className="form-control"
              />

              <label className="mx-2">{props.body.questionOp3}</label>
              <Input
                value={
                  props.question == null ? "" : props.question.Answers[2].Value
                }
                type="text"
                changeEvent={(c) => props.body.changeventQuestionOp3(c)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="mx-2">{props.body.correctAnswerText}</label>

              <Select
                selectedValue={props.body.correctAnswer}
                className={"custom-select"}
                changeEvent={(c) => props.body.changeEventCorrectAnswer(c)}
                lstOptions={props.body.lstCorrectAnswer}
              />
            </div>
            <div className="form-group">
              <label className="mx-2">{props.body.descriptionText}</label>

              <Input
                value={props.body.description}
                type="text"
                changeEvent={(c) => props.body.changeventDescription(c)}
                className="form-control"
                placeholder={props.body.plhdDescrition}
              />
            </div>
            <div className="form-group">
              <label className="mx-2">{props.body.topicText}</label>

              <Select
                selectedValue={
                  props.question != undefined && props.question.Topic !== null
                    ? props.question.Topic
                    : props.body.topics[0].value
                }
                placeholder={props.body.placeholderTopic}
                className={"custom-select"}
                changeEvent={(c) => props.body.changeventTopic(c)}
                lstOptions={props.body.topics}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-secondary"
            clickEvent={() => props.footer.onClick(false)}
            text={props.footer.textCancel}
          />
          <Button
            className="btn-primary"
            clickEvent={() => props.footer.onClick(true)}
            text={props.footer.textAccept}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
