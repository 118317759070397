import ExtraordinaryQuestionsSettings, { createNewExtraordinaryQuestionsSettings } from "./extraordinaryQuestionsSettings"
import SocialNetworkSettings, { createNewSocialNetworkSettings } from "./socialNetworkSettings"
import MailingSettings, { createNewMailingSettings } from "./mailingSettings"
import PenaltySettings, { createNewPenaltySettings } from "./penaltySettings"

export default interface Settings {
    id: string;
    tenantId: string;
    extraordinaryQuestionsSettings: ExtraordinaryQuestionsSettings;
    showGlobalQuestions: boolean;
    socialNetworkSettings: SocialNetworkSettings;
    mailingSettings: MailingSettings;
    penaltySettings: PenaltySettings;
    isBannerActive: boolean;
    categoriesDisabled: string[];
    disableInvitation: boolean;
    customization: Customization;
    topic: string;
}

export const createNewSettings = (): Settings => {
    return {
        id: "",
        tenantId: "",
        extraordinaryQuestionsSettings: createNewExtraordinaryQuestionsSettings(),
        showGlobalQuestions: true,
        mailingSettings: createNewMailingSettings(),
        socialNetworkSettings: createNewSocialNetworkSettings(),
        penaltySettings: createNewPenaltySettings(),
        isBannerActive: false,
        categoriesDisabled: [] as string[],
        disableInvitation: false,
        customization: CreateEmptyCustomization(),
        topic: ""
    }
}

export const createNewSettingsFromOther = (settings: Settings): Settings => {
    return {
        id: settings.id,
        tenantId: settings.tenantId,
        extraordinaryQuestionsSettings: settings.extraordinaryQuestionsSettings,
        showGlobalQuestions: settings.showGlobalQuestions,
        mailingSettings: settings.mailingSettings,
        socialNetworkSettings: settings.socialNetworkSettings,
        penaltySettings: settings.penaltySettings,
        isBannerActive: settings.isBannerActive,
        categoriesDisabled: settings.categoriesDisabled,
        disableInvitation: settings.disableInvitation,
        customization: settings.customization,
        topic: settings.topic
    }
}


interface Customization {
    logo: string;
    color: string;
}

export const CreateEmptyCustomization = (): Customization => {
    return {
        logo: "",
        color: ""
    }
}