import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import { appInsights } from '../common/telemetry/appInsights';
import Topic from "../models/topic";

const baseURL = process.env.REACT_APP_API;

export const GetTopics = async (title: string, isPublic: boolean): Promise<any> => {

    const url = baseURL + "/api/topic";
    let params = "";
    if (title != null && title != undefined) {
        params = "?title=" + title;
    }
    if (isPublic !== null && isPublic != undefined) {
        params += (params.length > 0 ? "&" : "?") + "ispublic=" + isPublic.toString() ;
    }
    const obj = {
        method: 'GET'
    };

    var resu = await MakeRequest(url + params, obj);
    return resu;
}

export const GetPublicTopics = async (): Promise<any> => {

    const url = baseURL + "/api/topic/public";
   
    const obj = {
        method: 'GET'
    };

    var resu = await MakeRequest(url, obj);
    return resu;
}

export const SaveTopic = async (topic: Topic): Promise<any> => {
    topic.isPublic = topic.isPublic.toString() == "1";
    const url = baseURL + "/api/topic";
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(topic)
    };

    var resu = await MakeRequest(url, obj);
    return resu;
}

export const NewTopic = async (topic: Topic): Promise<any> => {
    const url = baseURL + "/api/topic";
    topic.isPublic = topic.isPublic.toString() == "1";
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(topic)
    };
    var resu = await MakeRequest(url, obj);
    return resu;
}

export const DeleteTopic = async (topic: Topic): Promise<any> => {
    topic.isPublic = topic.isPublic.toString() == "1";
    const url = baseURL + "/api/topic";
    const obj = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(topic)
    };
    var resu = await MakeRequest(url, obj);
    return resu;
}




const MakeRequest = async (url: string, options: any) => {
    const pnp = new PnPFetchClient(msalInstance);

    return await pnp.fetch(url, options)
        .then(async (response) => {
            return await response.json();
        }).catch((error) => {
            appInsights.trackException({ exception: error });
            return null;
        });
}


export const TopicAPI = {
    GetTopics,
    SaveTopic,
    NewTopic,
    DeleteTopic,
    GetPublicTopics

}
