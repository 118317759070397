export default interface PenaltySettings {   
    isActive: boolean;
    days: number;
    points: number;
}

export const createNewPenaltySettings = (): PenaltySettings => {
    const defaultPenaltyDays = parseInt(process.env.DefaultSettings_PenaltyDays) || 3;
    const defaultPenaltyPoints = parseInt(process.env.DefaultSettings_PenaltyPoints) || 10;
    return {
        isActive: true,
        days: defaultPenaltyDays,
        points: defaultPenaltyPoints
    }
}