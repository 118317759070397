import { socialNetworkApi } from "../../api/socialNetwork";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";

export const loadUrlBlobSocialNetworkAction = (base64Data: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    socialNetworkApi.UrlBlobSocialNetworkAsync(base64Data).then((result: string) => {
        dispatch(loadUrlBlobSocialNetworkCompleted(result));
    })

    const loadUrlBlobSocialNetworkCompleted = (result: string) => ({
        type: actionsDefinition.GET_BLOB_SOCIAL_NETWORK,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}