import { actionsDefinition } from '../actions/actionsDefinition';
import User, { createEmptyUser } from '../models/user';



export interface currentUserState {
    currentUser: User;
    firstTenantConnection: boolean;
    adminConsentOptionSelected: boolean;
}
const initialState: currentUserState = {
    currentUser: createEmptyUser(),
    firstTenantConnection: null,
    adminConsentOptionSelected: false
}

export const currentUserReducer = (state: currentUserState = initialState, action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_CURRENT_USER:
            return handleCurrentUserCompleted(state, action.payload);
        case actionsDefinition.GET_TENANT_FIRST_CONNECTION:
            return handleTenantFirstConnection(state, action.payload);
        case actionsDefinition.SAVE_ADMIN_CONSENT_OPTION_SELECTED:
            return handleSaveAdminConsentOption(state, action.payload);
    }
    return state;
};

const handleCurrentUserCompleted = (state: currentUserState, payload: User) => {
    return {
        ...state,
        currentUser: payload
      };
};

const handleTenantFirstConnection = (state: currentUserState, payload: boolean) => {
    return {
        ...state,
        firstTenantConnection: payload
      };
};
const handleSaveAdminConsentOption = (state: currentUserState, payload: boolean) => {
    return {
        ...state,
        adminConsentOptionSelected: payload
      };
};