import { actionsDefinition } from '../actions/actionsDefinition';
import {ITag} from '@fluentui/react'
export const questionCategoriesReducer = (state: ITag[] = [], action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_QUESTION_CATEGORIES:
            return handleLoadQuestionCategoriesCompleted(state, action.payload);
    }
    return state;
};

const handleLoadQuestionCategoriesCompleted = (state: ITag[], payload: ITag[]) => {
    return payload;
}