import * as React from "react";
import "./css/style.css";



export interface ILoadingProps {
    text?: string;
    className?: string;
  }

  

export const LoadingComponent = function (props: ILoadingProps) {
  return (
    <div className={"row " + props.className}>
      <div className="col align-self-center">
        <div id="preloader">
          <div id="loader"></div>
        </div>
        <div>{props.text}</div>
      </div>
    </div>
  );
};
