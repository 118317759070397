import { userApi } from "../../api/userApi";
import User from "../../models/user";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { loadUsersAction } from "./loadUsers";
import { loadTop5UsersAction, loadCurrentUserAction, loadTopWeekUsers } from "../Home";
import * as toastr from 'toastr';

export const editUserAction = (user: User, successMessage: string) =>  (dispatch: any) => {
    dispatch(httpCallStartAction());
     userApi.EditUserAsync(user).then((result) => {
        dispatch(loadUsersAction());
        dispatch(loadCurrentUserAction());
        dispatch(loadTop5UsersAction());
        dispatch(loadTopWeekUsers());
        dispatch(editUserCompleted(result));
        if (successMessage !== '') {
            toastr.success(successMessage, '', { timeOut: 3000 });
        }
    }).catch(error => {
        toastr.error(error);
    });

    const editUserCompleted = (result: User) => ({
        type: actionsDefinition.EDIT_USER,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}