import * as React from "react";
import { RouteComponentProps } from "react-router";
import "./css/style.css";
import "./css/styleProfile.css";
import { Reto } from "./reto";
import { RetoCumplido } from "./retoCumplido";
import { WhatIsPlayQuiz } from "./whatIsPlayQuiz";
import { StartDailyChallenge } from "./startDailyChallenge";
import { EndDailyChallenge } from "./endDailyChallenge";
import { StartExtraChallenge } from "./startExtraChallenge";
import { EndExtraChallenge } from "./endExtraChallenge";
import { ModalCongratsInvite } from "./modalCongratsInvite";
import { UserProfile } from "./userProfile/userProfile";
import { Ranking } from "./ranking";
import User from "../../models/user";
import { SendChallengeContainer } from "../sendChallenge/sendChallengeContainer";
import { ConfigureContainer } from "../configure/configureContainer";
import DailyQuestion from "../../models/dailyQuestion";
import Settings from "../../models/settings";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { appInsights } from "../../common/telemetry/appInsights";
import { DailyQuestionsContainer } from "../dailyQuestions/dailyQuestionsContainer";
import { Icon } from "@fluentui/react";
import { translate, TransProps } from "react-i18next";
import { LoadingComponent } from "../../common/components/Loading/loading";
import { NoQuestionComponent } from "../../common/components/noQuestions/noquestion";
import LevelSettings from "../../models/levelSettings";
import { BannerComponent } from "./banner";
import { userApi } from "../../api/userApi";
import history from "../../history/history";

const blobStorageUrl = process.env.REACT_APP_BLOBSTORAGE_URL;
var weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export interface IHomeProps extends TransProps {
  getTop5Users: () => Promise<void>;
  getTopWeekUsers: () => Promise<void>;
  getCurrentUser: () => Promise<void>;
  getDailyQuestions: () => Promise<void>;
  getUrlBlobSocialNetWork: (base64Data: string) => Promise<string>;
  getSettings: () => void;
  getLevels: () => void;
  loadTenantFirstConnection: () => void;
  updateUser: (user: User) => Promise<void>;
  questionsComplete: () => void;
  top5Users: User[];
  topWeekUsers: User[];
  user: User;
  urlBlobSocialNetwork: string;
  dailyQuestions: DailyQuestion[];
  loadingQuestions: boolean;
  settings: Settings;
  token: string;
  levels: LevelSettings[];
  firstTenantConnection: boolean;
  questionsCompletedToday: boolean;
  isDailyQuestionEmpty: boolean;
}

export interface IHomeState {
  dailyQuestionsCompleted: number;
  showWhatIsPQ: boolean;
  SendChallenge: boolean;
  Configure: boolean;
  showAll: boolean;
  showSurvey: boolean;
  showExtraordinaryPanel: boolean;
  points: number;
  extraPoints: number;
  isExtraQuestions: boolean;
  showBanner: boolean;
  loadingTokenTeams: boolean;
  showCongratsInvite: boolean;
  alreadyAnswered: boolean;
  isExtraordinayDay: boolean;
}

export class Home extends React.Component<
  RouteComponentProps<{}> & IHomeProps,
  IHomeState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      dailyQuestionsCompleted: this.isDayofReto() ? 0 : 2,
      showWhatIsPQ: false,
      SendChallenge: false,
      Configure: false,
      showAll: false,
      showSurvey: false,
      showExtraordinaryPanel: false,
      points: 0,
      isExtraQuestions: false,
      extraPoints: 0,
      showBanner: true,
      loadingTokenTeams: false,
      showCongratsInvite: false,
      alreadyAnswered: false,
      isExtraordinayDay: false
    };

    this.completedReto = this.completedReto.bind(this);
    this.hideWhatIsPQ = this.hideWhatIsPQ.bind(this);
    this.hideSendChallenge = this.hideSendChallenge.bind(this);
    this.hideConfigure = this.hideConfigure.bind(this);
    this.showSendChallenge = this.showSendChallenge.bind(this);
    this.isFirstConnection = this.isFirstConnection.bind(this);
    this.hideBanner = this.hideBanner.bind(this);
  }

  isDayofReto(): boolean {
    var today = new Date();
    if (today.getDay() === 0 || today.getDay() === 6) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    if (window.location.hash.indexOf("#id_token") >= 0) {
      history.push("/");
    }
    if (window.self !== window.top) {
      if (!localStorage.getItem("token") && !localStorage.getItem("adal_44")) {
        this.setState({ loadingTokenTeams: true });
      } else {
        this.setState({ loadingTokenTeams: false });
      }
    }
    await this.props.loadTenantFirstConnection();
    await this.props.getCurrentUser();
    await this.props.getTop5Users();
    await this.props.getTopWeekUsers();
    await this.props.getSettings();
    await this.props.getLevels();
    await this.props.getDailyQuestions();
    this.CheckIfUserAlreadyAnswered();



  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.isDayofReto()) {
      const dailyQuestionAnswered = this.state.dailyQuestionsCompleted !== 1 ||
        this.props.questionsCompletedToday ||
        this.state.alreadyAnswered;

      let retoCompletado = this.retoCompletado();
      if (retoCompletado === 2) {
        this.props.questionsComplete();
      }
      if (
        this.props.dailyQuestions.length === 0 &&
        !this.props.loadingQuestions &&
        retoCompletado === 1 &&
        !this.props.isDailyQuestionEmpty
      ) {
        await this.props.getDailyQuestions();
      }
      if (
        this.props.questionsCompletedToday !==
        prevProps.questionsCompletedToday &&
        this.props.questionsCompletedToday
      ) {
        this.setState({
          dailyQuestionsCompleted: 2,
        });
      } else if (
        this.state.dailyQuestionsCompleted !== retoCompletado &&
        !this.props.questionsCompletedToday
      ) {
        this.setState({
          showWhatIsPQ: this.isFirstConnection(),
          dailyQuestionsCompleted: retoCompletado,
        });
      }
      if (dailyQuestionAnswered &&
        this.props.user?.UserConnection?.ExtraordinaryQuestionDay === weekDays[new Date().getDay()] &&
        !this.state.isExtraordinayDay) {

        this.setState({
          ...this.state,
          isExtraordinayDay: this.props.user?.UserConnection?.ExtraordinaryQuestionDay == weekDays[new Date().getDay()] || false
        });
      }

    }
    if (this.state.loadingTokenTeams && this.props.token.length > 0) {
      this.setState({
        loadingTokenTeams: false,
        showBanner: false,
      });
    }
  }

  private async completedReto(points: number, extraPoints: number) {
    await this.props.getCurrentUser();
    this.setState({
      dailyQuestionsCompleted: 2,
      points: points,
      extraPoints: extraPoints,
    });
  }

  private hideWhatIsPQ() {
    if (this.props.top5Users.length === 0) {
      this.setState({
        showWhatIsPQ: false,
        Configure: true,
      });
    } else {
      this.setState({
        showWhatIsPQ: false,
        SendChallenge: this.isFirstConnection(),
      });
    }
  }

  private showSendChallenge() {
    this.setState({
      SendChallenge: true,
    });
  }

  private async hideSendChallenge(isCloseButton: boolean) {
    if (this.isFirstConnection()) {
      await this.props.getCurrentUser();
      await this.props.getTop5Users();
      await this.props.getTopWeekUsers();
    }

    this.setState({
      SendChallenge: false,
      showAll: true,
      showCongratsInvite: !isCloseButton,
    });
  }

  private hideConfigure() {
    this.setState({
      Configure: false,
      SendChallenge: this.isFirstConnection(),
    });
  }

  private hideCongratsInvite() {
    this.setState({
      Configure: false,
      showCongratsInvite: false,
    });
  }

  private readonly showSurvey = (isExtraQuestions: boolean) => {
    let startTime = Date.now();
    this.setState({ showSurvey: true, isExtraQuestions: isExtraQuestions });
    let duration = Date.now() - startTime;
    appInsights.trackEvent({
      name: "Empezar reto",
      properties: {
        user: this.props.user.Name,
        tenant: this.props.user.TenantId,
      },
      measurements: { duration: duration },
    });
  };
  private readonly showExtraordinaryPanel = () => {
    let startTime = Date.now();
    this.setState({ showExtraordinaryPanel: true });
    let duration = Date.now() - startTime;
    appInsights.trackEvent({
      name: "Empezar reto extraordinario",
      properties: {
        user: this.props.user.Name,
        tenant: this.props.user.TenantId,
      },
      measurements: { duration: duration },
    });
  };

  private readonly closePanel = (points: number, extraPoints: number) => {
    this.setState({
      showSurvey: false,
    });
    this.completedReto(points, extraPoints);
  };

  private readonly cancel = () => {
    this.setState({
      showSurvey: false,
    });
  };

  private hideBanner() {
    this.setState({
      showBanner: false,
    });
  }

  public retoCompletado() {
    if (this.props.user?.UserConnection?.LastParticipation) {
      var dateSplit =
        this.props.user.UserConnection.LastParticipation.toString().split("-");
      var newDate =
        dateSplit[0] + "/" + dateSplit[1] + "/" + dateSplit[2].substring(0, 2);

      var lastParticipation: Date = new Date(newDate);

      var today = new Date();
      if (
        lastParticipation.getDate() === today.getDate() &&
        lastParticipation.getMonth() === today.getMonth() &&
        lastParticipation.getFullYear() === today.getFullYear()
      ) {
        return 2;
      } else return 1;
    } else return 0;
  }

  public render() {
    const dailyQuestionAnswered = this.state.dailyQuestionsCompleted !== 1 ||
      this.props.questionsCompletedToday ||
      this.state.alreadyAnswered;
    return (
      <>
        {this.state.loadingTokenTeams ||
          this.props.firstTenantConnection === null ||
          this.props.loadingQuestions ||
          this.props.top5Users.indexOf.length === 0 ? (
          <LoadingComponent className={"loading-container shadow"} />
        ) : (
          <div className="contenedor">
            {this.props.user?.UserConnection?.FirstConnection !== null &&
              this.state.SendChallenge ? (
              <SendChallengeContainer
                hideSendChallenge={this.hideSendChallenge}
                isFirstConnection={this.isFirstConnection}
                user={this.props.user}
              />
            ) : null}
            {this.state.showCongratsInvite ? (
              <ModalCongratsInvite
                hideCongratsInvite={this.hideCongratsInvite}
              />
            ) : null}
            {this.state.Configure ? (
              <ConfigureContainer hideConfigure={this.hideConfigure} />
            ) : null}
            {this.state.showWhatIsPQ ? (
              <WhatIsPlayQuiz hideWhatIsPQ={this.hideWhatIsPQ} />
            ) : null}
            {this.state.points > 0 ? (
              <EndDailyChallenge
                showExtraordinaryPanel={this.showExtraordinaryPanel}
                points={this.state.points}
                user={this.props.user}
                settings={this.props.settings}
              />
            ) : null}
            {this.state.showExtraordinaryPanel ||
              (!this.props.user.UserConnection.ExtraordinaryQuestionAnswered && this.state.isExtraordinayDay) && dailyQuestionAnswered ? (
              <StartExtraChallenge
                showSurvey={this.showSurvey}
                questionsNumber={
                  this.props.settings?.extraordinaryQuestionsSettings
                    ?.numberQuestions
                }
                extraordinaryQuestionsSettings={
                  this.props.settings.extraordinaryQuestionsSettings
                }
              />
            ) : null}
            {this.state.extraPoints > 0 ? (
              <EndExtraChallenge points={this.state.extraPoints} />
            ) : null}
            {this.state.showSurvey ? (
              <DailyQuestionsContainer
                showSurvey={this.state.showSurvey}
                cancel={() => {
                  this.cancel();
                }}
                closePanel={(points: number, extraPoints: number) => {
                  this.closePanel(points, extraPoints);
                }}
                isExtraQuestions={this.state.isExtraQuestions}
                settings={this.props.settings}
              />
            ) : null}
            {this._renderContent()}
          </div>
        )}{" "}
      </>
    );
  }

  private _renderContent(): JSX.Element {
    let result: JSX.Element = <></>;
    result = (
      <div className="container-fluid">
        <BannerComponent
          showBanner={this.state.showBanner}
          isBannerActive={this.props.settings?.isBannerActive}
          location={this.props.location.pathname}
          language={this.props.i18n.language}
          hideBanner={this.hideBanner}
        />
        <div className="row">
          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <UserProfile
              user={this.props.user}
              getUrlBlobSocialNetWork={this.props.getUrlBlobSocialNetWork}
              urlBlobSocialNetwork={this.props.urlBlobSocialNetwork}
              settings={this.props.settings}
              levels={this.props.levels}
            />

            {this._renderChallenge()}
          </div>

          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <Ranking
              top5Users={this.props.top5Users}
              topWeekUsers={this.props.topWeekUsers}
              showSendChallenge={this.showSendChallenge}
              levels={this.props.levels}
              settings={this.props.settings}
            />
          </div>
        </div>
      </div>
    );

    return result;
  }

  private _renderChallenge(): JSX.Element {
    let result: JSX.Element = <div className="empezarReto container"></div>;

    if (this.props.isDailyQuestionEmpty) {
      return <NoQuestionComponent />;
    }
    if (
      this.state.dailyQuestionsCompleted !== 0 ||
      this.props.questionsCompletedToday
    ) {
      if (
        this.state.dailyQuestionsCompleted !== 1 ||
        this.props.questionsCompletedToday ||
        this.state.alreadyAnswered
      ) {
        result = <RetoCumplido user={this.props.user} />;
      } else {
        result = (
          <>
            <StartDailyChallenge
              user={this.props.user}
              showSurvey={this.showSurvey}
              changeUser={this.props.updateUser}
            />
            <Reto completedReto={this.completedReto} user={this.props.user} />
          </>
        );
      }
    }

    return result;
  }

  private isFirstConnection() {
    let firstConection = null;
    let lastConection = null;

    if (this.props.user !== null && this.props.user.UserConnection !== null) {
      firstConection = this.props.user?.UserConnection.FirstConnection;
      lastConection = this.props.user?.UserConnection.LastConnection;
    }

    let first: Date =
      firstConection != null && firstConection != undefined
        ? new Date(this.props.user.UserConnection.FirstConnection)
        : new Date();
    let last: Date =
      lastConection != null && lastConection != undefined
        ? new Date(this.props.user.UserConnection.LastConnection)
        : new Date();

    const isFirstConnection =
      (first.getSeconds() === last.getSeconds() - 1 ||
        first.getSeconds() === last.getSeconds()) &&
      first.getMinutes() === last.getMinutes() &&
      first.getHours() === last.getHours() &&
      first.getDate() === last.getDate() &&
      first.getMonth() === last.getMonth() &&
      first.getFullYear() === last.getFullYear();

    return isFirstConnection;
  }
  CheckIfUserAlreadyAnswered = () => {
    userApi.GetIfUserAlreadyAnswer(this.state.isExtraQuestions).then((resu) => {
      this.setState({
        alreadyAnswered: resu,
      });
    });
  };
}

export const HomePage = translate("common")(
  withAITracking(new ReactPlugin(), Home)
);
