import { TopicAPI } from "../../api/topicApi";
import { actionsDefinition } from '../actionsDefinition';
import Topic from "../../models/topic";
import { utils } from "../../common/functions/utils";

export const GetTopicsAction = (title: string = null, isPublic: boolean = null) => async (dispatch: any) => {
    dispatch(InitLoadingTopics());
    await TopicAPI.GetTopics(title, isPublic).then((result) => {
        if (result == null)
        {
            result = [];
        }
        dispatch(GetTopicsCompleted(result));
    }).catch(error => {
        //TOASTR?
    });
}

export const GetPublicTopicsAction = () => async (dispatch: any) => {
    dispatch(InitLoadingTopics());
    await TopicAPI.GetPublicTopics().then((result) => {
        if (result == null)
        {
            result = [];
        }
        dispatch(GetTopicsCompleted(result));
    }).catch(error => {
        //TOASTR?
    });
}

export const SaveTopicAction = (topic: Topic, pageText) => async (dispatch: any) => {
    await TopicAPI.SaveTopic(topic).then((result) => {
        if (result) {
            utils.ShowToastr("success", pageText.SaveSuccess, "");
        }
    }).catch(error => {
        //TOASTR?
    });
}

export const DeleteTopicAction = (topic: Topic, pageText) => async (dispatch: any) => {
    await TopicAPI.DeleteTopic(topic).then((result) => {
        if (result) {
            utils.ShowToastr("success", pageText.DeleteSuccess, "");
        }
    }).catch(error => {
        //TOASTR?
    });
}


export const NewTopicAction = (topic: Topic, pageText) => async (dispatch: any) => {
    await TopicAPI.NewTopic(topic).then((result) => {
        if (result) {
            utils.ShowToastr("success", pageText.NewSuccess, "");
        }
    }).catch(error => {
        //TOASTR?
    });
}


const GetTopicsCompleted = (topics: Topic[]) => ({
    type: actionsDefinition.GET_TOPICS,
    payload: topics
});

const InitLoadingTopics = () => ({
    type: actionsDefinition.LOADING_TOPICS,
});