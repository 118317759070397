import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { RouteComponentProps } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { LoadingComponent } from "../../common/components/Loading/loading";

export class Login extends React.Component<RouteComponentProps<{}>, {}> {
    constructor(props)  {
        super(props);
    }

    componentDidMount() {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => {
          let state = uuidv4();
          localStorage.setItem("TeamsQuiz.state", state);
          localStorage.removeItem("TeamsQuiz.error");
    
          let queryParams = {
            client_id: process.env.REACT_APP_MSALCLIENTID,
            response_type: "id_token token",
            response_mode: "fragment",
            scope: "User.Read User.ReadBasic.All openid",
            redirect_uri: window.location.origin + "/teams/loginEnd",
            nonce: uuidv4(),
            state: state,
            login_hint: context.loginHint,        
          };
    
          let authorizeEndpoint = "https://login.microsoftonline.com/" + context.tid + "/oauth2/v2.0/authorize?" + this._toQueryString(queryParams);
          window.location.assign(authorizeEndpoint);      
        });
    }
    
    public render() {
        return (
                <LoadingComponent  className={"loading-container-login"}></LoadingComponent>
            
        )
    }

    _toQueryString(queryParams: any) {
        let encodedQueryParams = [];
        for (let key in queryParams) {
            encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
        }

        return encodedQueryParams.join("&");
    }
}