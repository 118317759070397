import { userApi } from "../../api/userApi";
import User from "../../models/user";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";

export const loadTopWeekUsers = () => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.GetTopWeekUsersAsync().then((result) => {
        dispatch(loadTopWeekUsersCompleted(result));
    })

    const loadTopWeekUsersCompleted = (result: User[]) => ({
        type: actionsDefinition.LOAD_TOPWEEK_USERS,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}