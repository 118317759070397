import { alertsApi } from "../../api/alertsApi";
import Alert from "../../models/alert";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { getUserAlerts } from "./getUserAlerts";
import { getUserUnreadAlertsNumber } from "./getUserUnreadAlertsNumber";
import { loadQuestionsAction } from "../../actions/Questions";

export const editAlert = (alert: Alert) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    alertsApi.UpdateAlert(alert).then((result) => {
        dispatch(getUserAlerts());
        dispatch(getUserUnreadAlertsNumber());
        dispatch(editAlertCompleted(result));
        if (alert.type === 'Hangfire - QuestionsCsv') {
            dispatch(loadQuestionsAction())
        }
    })

    const editAlertCompleted = (result: boolean) => ({
        type: actionsDefinition.alerts.UPDATE_ALERT,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}