const appJson = 'application/json';

export const headersRequest = {
    CONTENT_TYPE_JSON: { 'Content-Type': appJson },
    ACCEPT_JSON: { 'Accept': appJson },
    ACCEPT_CONTENT_TYPE_JSON: {
        'Accept': appJson,
        'Content-Type': appJson
    }
};
