import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { HomePage } from './home';
import { loadTop5UsersAction, loadTopWeekUsers, loadCurrentUserAction, loadUrlBlobSocialNetworkAction, loadTenantFirstConnectionAction } from '../../actions/Home';
import { getSettingsAction } from '../../actions/Admin/getSettings';
import { getLevelsAction } from '../../actions/Levels/getLevels';
import { loadDailyQuestionsAction, questionsComplete } from '../../actions/Questions';
import { editUserAction } from '../../actions/Users';
import User from '../../models/user';

const mapStateToProps = (state: IStateReducer) => ({
    top5Users: state.top5Users,
    topWeekUsers: state.topWeekUsers,
    user: state.currentUser.currentUser,
    dailyQuestions: state.dailyQuestion.dailyQuestion,
    loadingQuestions: state.dailyQuestion.loadingQuestions,
    urlBlobSocialNetwork: state.urlBlobSocialNetwork,
    settings: state.settingsState.settings,
    token: state.token,
    levels: state.levels.levels,
    firstTenantConnection: state.currentUser.firstTenantConnection,
    questionsCompletedToday: state.questionsCompletedToday,    
    isDailyQuestionEmpty: state.dailyQuestion.isDailyQuestionEmpty
});

const mapDispatchToProps = (dispatch: any) => ({
    getTop5Users: () => dispatch(loadTop5UsersAction()),
    getTopWeekUsers: () => dispatch(loadTopWeekUsers()),
    getCurrentUser: async () => dispatch(await loadCurrentUserAction()),
    getUrlBlobSocialNetWork: (base64Data: string) => dispatch(loadUrlBlobSocialNetworkAction(base64Data)),
    getDailyQuestions: () => dispatch(loadDailyQuestionsAction()),
    getSettings: () => dispatch(getSettingsAction()),
    getLevels: () => dispatch(getLevelsAction()),
    updateUser: (user: User) => dispatch(editUserAction(user, '')),
    loadTenantFirstConnection: () => dispatch(loadTenantFirstConnectionAction()),
    questionsComplete: () => dispatch(questionsComplete())
});

export const HomeContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
