import Settings from '../models/settings';
import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import { appInsights } from '../common/telemetry/appInsights';
import UploadedBanners from '../models/uploadedBanners';
import { utils } from '../common/functions/utils';

const baseURL = process.env.REACT_APP_API + '/api/tenant';

const GetSettings = async (): Promise<Settings> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        }
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToSettings(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return null;
        });
}

const UploadFile = async (formFile: any): Promise<any> => {
    const url = baseURL + "/uploadimage";
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formFile)
    }
    
    return await client.fetch(url, obj)
        .then(async response => {

            var result = await response.json();
            if (result.type == "Error")
            {
                return "";
            }
            if (result.length > 0)
            {
                return result[0];
            }
            return result;
            
        }).catch(error => {
            
            //appInsights.trackException(error);
            return null;
        });
}

const UpdateSettings = async (settings: Settings): Promise<Settings> => {
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(settings)
    }
    return client.fetch(baseURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToSettings(result);
            }

            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return null;
        });
}

const UploadBanner = async (formData: FormData, language: string): Promise<boolean> => {
    const uploadBannerUrl = baseURL + `/uploadBanner`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        body: formData
    }

    return client.fetch(uploadBannerUrl, obj)
        .then(async (response) => {

            if (response.status === 200) {
                var result = await response.json();
                return result;
            }

            return false;
        }).catch(error => {
            appInsights.trackException(error);
            return false;
        });
}

const CheckUploadedBanners = async (): Promise<UploadedBanners> => {
    const checkUploadedBannersUrl = baseURL + `/checkUploadedBanners`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return client.fetch(checkUploadedBannersUrl, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToCheckBanners(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return null;
        });
}

const mapToSettings = (response: any): Settings => {
    return {
        id: response.id,
        tenantId: response.tenantId,
        extraordinaryQuestionsSettings: {
            isActive: response.extraordinaryQuestionsSettings.isActive,
            numberQuestions: response.extraordinaryQuestionsSettings.numberQuestions,
            extraPointsSuccess: response.extraordinaryQuestionsSettings.extraPointsSuccess,
            extraPointsFailure: response.extraordinaryQuestionsSettings.extraPointsFailure
        },
        showGlobalQuestions: response.showGlobalQuestions == null ? true : response.showGlobalQuestions,
        mailingSettings: {
            isActive: response.mailingSettings.isActive
        },
        socialNetworkSettings: {
            facebook: response.socialNetworkSettings.facebook,
            instagram: response.socialNetworkSettings.instagram,
            linkedin: response.socialNetworkSettings.linkedin,
            teams: response.socialNetworkSettings.teams,
            twitter: response.socialNetworkSettings.twitter,
            yammer: response.socialNetworkSettings.yammer
        },
        penaltySettings: {
            isActive: response.penaltySettings.isActive,
            days: response.penaltySettings.days,
            points: response.penaltySettings.points
        },
        isBannerActive: response.isBannerActive,
        categoriesDisabled: response.categoriesDisabled,
        disableInvitation: response.disableInvitation,
        customization: response.customization,
        topic: response.topic
    };
}

const mapToCheckBanners = (response: any): UploadedBanners => {
    return {
        allChecked: true,
        desktopBannerEs: response.desktopBannerEs,
        desktopBannerEn: response.desktopBannerEn,
        mobileBannerEs: response.mobileBannerEs,
        mobileBannerEn: response.mobileBannerEn
    };
}

export const settingsApi = {
    GetSettings,
    UpdateSettings,
    UploadBanner,
    CheckUploadedBanners,
    UploadFile
}

