import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../../common/components/button/button";
import { Select } from "../../../common/components/select/select";
import "../css/style.css";

interface IUploadQuestionProps {
  panelUploadIsOpen: boolean;
  showFileUploadModal: (show) => void;
  t: any;
  onSelectedFile: (e) => void;
  onSelectTopic: any;
  topics: any;
  btnDisabled: boolean;
  selectedTopic: any;
  uploadQuestion: any;
  selectedFile: any;
}

export const UploadQuestionComponent = (props: IUploadQuestionProps) => {
  const AJAXSubmit = () => {
    const formData = new FormData(document.forms[0]);
    props.uploadQuestion(
      formData,
      props.t("ToastrMessages.Settings.UploadQuestions"),
      props.selectedTopic
    );
  };

  const GetFileName = (file) => {
    var lastIndex = file.lastIndexOf("\\") + 1;
    var fileName = file.substring(lastIndex, file.length);
    return fileName;
  };

  return (
    <Modal
      show={props.panelUploadIsOpen}
      onHide={() => {
        props.showFileUploadModal(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className=" d-flex justify-content-between w-100">
          <div>{props.t("AdminQuestion.TituloSubirFichero")}</div>
          <div className="botonCerrar">
            <button
              className="btn"
              onClick={() => {
                props.showFileUploadModal(false);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form encType="multipart/form-data" method="post" name="fileupload">
          <dl>
            <dt>
              <label htmlFor="FileUpload_FormFile">
                {props.t("AdminQuestion.Fichero")}
              </label>
            </dt>
            <dd>
              <label
                htmlFor="FileUpload_FormFile"
                className="btn btn-secondary labelFile"
              >
                {props.selectedFile.length > 0
                  ? props.t("AdminQuestion.SelectedFile")
                  : props.t("AdminQuestion.SelectFile")}
              </label>
              {props.selectedFile.length > 0 ? (
                <div className="labelFile fileName">
                  {GetFileName(props.selectedFile)}
                </div>
              ) : (
                <></>
              )}
              <div className="input-file">
                <input
                  title={
                    props.selectedFile.length > 0
                      ? GetFileName(props.selectedFile)
                      : props.t("AdminQuestion.SelectFile")
                  }
                  className="input-file-input"
                  id="FileUpload_FormFile"
                  type="file"
                  name="file"
                  accept="application/vnd.ms-excel,.xlsx,.xls"
                  onChange={props.onSelectedFile}
                />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>{props.t("AdminQuestion.Topic")}</dt>
            <dd>
              <Select
                placeholder={props.t("AdminQuestion.Topic")}
                className={"custom-select"}
                changeEvent={(e) => props.onSelectTopic(e.target.value)}
                lstOptions={props.topics}
              />
            </dd>
          </dl>
          <div>
            <output name="result"></output>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            props.showFileUploadModal(false);
          }}
        >
          {props.t("AdminQuestion.Botones.Cancelar")}
        </button>
        <button
          disabled={props.btnDisabled}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            AJAXSubmit();
            props.showFileUploadModal(false);
          }}
        >
          {props.t("AdminQuestion.Botones.Fichero")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
