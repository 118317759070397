import { userApi } from '../../api/userApi';
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { loadUsersAction } from "./loadUsers";
import * as toastr from 'toastr';

export const deleteUserAction = (userId: string, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.DeleteUserAsync(userId).then((result) => {
        dispatch(loadUsersAction());
        dispatch(deleteUserCompleted(result));
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });

    const deleteUserCompleted = (result: boolean) => ({
        type: actionsDefinition.DELETE_USER,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}