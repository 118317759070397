import { settingsApi } from "../../api/settingsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import Settings, { createNewSettings } from "../../models/settings";

export const getSettingsAction = () => async (dispatch: any) => {
    dispatch(httpCallStartAction());

    await settingsApi.GetSettings().then((result) => {
        if (result == null)
        {
            result = createNewSettings();
        }
        dispatch(getSettingsCompleted(result));
        dispatch(httpCallEndAction());
    }).catch(error => {
        //TOASTR?
    });
}

const getSettingsCompleted = (settings: Settings) => ({
    type: actionsDefinition.settings.GET_SETTINGS_COMPLETED,
    payload: settings
});
