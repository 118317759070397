import * as React from "react";
import { RouteComponentProps } from "react-router";
import { translate, TransProps } from "react-i18next";
import { SpinButton, Toggle } from "@fluentui/react";
import Settings, { createNewSettingsFromOther } from "../../models/settings";
import twitterIcon from "../../img/twitter-icon.svg";
import facebookIcon from "../../img/facebook-icon.svg";
import linkedinIcon from "../../img/linkedin-icon.svg";
import teamsIcon from "../../img/teams-icon.svg";
import yammerIcon from "../../img/yammer-icon.svg";
import { ITag } from "@fluentui/react";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { MessageAlertType } from "../../common/constants/enum";
import { LoadingComponent } from "../../common/components/Loading/loading";
import Customization from "./Customization/Customization";
import Topic from "../../models/topic";
import { Select } from "../../common/components/select/select";
import { CategoryItem } from "./CategoryContainer/categoryItem/categoryItem";
import { CategoryContainer } from "./CategoryContainer/categoryContainer";

export interface IAdminSettingsProps extends TransProps {
  t: any;
  settings: Settings;
  settingsCallCompleted: boolean;
  categories: ITag[];
  topics: Topic[];

  getSettings: () => void;
  GetTopics: (isPublic: boolean) => Promise<Topic[]>;
  getQuestionCategories: () => void;
  updateSettings: (settings: Settings, successMessage) => void;
}

interface categoriesActive {
  name: string;
  key: string | number;
  active: boolean;
}

export interface IAdminSettingsState {
  categories: categoriesActive[];
  filteredTopics: any[];
  isLoading: boolean;
}

const extraPointsMaxDefault = parseInt(
  process.env.REACT_APP_EXTRA_QUESTION_MAX_POINTS
);
const extraPointsSuccessDefault = parseInt(
  process.env.REACT_APP_EXTRA_QUESTION_SUCCESS_POINTS
);
const extraPointsFailureDefault = parseInt(
  process.env.REACT_APP_EXTRA_QUESTION_FAIL_POINTS
);
const consentURL =
  "https://login.microsoftonline.com/common/adminconsent?client_id=" +
  [process.env.REACT_APP_MSALCLIENTID];

class AdminSettingsPage extends React.Component<
  RouteComponentProps & IAdminSettingsProps,
  IAdminSettingsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      categories: [] as categoriesActive[],
      filteredTopics: [],
      isLoading: true
    };

    this.onBlurSpinSettings = this.onBlurSpinSettings.bind(this);
    this.onChangeToggle = this.onChangeToggle.bind(this);
    this.SaveCustomization = this.SaveCustomization.bind(this);
  }

  async componentDidMount() {
    await this.props.getSettings();
    await this.props.getQuestionCategories();
    await this.props.GetTopics(true);
    this.GetTopicsForSelect();
    if (this.props.settings !== null) {
      this.props.settings.customization = this.CheckCustomization(
        this.props.settings.customization
      );
    }

    this.setState({ isLoading: false });
  }

  public render() {
    return (
      <div>
        {this.state.isLoading ? (
          <LoadingComponent className="loading-center-in-component" />
        ) : this.props.categories != null ? (
          <div className="panelSettings">
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.ExtraordinaryQuestions.Title")}
                </div>
                <div>
                  <Toggle
                    checked={
                      this.props.settings.extraordinaryQuestionsSettings
                        .isActive
                    }
                    id={"extra-questions-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>

              <div className="description">
                {this.props.t("Settings.ExtraordinaryQuestions.Description")}
              </div>
              <div className="extraQuestions">
                <div className="extraQuestionsTitle">
                  {this.props.t(
                    "Settings.ExtraordinaryQuestions.NumberOfQuestions"
                  )}
                </div>
                <div>
                  <SpinButton
                    defaultValue={this.props.settings.extraordinaryQuestionsSettings.numberQuestions.toString()}
                    value={this.props.settings.extraordinaryQuestionsSettings.numberQuestions.toString()}
                    min={1}
                    max={100}
                    step={1}
                    id={"numberOfQuestions"}
                    incrementButtonAriaLabel={"Increase value by 1"}
                    decrementButtonAriaLabel={"Decrease value by 1"}
                    onBlur={this.onBlurSpinSettings}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="extraPointsSuccess mt-2">
                <div className="extraPointsSuccessTitle">
                  {this.props.t(
                    "Settings.ExtraordinaryQuestions.ExtraPointsSuccess"
                  )}
                </div>
                <div>
                  <SpinButton
                    defaultValue={this.props.settings.extraordinaryQuestionsSettings.extraPointsSuccess.toString()}
                    value={this.props.settings.extraordinaryQuestionsSettings.extraPointsSuccess.toString()}
                    min={1}
                    max={10}
                    step={1}
                    id={"extraPointsSuccess"}
                    incrementButtonAriaLabel={"Increase value by 1"}
                    decrementButtonAriaLabel={"Decrease value by 1"}
                    onBlur={this.onBlurSpinSettings}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="extraPointsFailure mt-2">
                <div className="extraPointsFailureTitle">
                  {this.props.t(
                    "Settings.ExtraordinaryQuestions.ExtraPointsFailure"
                  )}
                </div>
                <div>
                  <SpinButton
                    defaultValue={this.props.settings.extraordinaryQuestionsSettings.extraPointsFailure.toString()}
                    value={this.props.settings.extraordinaryQuestionsSettings.extraPointsFailure.toString()}
                    min={1}
                    max={10}
                    step={1}
                    id={"extraPointsFailure"}
                    incrementButtonAriaLabel={"Increase value by 1"}
                    decrementButtonAriaLabel={"Decrease value by 1"}
                    onBlur={this.onBlurSpinSettings}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
            </div>

            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.CommonQuestions.Title")}
                </div>
                <div>
                  <Toggle
                    checked={this.props.settings.showGlobalQuestions}
                    id={"common-questions-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>

              <div className="description">
                {this.props.t("Settings.CommonQuestions.Description")}
              </div>
            </div>

            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.ActiveTopic.Title")}
                </div>
                <div>
                  <Select
                    selectedValue={
                      this.props.settings.topic != null &&
                        this.props.settings.topic.length > 0
                        ? this.props.settings.topic
                        : ""
                    }
                    placeholder={this.props.t("AdminQuestion.Topic")}
                    className={"custom-select"}
                    changeEvent={(e) => this.SaveTopic(e.target.value)}
                    lstOptions={this.state.filteredTopics}
                  />
                </div>
              </div>

              <div className="description">
                {this.props.t("Settings.ActiveTopic.Description")}
              </div>
            </div>

            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.DisableInvitation.Title")}
                </div>
                <div>
                  <Toggle
                    checked={this.props.settings.disableInvitation}
                    id={"disabled-invitation"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>

              <div className="description">
                {this.props.t("Settings.DisableInvitation.Description")}
              </div>
            </div>
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.Category.Title")}
                </div>
              </div>
              <CategoryContainer
                settings={this.props.settings}
                updateSettings={this.props.updateSettings}
                settingsCallCompleted={this.props.settingsCallCompleted}
                t={this.props.t}
                categories={this.props.categories} />
            </div>
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.Mailing.Title")}
                </div>
                <div>
                  <Toggle
                    id={"mailing-active"}
                    onChange={this.onChangeToggle}
                    checked={this.props.settings.mailingSettings.isActive}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="onlyDescription">
                <div>{this.props.t("Settings.Mailing.Description.Text")}</div>
                <div className="ml-3">
                  {this.props.t("Settings.Mailing.Description.T1")}
                </div>
                <div className="ml-3">
                  {this.props.t("Settings.Mailing.Description.T2")}
                </div>
                <div className="ml-3">
                  {this.props.t("Settings.Mailing.Description.T3")}
                </div>
              </div>
            </div>
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.SocialNetworks.Title")}
                </div>
              </div>
              <div className="description">
                {this.props.t("Settings.SocialNetworks.Description")}
              </div>
              <div className="socialNetworksContainer row">
                <div className="socialItem col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
                  <div>
                    <img
                      className="facebook-icon"
                      src={facebookIcon}
                      alt="facebook-icon"
                    />
                    <div>Facebook</div>
                  </div>
                  <Toggle
                    checked={this.props.settings.socialNetworkSettings.facebook}
                    id={"facebook-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
                <div className="socialItem col-6 col-sm-4  col-md-3 col-lg-2 mb-2">
                  <div>
                    <img
                      className="twitter-icon"
                      src={twitterIcon}
                      alt="twitter-icon"
                    />
                    <div>Twitter</div>
                  </div>
                  <Toggle
                    checked={this.props.settings.socialNetworkSettings.twitter}
                    id={"twitter-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
                <div className="socialItem col-6 col-sm-4  col-md-3 col-lg-2 mb-2">
                  <div>
                    <img
                      className="linkedin-icon"
                      src={linkedinIcon}
                      alt="linkedin-icon"
                    />
                    <div>Linkedin</div>
                  </div>
                  <Toggle
                    checked={this.props.settings.socialNetworkSettings.linkedin}
                    id={"linkedin-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
                <div className="socialItem col-6 col-sm-4  col-md-3 col-lg-2 mb-2">
                  <div>
                    <img
                      className="teams-icon"
                      src={teamsIcon}
                      alt="teams-icon"
                    />
                    <div>Teams</div>
                  </div>
                  <Toggle
                    checked={this.props.settings.socialNetworkSettings.teams}
                    id={"teams-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
                <div className="socialItem col-6 col-sm-4  col-md-3 col-lg-2 mb-2">
                  <div>
                    <img
                      className="yammer-icon"
                      src={yammerIcon}
                      alt="yammer-icon"
                    />
                    <div>Yammer</div>
                  </div>
                  <Toggle
                    checked={this.props.settings.socialNetworkSettings.yammer}
                    id={"yammer-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
            </div>
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.Penalty.Title")}
                </div>
                <div>
                  <Toggle
                    checked={this.props.settings.penaltySettings.isActive}
                    id={"penalty-active"}
                    onChange={this.onChangeToggle}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="description">
                {this.props.t("Settings.Penalty.Description")}
              </div>
              <div className="penaltyDays">
                <div className="penaltyDaysTitle">
                  {this.props.t("Settings.Penalty.Days")}
                </div>
                <div>
                  <SpinButton
                    defaultValue={this.props.settings.penaltySettings.days.toString()}
                    value={this.props.settings.penaltySettings.days.toString()}
                    min={1}
                    max={30}
                    step={1}
                    id={"penaltyDays"}
                    incrementButtonAriaLabel={"Increase value by 1"}
                    decrementButtonAriaLabel={"Decrease value by 1"}
                    onBlur={this.onBlurSpinSettings}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="penaltyPoints mt-2">
                <div className="penaltyPointsTitle">
                  {this.props.t("Settings.Penalty.Points")}
                </div>
                <div>
                  <SpinButton
                    defaultValue={this.props.settings.penaltySettings.points.toString()}
                    value={this.props.settings.penaltySettings.points.toString()}
                    min={1}
                    max={100}
                    step={1}
                    id={"penaltyPoints"}
                    incrementButtonAriaLabel={"Increase value by 1"}
                    decrementButtonAriaLabel={"Decrease value by 1"}
                    onBlur={this.onBlurSpinSettings}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
            </div>

            <Customization
              onCompleteEvent={this.SaveCustomization}
              pageText={this.props.t("Settings.Customization", {
                returnObjects: true,
              })}
              color={this.props.settings.customization.color}
              logo={this.props.settings.customization.logo}
            />
          </div>
        ) : (
          <MessagePanel
            type={MessageAlertType.Warning}
            title={this.props.t("AdminPanel.AccesoDen")}
            message={this.props.t("AdminPanel.AccesoDenMjs")}
          />
        )}
      </div>
    );
  }

  private onBlurSpinSettings(event: React.FocusEvent<HTMLInputElement>) {
    const id = event.currentTarget.parentElement.id;
    const parsedValue = isNaN(Number.parseFloat(event.currentTarget.value))
      ? 0
      : Number.parseFloat(event.currentTarget.value);
    const value = Math.round(parsedValue);
    let newSettings = createNewSettingsFromOther(this.props.settings);

    const successMessage = this.props.t(
      "ToastrMessages.Settings.UpdateSettings"
    );

    switch (id) {
      case "numberOfQuestions":
        if (value > 0) {
          newSettings.extraordinaryQuestionsSettings.numberQuestions = value;
        } else {
          newSettings.extraordinaryQuestionsSettings.numberQuestions = 1;
        }
        break;
      case "extraPointsSuccess":
        if (value > 0 && value <= extraPointsMaxDefault) {
          newSettings.extraordinaryQuestionsSettings.extraPointsSuccess = value;
        } else {
          newSettings.extraordinaryQuestionsSettings.extraPointsSuccess =
            extraPointsSuccessDefault;
        }
        break;
      case "extraPointsFailure":
        if (value > 0 && value <= extraPointsMaxDefault) {
          newSettings.extraordinaryQuestionsSettings.extraPointsFailure = value;
        } else {
          newSettings.extraordinaryQuestionsSettings.extraPointsFailure =
            extraPointsFailureDefault;
        }
        break;
      case "penaltyDays":
        if (value > 0 && value <= 30) {
          newSettings.penaltySettings.days = value;
        } else {
          newSettings.penaltySettings.days = 0;
        }
        break;
      case "penaltyPoints":
        if (value > 0 && value <= 500) {
          newSettings.penaltySettings.points = value;
        } else {
          newSettings.penaltySettings.points = 0;
        }
        break;
    }

    this.props.updateSettings(newSettings, successMessage);
  }

  private onChangeToggle(
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean
  ) {
    const id = ev.currentTarget.id;
    let newSettings = createNewSettingsFromOther(this.props.settings);
    const successMessage = this.props.t(
      "ToastrMessages.Settings.UpdateSettings"
    );
    switch (id) {
      case "mailing-active":
        if (this.props.settings.mailingSettings.isActive !== checked) {
          newSettings.mailingSettings.isActive = checked;
        }
        break;
      case "facebook-active":
        if (this.props.settings.socialNetworkSettings.facebook !== checked) {
          newSettings.socialNetworkSettings.facebook = checked;
        }
        break;
      case "instagram-active":
        if (this.props.settings.socialNetworkSettings.instagram !== checked) {
          newSettings.socialNetworkSettings.instagram = checked;
        }
        break;
      case "twitter-active":
        if (this.props.settings.socialNetworkSettings.twitter !== checked) {
          newSettings.socialNetworkSettings.twitter = checked;
        }
        break;
      case "linkedin-active":
        if (this.props.settings.socialNetworkSettings.linkedin !== checked) {
          newSettings.socialNetworkSettings.linkedin = checked;
        }
        break;
      case "teams-active":
        if (this.props.settings.socialNetworkSettings.teams !== checked) {
          newSettings.socialNetworkSettings.teams = checked;
        }
        break;
      case "yammer-active":
        if (this.props.settings.socialNetworkSettings.yammer !== checked) {
          newSettings.socialNetworkSettings.yammer = checked;
        }
        break;
      case "extra-questions-active":
        if (
          this.props.settings.extraordinaryQuestionsSettings.isActive !==
          checked
        ) {
          newSettings.extraordinaryQuestionsSettings.isActive = checked;
        }
        break;
      case "common-questions-active":
        if (this.props.settings.showGlobalQuestions !== checked) {
          newSettings.showGlobalQuestions = checked;
        }
        break;

      case "disabled-invitation":
        if (this.props.settings.disableInvitation !== checked) {
          newSettings.disableInvitation = checked;
        }
        break;
      case "penalty-active":
        if (this.props.settings.penaltySettings.isActive !== checked) {
          newSettings.penaltySettings.isActive = checked;
        }
        break;
    }

    this.props.updateSettings(newSettings, successMessage);
  }

  public async SaveCustomization(fieldName, fieldValue) {
    var newSettings = this.props.settings;
    newSettings.customization[fieldName] = fieldValue;

    await this.props.updateSettings(
      newSettings,
      this.props.t("ToastrMessages.Settings.UpdateSettings")
    );
  }
  public CheckCustomization(customization) {
    if (customization == null || customization == null) {
      customization = {
        logo: "",
        color: "",
      };
    }
    return customization;
  }

  SaveTopic = async (value) => {
    var newSettings = this.props.settings;
    newSettings.topic = value;

    await this.props.updateSettings(
      newSettings,
      this.props.t("ToastrMessages.Settings.UpdateSettings")
    );
  };

  GetTopicsForSelect = () => {
    var topics = [
      {
        text: this.props.t("AdminQuestion.SelectTopic"),
        value: "",
      },
    ];
    if (this.props.topics !== null) {
      this.props.topics.forEach((topic) => {
        var item = {
          value: topic.title,
          text: topic.title,
        };
        topics.push(item);
      });
      this.setState({
        filteredTopics: topics,
      });
    }
  };
}

export const AdminSettings = translate("common")(AdminSettingsPage);
