import LevelSettings from "../../models/levelSettings";
import i18next from 'i18next';
import * as toastr from "toastr";


function cloneObject<T>(obj: T): T {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}

export const GetUserLevel = (levels: LevelSettings[], totalPoints: number) => {
    var selectedLevel: LevelSettings = {
        color: "brown",
        name: "Amateur",
        scoreInit: 1,
        scoreEnd: 60,
        number: 1,
        id: "src/common/functions/utils.ts"
    };
    if (levels !== null && totalPoints !== null) {
        levels.forEach((level) => {
            if (totalPoints >= level.scoreInit && totalPoints <= level.scoreEnd) {
                selectedLevel = level;
            }
        })
    }


    return selectedLevel;
}
export const getValueLevelPercentage = (userLevel: LevelSettings, totalPoints: number) => {
    const totalLevelPoints = userLevel?.scoreEnd - userLevel?.scoreInit;
    const actualLevelPoints = totalPoints - userLevel?.scoreInit;
    const result = (actualLevelPoints * 100) / totalLevelPoints;
    return result;
}
function getAuraClass(levelUser: number, levels: LevelSettings[]): string {

    if (levels != null) {
        var color = "aura-user-"
        levels.forEach(level => {
            if (levelUser === level.number) {
                color += level.color;
            }
        });
        return color;
    }
    return "";
}
function isDemo(): boolean {
    if (window.location.search.split('?')[1] === "demo=true") {
        return true;
    }
    return false;
}

function adminConsentGranted(): boolean {
    if (window.location.search.split('?')[1]) {
        if (window.location.search.split('?')[1].includes("admin_consent=True")) {
            return true;
        }

    }
    return false;
}

function getCurrentLanguage(): string {
    let whitelist: string[] = ['en', 'es'];
    const defaultLng: string = whitelist[0];
    let selectedLng: string = i18next.language !== null ? i18next.language : defaultLng;
    return selectedLng;
}

function getUTCHours(): string {
    var date = new Date();
    var hours = new Date(date + 'z').getTime() - date.getTime();
    hours /= (1000 * 60 * 60);
    hours = Math.round(hours);
    return isNaN(hours) ? '0' : hours.toString();
}

function UTC_To_Local(date: Date): Date {
    return new Date(new Date(date) + 'Z');
}
const ShowToastr = (type: ToastrType, title: string, msg: string) => {
    const option: ToastrOptions = {
        positionClass: "toast-top-right",
    };

    switch (type) {
        case "error":
            toastr.error(msg, title, option);
            break;
        case "info":
            toastr.info(msg, title, option);
            break;
        case "success":
            toastr.success(msg, title, option);
            break;
        case "warning":
            toastr.warning(msg, title, option);
            break;
    }
};

export const utils = {
    cloneObject,
    getAuraClass,
    isDemo,
    adminConsentGranted,
    getCurrentLanguage,
    getUTCHours,
    UTC_To_Local,
    ShowToastr
}
