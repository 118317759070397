import { connect } from "react-redux";
import { IStateReducer } from "../../reducers";
import { DailyQuestions } from "./dailyQuestions";
import {
  loadDailyQuestionsAction,
  questionsComplete,
} from "../../actions/Questions";
import { loadTop5UsersAction, loadTopWeekUsers } from "../../actions/Home";

const mapStateToProps = (state: IStateReducer) => ({
  dailyQuestions: state.dailyQuestion.dailyQuestion,
  loadingQuestions: state.dailyQuestion.loadingQuestions,
  user: state.currentUser.currentUser,
  extraordinaryQuestions: state.dailyQuestion.extraQuestions,
  questionsCompletedToday: state.questionsCompletedToday,
  isDailyQuestionEmpty: state.dailyQuestion.isDailyQuestionEmpty,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDailyQuestions: () => dispatch(loadDailyQuestionsAction()),
  questionsComplete: () => dispatch(questionsComplete()),
  GetTopWeekUsers: () => dispatch(loadTopWeekUsers()),
  GetTopUsers: () => dispatch(loadTop5UsersAction()),
});

export const DailyQuestionsContainer: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyQuestions);
