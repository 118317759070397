import { userApi } from "../../api/userApi";
import User from "../../models/user";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";

export const loadTop5UsersAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.GetTop5UsersAsync().then((result) => {
        dispatch(loadTop5UsersCompleted(result));
    })

    const loadTop5UsersCompleted = (result: User[]) => ({
        type: actionsDefinition.LOAD_TOP5_USERS,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}