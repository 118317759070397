import React from 'react';

export function LayoutStyler(Comp: React.ComponentClass<any>, LayoutParam: React.ComponentType<any>): React.ComponentClass<any> {
    class Styler extends React.Component<any>{

        public render() {
            return (
                <LayoutParam>
                    <Comp {...this.props} />
                </LayoutParam>
            );
        }
    }
    return Styler;
}