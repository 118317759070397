import User from "../models/user";
import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import UserAnswer from "../models/userAnswer";
import UserConnection from "../models/userConnection";
import UserPosition from "../models/userPosition";
import { appInsights } from '../common/telemetry/appInsights';
import userSetting from "../models/userSetting";
import { utils } from '../common/functions/utils';
import TeamsFunctions from "../common/functions/teamsFunctions";


const baseURL = process.env.REACT_APP_API;


export interface IHttpRequest {
    body: ReadableStream;
    bodyUsed: false;
    headers: any;
    ok: boolean;
    redirected: boolean;
    status: number;
    statusText: string;
    type: string;
    url: string;
}



export const CheckIfAdmin = async (setIfAdmin?): Promise<any> => {
    const url = baseURL + "/api/auth/isadmin";
    const pnp = new PnPFetchClient(msalInstance);
    const headers = {
        method: 'GET'
    }
    return pnp.fetch(url, headers)
        .then((response) => {
            setIfAdmin(prev => response.ok);
            return response;

        }).catch((error) => {
            appInsights.trackException({ exception: error });
            setIfAdmin(prev => false);
            return null;
        });
}


const GetUsersAsync = async (): Promise<User[]> => {
    const getUserURL = baseURL + `/api/user/all`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours()
        }
    }

    return pnp.fetch(getUserURL, obj).then(async (response) => {
        if (!response) {
            return [];
        }
        const data = await response.json().then((json) => {
            const users = mapToUsers(json)
            return users;
        });
        return data;
    }).catch((error) => {

        appInsights.trackException({ exception: error });
        return [];
    });
}


const GetTop5UsersAsync = async (): Promise<User[]> => {
    const getUserURL = baseURL + `/api/user/top5`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours()
        }
    }
    return pnp.fetch(getUserURL, obj).then(async (response) => {
        if (!response) {
            return [];
        }
        const data = await response.json().then((json) => {
            const users = mapToUsers(json)
            return users;
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const GetTopWeekUsersAsync = async (): Promise<User[]> => {
    const getUserURL = baseURL + `/api/user/weekTop`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours()
        }
    }
    return pnp.fetch(getUserURL, obj).then(async (response) => {
        if (!response) {
            return [];
        }
        const data = await response.json().then((json) => {
            const users = mapToUsers(json)
            return users;
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const GetCurrentUserAsync = async (): Promise<User> => {
    const getUserURL = baseURL + "/api/user";
    const pnp = new PnPFetchClient(msalInstance);
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours(),
            'Lang': navigator.language,
            'Platform': TeamsFunctions.IsInTeams() ? "PlayQuiz Teams App" : "PlayQuiz Web App"
        }
    }
    return pnp.fetch(getUserURL, options).then(async (response) => {
        if (!response) {
            return [];
        }
        const data = await response.json().then((json) => {
            const user = mapToUser(json)
            return user;
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const AddUserAsync = async (user: User): Promise<boolean> => {
    const addUserURL = baseURL + `/api/user/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours()
        },
        body: JSON.stringify(user)
    }
    return await pnp.fetch(addUserURL, obj).then((response) =>
        response.json());
}
const EditUserAsync = async (user: User): Promise<User> => {
    const editUserURL = baseURL + `/api/user/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Demo': utils.isDemo().toString(),
            'UTC': utils.getUTCHours()
        },
        body: JSON.stringify(user)
    }
    return await pnp.fetch(editUserURL, obj).then(async (response) => {
        if (!response) {
            return null;
        }
        const data = await response.json().then((json) => {
            return mapToUser(json);
        });
        return data;
    }).catch((error) => {
        appInsights.trackException({ exception: error });
        return null;
    });
}

const DeleteUserAsync = async (userId: string): Promise<boolean> => {
    const deleteUserURL = baseURL + `/api/user/`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(userId)
    }
    return await pnp.fetch(deleteUserURL, obj).then((response) =>
        response.json());
}

const GetTenantFirstConnection = async (): Promise<boolean> => {
    const url = baseURL + `/api/user/firstConnection`;
    const pnp = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }
    return await pnp.fetch(url, obj).then((response) =>
        response.json());
}

const GetTenantFirstConnectionTeams = async (tenantId): Promise<boolean> => {
    const getFirstConnectionURL = baseURL + `/api/user/firstConnectionTeams`;
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(tenantId)
    }
    return await fetch(getFirstConnectionURL, obj).then((response) =>
        response.json());
}

const GetIfUserAlreadyAnswer = async (isExtraQuestion: boolean): Promise<boolean> => {
    const getFirstConnectionURL = baseURL + `/api/useranswer/useralreadyanswered`;
    const pnp = new PnPFetchClient(msalInstance);

    if (isExtraQuestion === undefined || isExtraQuestion === null) {
        isExtraQuestion = false;
    }

    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'UTC': utils.getUTCHours(),
            'IsExtraordinary': isExtraQuestion.toString()
        },
    }
    return await pnp.fetch(getFirstConnectionURL, obj).then((response) =>
        response.json());
}
const UpdateLanguage = async (lang: string) => {
    const client = new PnPFetchClient(msalInstance);
    const url = baseURL + `/api/user/changeLanguage?lang=${lang}`;
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }
    return client.fetch(url, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return result;
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return null;
        });
}
const EvaluateSurvey = async (userAnswers: UserAnswer[], isExtraQuestion: boolean): Promise<any> => {
    const apiUrl = baseURL + `/api/useranswer/EvaluateAnswers`;
    const pnp = new PnPFetchClient(msalInstance);

    var objBody = {
        isExtraordinary: isExtraQuestion,
        userAnswers: userAnswers
    };
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'UTC': utils.getUTCHours(),
        },
        body: JSON.stringify(objBody)
    }
    return await pnp.fetch(apiUrl, obj).then((response) =>
        response.json());
}


const UpdateResetScoreUsers = async (): Promise<boolean> => {
    const pnp = new PnPFetchClient(msalInstance);
    const updateResetScoreUsersURL = baseURL + `/api/user/resetscore`;
    const obj = {
        method: 'POST',
    }
    return await pnp.fetch(updateResetScoreUsersURL, obj).then((response) =>
        response.ok)
}

const mapToUsers = (response: any[]): User[] => {
    let result = response != null ? response.map((e) => mapToUser(e)) : [];
    return result
}

const mapToUser = (response: any): User => {
    return {
        Id: response.id,
        TenantId: response.tenantId,
        Name: response.name,
        Email: response.email,
        UrlImage: response.urlImage,
        Level: response.level,
        TotalPoints: response.totalPoints,
        Role: response.role,
        DailyPoints: response.dailyPoints,
        WeeklyPoints: response.weeklyPoints,
        UserPosition: response.userPosition ? mapToUserPosition(response.userPosition) : null,
        UserConnection: response.userConnection ? mapToUserConnection(response.userConnection) : null,
        UserSettings: response.userSettings ? mapToUserSetting(response.userSettings) : null,
    };
}

const mapToUserConnection = (response: any): UserConnection => {
    return {
        Id: response.id,
        TenantId: response.tenantId,
        UserId: response.userId,
        LastConnection: response.lastConnection,//utils.UTC_To_Local(response.lastConnection),
        FirstConnection: response.firstConnection,//utils.UTC_To_Local(response.firstConnection),
        LastParticipation: response.lastParticipation,//utils.UTC_To_Local(response.lastParticipation),
        MondayParticipation: response.mondayParticipation,
        TuesdayParticipation: response.tuesdayParticipation,
        WednesdayParticipation: response.wednesdayParticipation,
        ThursdayParticipation: response.thursdayParticipation,
        FridayParticipation: response.fridayParticipation,
        StreakActive: response.streakActive,
        ExtraordinaryQuestionDay: response.extraordinaryQuestionDay,
        ExtraordinaryQuestionAnswered: response.extraordinaryQuestionAnswered,
    };
}

const mapToUserSetting = (response: any): userSetting => {
    return {
        Language: response.language,
        PlayQuizPopup: response.teamsquizPopup
    };
}

const mapToUserPosition = (response: any): UserPosition => {
    return {
        Position: response.position,
        TotalUsers: response.totalUsers
    };
}

export const userApi = {
    CheckIfAdmin,
    GetUsersAsync,
    GetTop5UsersAsync,
    GetCurrentUserAsync,
    AddUserAsync,
    DeleteUserAsync,
    EditUserAsync,
    GetTopWeekUsersAsync,
    GetTenantFirstConnection,
    GetTenantFirstConnectionTeams,
    UpdateResetScoreUsers,
    GetIfUserAlreadyAnswer,
    EvaluateSurvey,
    UpdateLanguage
}
