import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import "./css/style.css";
import User from "../../models/user";
import Alert from "../../models/alert";
import { msalInstance } from "../../common/hoc/auth";
import { translate, TransProps } from "react-i18next";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsights } from "../../common/telemetry/appInsights";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { Panel, IconButton } from "@fluentui/react";
import teamaQuizAlertIcon from "../../img/imagotipo-playquiz S.png";
import { utils } from "../../common/functions/utils";
import { Person } from "mgt-react";
import { Button } from "../../common/components/button/button";
import Settings from "../../models/settings";
import i18next, { LIST_LANGUAGES } from "../../i18n";

export interface IHeaderProps extends TransProps {
  user: User;
  getCurrentUser: () => Promise<void>;
  alerts: Alert[];
  getUserAlerts: () => Promise<void>;
  getSettings: () => Promise<void>;
  updateAlert: (alert: Alert) => Promise<void>;
  markAsRead: () => Promise<boolean>;
  getUserUnreadAlerts: () => Promise<void>;
  unreadAlerts: number;
  loadDailyQuestionsAction: () => Promise<void>;
  t: any;
  settings: Settings;
}

export interface IHeaderState {
  user: User;
  alerts: Alert[];
  lang: string;
  alertsOpen: boolean;
}

class HeaderComponent extends React.Component<
  RouteComponentProps & IHeaderProps,
  IHeaderState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: this.props.user,
      alerts: this.props.alerts,
      lang: i18next.language,
      alertsOpen: false,
    };
  }

  componentDidUpdate(prevProps: IHeaderProps, prevState: IHeaderState) {
    if (this.props !== prevProps) {
      this.setState({
        user: this.props.user,
        alerts: this.props.alerts,
      });
    }

    document.title = this.props.t("PlayQuizTitle");
  }

  async componentDidMount() {
    this.props.getUserAlerts();
    this.props.getUserUnreadAlerts();
    await this.props.getSettings();
  }

  handleChange = (event) => {
    this.setState({
      lang: event.target.value,
    });
    i18next.changeLanguage(event.target.value);
    let newAppInsights = appInsights;
    newAppInsights.trackEvent({
      name: "Cambio idioma",
      properties: {
        user: this.props.user.Name,
        tenant: this.props.user.TenantId,
        language: event.target.value,
      },
    });
    this.props.loadDailyQuestionsAction();
  };
  template2 = () => {
    return (
      <mgt-person person-query="me" show-name show-email inh>
        <template>
          <div className="site-user userDiv mx-3" data-if="personImage">
            <div className="d-flex">
              <img src="{{personImage}}" alt=""></img>
            </div>
          </div>
          <div>
            <label title="{{person.displayName}}"></label>
            <label title="{{person.displayName}}"></label>
            <label title="{{person.displayName}}"></label>
            <label title="{{person.givenName}}"></label>
            <label title="{{person.surname}}"></label>
            <label title="{{person.userPrincipalName}}"></label>
          </div>
        </template>
      </mgt-person>
    );
  };
  template = () => {
    // si tuvieramos permiso para el tema de las imagenes nos valdia con esto
    // return<mgt-person person-query='me' show-name show-email />
    return (
      <>
        <Person personDetails={{ displayName: this.props.user.Name }} />

        <div className="userDetail">
          <span className="userName">{this.props.user.Name}</span>
          <span className="userEmail">{this.props.user.Email}</span>
          <i className="fas fa-caret-down dropdown-icon arrowIcon"></i>
        </div>
      </>
    );
  };
  private cambiarPagina(pagina: string) {
    let newAppInsights = appInsights;
    newAppInsights.trackPageView({ name: pagina });
  }

  private readonly onDismissAlerts = () => {
    this.setState({
      alertsOpen: false,
    });
  };
  private readonly onToggleAlerts = () => {
    this.setState({
      alertsOpen: !this.state.alertsOpen,
    });
  };

  private updateAlert(alert: Alert) {
    alert.readed = true;
    this.props.updateAlert(alert);
  }
  private renderAlerts = (alerts: Alert[]) => {
    const lstAlertsFiltered = alerts.filter((c) => !c.readed);

    return lstAlertsFiltered.map((alert, index) => (
      <div
        className="row user-notification"
        key={index}
        onClick={() => this.updateAlert(alert)}
      >
        <div className="col-3">
          <img
            className="teamQuizAlertIcon"
            src={teamaQuizAlertIcon}
            alt="PlayQuiz: "
          />
        </div>
        <div className="col-9">
          <span className="title">{alert.message}</span>
          <p className="date">
            Created: {alert.created.toString().substr(0, 10)}
          </p>
        </div>
      </div>
    ));
  };

  public render() {
    return (
      <>
        <Panel
          headerText={i18next.t("common:Home.Alerts")}
          isBlocking={false}
          isOpen={this.state.alertsOpen}
          onDismiss={this.onDismissAlerts}
          className="alerts-panel"
        >
          <div className="container">
            {this.props.alerts.filter((c) => !c.readed).length > 0 ? (
              <Button
                className={"btn-primary read-all"}
                text={i18next.t("common:Home.AlertsReadAll")}
                clickEvent={() => this.SetAllAlertsRead()}
              />
            ) : (
              <></>
            )}

            {this.renderAlerts(this.props.alerts)}
          </div>
        </Panel>
        <header
          className="w-100 d-flex justify-content-between fixed-top shadow p-3"
          style={{ background: this.GetColor() }}
        >
          <div className="logo-zone">
            <div className="d-inline-flex ml-5">
              <Link
                className="d-inline-flex text-decoration-none"
                onClick={() => this.cambiarPagina("home")}
                to="/"
              >
                <img src={teamaQuizAlertIcon} alt="Logo" />
              </Link>
            </div>
            {this.props.settings !== null &&
              this.props.settings.customization !== null &&
              this.props.settings.customization.logo !== null &&
              this.props.settings.customization.logo.length > 0 ? (
              <div className="d-inline-flex ml-5">
                <img src={this.props.settings.customization.logo} alt="Logo" />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="header-menu d-inline-flex justify-content-between">
            <div className="language-select align-self-center mx-3">
              <select
                value={this.state.lang}
                className="custom-select"
                onChange={this.handleChange}
              >
                {
                  LIST_LANGUAGES.map((language, index) => {
                    return <option key={index} value={language}>{language.toLocaleUpperCase()}</option>
                  })
                }
              </select>
            </div>
            <div className="d-inline-flex align-self-center mx-1">
              <IconButton
                className={`alerts-icon ${this.state.alertsOpen ? "active" : ""
                  }`}
                iconProps={{ iconName: "Ringer" }}
                onClick={this.onToggleAlerts}
              />
              {this.props.unreadAlerts > 0 ? (
                <div className="alertUnreadedNumberContainer">
                  <div className="alertUnreaderNumberContent">
                    {this.props.unreadAlerts}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="user-avatar-display">
              <div>
                {this.props.user != null && this.props.user != undefined
                  ? this.template()
                  : null}
              </div>

              <div className="site-user-settings">
                <nav className="settings-header">
                  <ul>
                    <li>
                      <div className="optionText">
                        <div className="optionText-inner">
                          {this.props.user != null &&
                            this.props.user != undefined ? (
                            <div
                              className=""
                              style={{
                                display:
                                  this.state.user?.Role === "Admin" &&
                                    !utils.isDemo()
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              <Link
                                className=""
                                onClick={() =>
                                  this.cambiarPagina("Admin Panel")
                                }
                                to="/adminpanel"
                              >
                                {" "}
                                <p className="">
                                  <i className="fas fa-users-cog mr-10"></i>
                                  {this.props.t("Header.AdminLink")}
                                </p>
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="optionText">
                        <div className="optionText-inner">
                          <div className="">
                            <p
                              className=""
                              onClick={() => msalInstance.logout()}
                            >
                              <span>
                                <i className="fas fa-sign-out-alt mr-10"></i>
                                {this.props.t("Header.LogoutLink")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="optionText">
                        <div className="optionText-inner"></div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  SetAllAlertsRead(): void {
    this.props.markAsRead();
  }
  GetColor() {
    if (
      this.props.settings === null ||
      this.props.settings.customization === null ||
      this.props.settings.customization === undefined
    ) {
      return "";
    } else {
      var color = this.props.settings.customization.color;
      return color;
    }
  }
}

let reactPlugin = new ReactPlugin();
export const Header = translate("common")(
  withAITracking(reactPlugin, HeaderComponent)
);
