import * as microsoftTeams from "@microsoft/teams-js";
import i18next, { LIST_LANGUAGES } from "../../i18n";

export default class TeamsFunctions {
    public static IsInTeams(): boolean {
        try {


            microsoftTeams.getContext((context: any) => {
                if (context != null) {
                    return true;
                }
            })

            if (window.self !== window.top)
                return true;

            const hostClientType = localStorage.getItem("hostClientType");

            if (hostClientType != null
                && (hostClientType.indexOf("android") >= 0 || hostClientType.toLowerCase().indexOf("ios") >= 0)) {
                return true;
            }
            return false;

        } catch (e) {
            return true;
        }
    }

    public static getTeamsLanguage() {
        microsoftTeams.getContext((context) => {
            if (context != null) {
                const localeContext = context.locale;
                if (localeContext != null && localeContext != undefined && localeContext != "") {
                    const locale = localeContext.split("-")[0];
                    i18next.changeLanguage(locale);

                    if (LIST_LANGUAGES.indexOf(locale) < 0) {
                        i18next.changeLanguage("en")
                    }
                }
            }
        });
    }
}