import { alertsApi } from "../../api/alertsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import Alert from "../../models/alert";

export const getUserAlerts = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    alertsApi.GetUserAlerts().then((result) => {
        dispatch(getAlertsCompleted(result));
        dispatch(httpCallEndAction());
    }).catch(error => {
        //TOASTR?
    });
}

const getAlertsCompleted = (levels: Alert[]) => ({
    type: actionsDefinition.alerts.GET_USER_ALERTS,
    payload: levels
});


export const setUserAlertReadAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    alertsApi.SetUserAlertRead().then((result) => {
        if (result) {
            dispatch(getUserAlerts());
            dispatch(setUserAlertReadCompleted());
        }
        dispatch(httpCallEndAction());
    }).catch(error => {
        //TOASTR?
    });
}
const setUserAlertReadCompleted = () => ({
    type: actionsDefinition.alerts.SET_ALERTS_READ,
});
//actionsDefinition.alerts.SET_ALERTS_READ
