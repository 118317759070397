import * as React from "react";
import { RouteComponentProps } from "react-router";
import User from "../../models/user";
import "./css/style.css";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import { translate, TransProps, I18nextProvider } from "react-i18next";
import Pagination from "../../common/pagination";
import { SendChallengeContainer } from "../sendChallenge/sendChallengeContainer";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { Input } from "../../common/components/input/input";
import { Select } from "../../common/components/select/select";
import { MessageAlertType } from "../../common/constants/enum";
import { LoadingComponent } from "../../common/components/Loading/loading";
import { EditUserModal } from "./modal/editUserModal";
import { ModalConfirm } from "./modal/confirmModal";
import { Button } from "../../common/components/button/button";

export interface IProjectsProps extends TransProps {
  getUsers: () => Promise<User[]>;
  addUser: (user: User[], successMessage: string) => Promise<boolean>;
  editUser: (user: User, successMessage: string) => Promise<boolean>;
  deleteUser: (userId: string, successMessage: string) => Promise<boolean>;
  users: User[];
  t: any;
  user: User;
  resetScoreUsers: (successMessage: string) => Promise<boolean>;
}

export interface IProjectsState {
  panelNewUserIsOpen: boolean;
  panelEditUserIsOpen: boolean;
  panelDeleteUserIsOpen: boolean;
  panelResetScoreIsOpen: boolean;
  id: string;
  tenantId: string;
  name: string;
  email: string;
  urlImage: string;
  role: string;
  level: number;
  totalPoints: number;
  t: any;
  rol: string;
  nombre: string;
  correo: string;
  totalPages: number;
  page: number;
  showAlertExistUser: boolean;
  alertUsers: string[];
}

class AdminUserPage extends React.Component<
  RouteComponentProps & IProjectsProps,
  IProjectsState
> {
  isLoading = true;
  constructor(props: any) {
    super(props);
    this.state = {
      panelNewUserIsOpen: false,
      panelEditUserIsOpen: false,
      panelDeleteUserIsOpen: false,
      panelResetScoreIsOpen: false,
      id: "",
      tenantId: "",
      name: "",
      email: "",
      urlImage: "",
      role: "",
      level: 1,
      totalPoints: 0,
      t: I18nextProvider,
      rol: "Todos",
      nombre: "",
      correo: "",
      totalPages: 1,
      page: 1,
      showAlertExistUser: false,
      alertUsers: [],
    };

    this.renderAddUserModal = this.renderAddUserModal.bind(this);
    this.renderEditUserModal = this.renderEditUserModal.bind(this);
    this.renderDeleteUserModal = this.renderDeleteUserModal.bind(this);
    this.renderAlertExistUser = this.renderAlertExistUser.bind(this);
    this.showNewUserModal = this.showNewUserModal.bind(this);
    this.showEditUserModal = this.showEditUserModal.bind(this);
    this.showDeleteUserModal = this.showDeleteUserModal.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.hideSendChallenge = this.hideSendChallenge.bind(this);
    this.hideEditUserModal = this.hideEditUserModal.bind(this);
    this.hideDeleteUserModal = this.hideDeleteUserModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.showAlertExistUser = this.showAlertExistUser.bind(this);
  }

  async componentDidMount() {
    await this.props.getUsers();

    this.isLoading = false;
  }

  componentDidUpdate(prevProps: IProjectsProps, prevState: IProjectsState) {
    if (this.props !== prevProps) {
      if (this.props.users && this.props.users !== prevProps.users) {
        let morePages = this.props.users.length % 10 === 0;
        let pages = this.props.users.length / 10;
        if (
          morePages &&
          (prevState.page === pages || prevState.page - 1 === pages)
        ) {
          this.setState({
            page: this.props.users.length / 10,
          });
        }
      }
    }
  }

  private showResetScoreModal = (open: boolean) => {
    this.setState({
      panelResetScoreIsOpen: open,
    });
  };

  private showNewUserModal(open: boolean, role: string = "") {
    this.setState({
      panelNewUserIsOpen: open,
      name: "",
      email: "",
      urlImage: "",
      role: role,
      level: 1,
      totalPoints: 0,
      alertUsers: [],
      showAlertExistUser: false,
    });
  }

  private showEditUserModal(u: User) {
    this.setState({
      panelEditUserIsOpen: true,
      id: u.Id,
      tenantId: u.TenantId,
      name: u.Name,
      email: u.Email,
      urlImage: u.UrlImage,
      role: u.Role,
      level: u.Level,
      totalPoints: u.TotalPoints,
    });
  }

  private showDeleteUserModal(u: User) {
    this.setState({
      panelDeleteUserIsOpen: true,
      id: u.Id,
      tenantId: u.TenantId,
      name: u.Name,
      email: u.Email,
    });
  }

  private handleEditUser() {
    let editUser: User = {
      Id: this.state.id,
      TenantId: this.state.tenantId,
      Name: this.state.name,
      Email: this.state.email,
      UrlImage: this.state.urlImage,
      Role: this.state.role,
      Level: this.state.level,
      TotalPoints: this.state.totalPoints,
      DailyPoints: 0,
      WeeklyPoints: 0,
    };
    this.props.editUser(
      editUser,
      this.props.t("ToastrMessages.Settings.UpdateUser")
    );
    this.hideEditUserModal();
  }

  private handleDeleteUser() {
    this.props.deleteUser(
      this.state.id,
      this.props.t("ToastrMessages.Settings.DeleteUser")
    );
    this.hideDeleteUserModal();
  }

  private handleResetScoreUsers = () => {
    this.props.resetScoreUsers(
      this.props.t("ToastrMessages.Settings.ResetScore")
    );
    this.showResetScoreModal(false);
  };

  private hideEditUserModal() {
    this.setState({
      panelEditUserIsOpen: false,
      id: "",
      tenantId: "",
      name: "",
      email: "",
      urlImage: "",
      role: "",
      level: 1,
      totalPoints: 0,
    });
  }

  private hideDeleteUserModal() {
    this.setState({
      panelDeleteUserIsOpen: false,
      id: "",
      tenantId: "",
      name: "",
      email: "",
      urlImage: "",
      role: "",
      level: 1,
      totalPoints: 0,
    });
  }

  private showAlertExistUser(show: boolean) {
    this.setState({
      showAlertExistUser: show,
    });
  }

  private hideSendChallenge() {
    this.setState({
      panelNewUserIsOpen: false,
    });
  }

  renderAddUserModal() {
    return this.state.panelNewUserIsOpen ? (
      <SendChallengeContainer
        hideSendChallenge={this.hideSendChallenge}
        isFirstConnection={(): boolean => {
          return false;
        }}
        user={this.props.user}
      />
    ) : null;
  }

  renderEditUserModal() {
    let editUser: User = {
      Id: this.state.id,
      TenantId: this.state.tenantId,
      Name: this.state.name,
      Email: this.state.email,
      UrlImage: this.state.urlImage,
      Role: this.state.role,
      Level: this.state.level,
      TotalPoints: this.state.totalPoints,
      DailyPoints: 0,
      WeeklyPoints: 0,
    };

    var header = {
      title: this.props.t("AdminUser.TituloEditarUs"),
      onClick: this.hideEditUserModal,
    };

    var body = {
      nameText: this.props.t("AdminUser.Nombre"),
      namePlhd: this.props.t("AdminUser.NombrePlaceholder"),
      state: this.state,
      onChangeName: (c) => this.setState({ name: c.target.value }),
      onChangeRol: (c) => this.setState({ role: c.target.value }),
      emailText: this.props.t("AdminUser.Email"),
      rolText: this.props.t("AdminUser.Rol.Titulo"),
      optioUserText: this.props.t("AdminUser.Rol.Usuario"),
      optionAdminText: this.props.t("AdminUser.Rol.Admin"),
    };

    var footer = {
      onClickCancel: this.hideEditUserModal,
      onClickAccept: this.handleEditUser,
      textEdit: this.props.t("AdminUser.Botones.Editar"),
      textCancel: this.props.t("AdminUser.Botones.Cancelar"),
    };

    return (
      <EditUserModal
        user={editUser}
        show={this.state.panelEditUserIsOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  }
  renderDeleteUserModal() {
    const header = {
      title: this.props.t("AdminUser.TituloEliminarUs"),
      onClick: this.ManageResult,
    };

    const body = {
      text:
        "<div> " +
        this.props.t("AdminUser.MensajeEliminarUs") +
        " <br /><br /> " +
        this.props.t("AdminUser.Nombre") +
        ":" +
        this.state.name +
        "<br />" +
        this.props.t("AdminUser.Email") +
        ":" +
        this.state.email +
        "</div>",
    };

    const footer = {
      onClick: this.ManageResult,
      textAccept: this.props.t("AdminUser.Botones.Borrar"),
      textCancel: this.props.t("AdminUser.Botones.Cancelar"),
    };

    return (
      <ModalConfirm
        show={this.state.panelDeleteUserIsOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  }
  ManageResult = (resu) => {
    if (resu) {
      this.handleDeleteUser();
    } else {
      this.hideDeleteUserModal();
    }
  };
  renderResetScoreModal = () => {
    const header = {
      title: this.props.t("AdminUser.Botones.Resetear"),
      onClick: this.ManageResetResult,
    };

    const body = {
      text: this.props.t("AdminUser.MensajeResetScore"),
    };

    const footer = {
      onClick: this.ManageResetResult,
      textAccept: this.props.t("AdminUser.Botones.Resetear"),
      textCancel: this.props.t("AdminUser.Botones.Cancelar"),
    };
    return (
      <ModalConfirm
        show={this.state.panelResetScoreIsOpen}
        header={header}
        body={body}
        footer={footer}
      />
    );
  };
  ManageResetResult = (resu) => {
    if (resu) {
      this.handleResetScoreUsers();
    } else {
      this.showResetScoreModal(false);
    }
  };

  renderAlertExistUser() {
    return (
      <Toast onClose={() => this.showAlertExistUser(false)}>
        <Toast.Header>
          <i className="fas fa-user-friends mr-2"></i>Los siguientes usuarios ya
          existen
        </Toast.Header>
        <Toast.Body>{this.state.alertUsers.join("\n")}</Toast.Body>
      </Toast>
    );
  }

  clearFilter() {
    this.setState({
      rol: "Todos",
      nombre: "",
      correo: "",
      page: 1,
    });
  }

  handleChange = (event) => {
    this.setState({
      rol: event.target.value,
      page: 1,
    });
  };

  handleChangeEmail = (event) => {
    this.setState({
      correo: event.target.value,
      page: 1,
    });
  };

  handleChangeName = (event) => {
    this.setState({
      nombre: event.target.value,
      page: 1,
    });
  };

  private readonly initPage = () => {
    this.setState({ page: 1 });
  };
  private readonly prevPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage - 1 });
  };
  private readonly paginate = (page: number) => {
    this.setState({ page: page });
  };
  private readonly nextPage = () => {
    let actualPage = this.state.page;
    this.setState({ page: actualPage + 1 });
  };
  private readonly lastPage = () => {
    this.setState({ page: this.state.totalPages });
  };

  public render() {
    const users = this.props.users;
    if (users != null && users.length > 0) {
      users.sort((a, b) => {
        return a.Name > b.Name ? 1 : -1;
      });
    }
    const lstOptions =  [
      {value: 'Todos', text: this.props.t("AdminUser.Rol.Todos")},
      {value: 'Admin', text: this.props.t("AdminUser.Rol.Admin")},
      {value: 'User', text: this.props.t("AdminUser.Rol.Usuario")},
    ]
    return (
      <div>
        {this.isLoading ? (
            <LoadingComponent className="loading-center-in-component" />         
        ) : users != null ? (
          <div className="panelAdminUser">
            {this.renderAddUserModal()}
            {this.renderEditUserModal()}
            {this.renderDeleteUserModal()}
            {this.renderResetScoreModal()}
            <div className="row">
              <div className="col-12">
                <Button
                  clickEvent={() => this.showNewUserModal(true, "User")}
                  text={this.props.t("AdminUser.Botones.AddUser")}
                  icon="fa-plus mr-2"
                  className={"btn-primary custom mb-1"}
                />

                <Button
                  clickEvent={() => this.showResetScoreModal(true)}
                  text={this.props.t("AdminUser.Botones.Resetear")}
                  icon="fa-minus mr-2"
                  className={"btn-primary custom"}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-3 col-sm-6 mb-1">
                <Input
                 value={this.state.nombre}
                 type="text"
                 changeEvent={this.handleChangeName}
                 className="form-control"
                 placeholder={this.props.t("AdminUser.Placeholder.Usuario")}
                 />
                
              </div>
              <div className="col-lg-3 col-sm-6 mb-1">
                <Input
                  value={this.state.correo}
                  type="text"
                  changeEvent={this.handleChangeEmail}
                  className="form-control"
                  placeholder={this.props.t("AdminUser.Placeholder.Email")}
                />
              </div>
              <div className="col-lg-3 col-sm-6">
                <Select 
                selectedValue={this.state.rol}
                  className={"custom-select"}
                  changeEvent={this.handleChange}
                  lstOptions={lstOptions} />
              </div>
              <div className="col-lg-3 col-sm-6 text-right">

              <Button
                  clickEvent={() => this.clearFilter()}
                  text={this.props.t("AdminUser.BorrarFiltros")}
                  icon="fa-filter mr-2"
                />                
              </div>
            </div>
            {users.length > 0 ? (
              <div >
                
                <table className="table questionTable">
                  <tbody>
                    <tr className="row">
                      <th className="col-8 col-md-3 col-sm-6">
                        <label className="mx-2 text-secondary">
                          {this.props.t("AdminUser.Nombre")}
                        </label>
                      </th>
                      <th className="col-md-3 d-none d-md-block">
                        <label className="mx-1 text-secondary">
                          {this.props.t("AdminUser.Email")}
                        </label>
                      </th>
                      <th className="col-md-2 d-none d-md-block">
                        <label className="text-secondary">
                          {this.props.t("AdminUser.Rol.Titulo")}
                        </label>
                      </th>
                      <th className="col-md-2 col-sm-4  d-none d-sm-block">
                        <label className="text-secondary">
                          {this.props.t("AdminUser.Nivel")}
                        </label>
                      </th>
                      <th className="col-4 col-md-2 col-sm-2">
                        <label className="text-secondary">
                          {this.props.t("AdminQuestion.Actions")}
                        </label>
                      </th>
                    </tr>

                    {this.renderAllUsers()}
                  </tbody>
                </table>
                
                {this.state.totalPages > 1 ? (
                  <Pagination
                    totalPages={this.state.totalPages}
                    currentPage={this.state.page}
                    itemRange={3}
                    initPage={this.initPage}
                    prevPage={this.prevPage}
                    paginate={this.paginate}
                    nextPage={this.nextPage}
                    lastPage={this.lastPage}
                  />
                ) : null}
              </div>
            ) : (
              <MessagePanel
                type={MessageAlertType.Info}
                title=""
                message={this.props.t("AdminPanel.NoUsers")}
              />
            )}
          </div>
        ) : (
          <MessagePanel
            type={MessageAlertType.Warning}
            title={this.props.t("AdminPanel.AccesoDen")}
            message={this.props.t("AdminPanel.AccesoDenMjs")}
          />
        )}
      </div>
    );
  }

  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  filtrar(users: User[]) {
    if (users !== null && users.length > 0) {
      if (this.state.rol !== "Todos") {
        users = users.filter((item) => item.Role === this.state.rol);
      }

      users = users.filter((item) =>
        this.removeAccents(item.Email ? item.Email.toLowerCase() : "").includes(
          this.removeAccents(this.state.correo.toLowerCase())
        )
      );
      users = users.filter((item) =>
        this.removeAccents(item.Name ? item.Name.toLowerCase() : "").includes(
          this.removeAccents(this.state.nombre.toLowerCase())
        )
      );

      if (
        Math.ceil(users.length / 10) !== this.state.totalPages &&
        users.length > 0
      ) {
        this.setState({
          totalPages: Math.ceil(users.length / 10),
        });
      }
      return users.slice(this.state.page * 10 - 10, this.state.page * 10);
    } else {
      return users;
    }
  }

  renderAllUsers = () => {
    let users = this.filtrar(this.props.users);
    return users.map((u, index) => (
      <tr className="row" key={index}>
        <th className="col-8 col-md-3 col-sm-6  align-items-center">
          <p className="pl-2 text-truncate" title={u.Name}>
            {u.Name}
          </p>
          <p className="pl-2 text-truncate text-secondary d-md-none" title={u.Email}>
            {u.Email}
          </p>

          <p className="pl-2 text-truncate d-md-none ">
            {u.Role === "Admin"
              ? this.props.t("AdminUser.Rol.Admin")
              : this.props.t("AdminUser.Rol.Usuario")}
          </p>
          <span className="pl-2 text-secondary d-md-none">
            {this.props.t("AdminUser.Nivel")} {u.Level}
          </span>

        </th>
        <th className=" col-md-3  align-items-center d-none d-md-block">
          <p className="pl-2 text-truncate" title={u.Email}>
            {u.Email}
          </p>
        </th>
        <th className="col-md-2 col-sm-4 align-items-center  d-none d-md-block">
          <p className="pl-2 text-truncate">
            {u.Role === "Admin"
              ? this.props.t("AdminUser.Rol.Admin")
              : this.props.t("AdminUser.Rol.Usuario")}
          </p>
          <p className="pl-2 text-secondary d-md-none">
            {this.props.t("AdminUser.Nivel")} {u.Level}
          </p>
        </th>
        <th className="col-md-2 col-sm-4 align-items-center d-none d-sm-block">
          <span className="pl-2">
            {this.props.t("AdminUser.Nivel")} {u.Level}
          </span>
        </th>
        <th className="col-4 col-md-2 col-sm-2 d-flex justify-content-around">
          {this.props.user.Id === u.Id ? (
            <button
              className="btn disabled"
              title={this.props.t("AdminUser.Botones.Borrarse")}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          ) : (
            <button
              className="btn"
              title={this.props.t("AdminUser.Botones.Borrar")}
              onClick={() => {
                this.showDeleteUserModal(u);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          )}
          <button
            className="btn"
            title={this.props.t("AdminUser.Botones.Editar")}
            onClick={() => {
              this.showEditUserModal(u);
            }}
          >
            <i className="fas fa-edit ml-2"></i>
          </button>
        </th>
      </tr>
    ));
  };
}

export const AdminUser = translate("common")(AdminUserPage);
