import * as React from "react";
import { translate, TransProps } from "react-i18next";
import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Icon,
  Toggle,
} from "@fluentui/react";
import UploadedBanners from "../../models/uploadedBanners";
import Settings, { createNewSettingsFromOther } from "../../models/settings";
import { MessagePanel } from "../../common/components/messagePanel/messagePanel";
import { MessageAlertType } from "../../common/constants/enum";
import { LoadingComponent } from "../../common/components/Loading/loading";

export interface IAdminBannerProps extends TransProps {
  uploadBannerCallCompleted: boolean;
  t: any;
  banners: UploadedBanners;
  settings: Settings;
  settingsCallCompleted: boolean;

  uploadBanner: (
    formData: FormData,
    language: string,
    successMessage: string
  ) => void;
  checkUploadedBanners: () => void;
  updateSettings: (settings: Settings, successMessage: string) => void;
}

interface IAdminBannerState {
  dropdownOptions: IDropdownOption[];
  language: string;
  showErrorMessageFile: boolean;
  showErrorMessageFileType: boolean;
}

class AdminBannerComponent extends React.Component<
  IAdminBannerProps,
  IAdminBannerState
> {
  isLoading = true;
  constructor(props: any) {
    
    super(props);

    const dropdownOptions = this.getDropdownLanguageOptions();
    this.state = {
      dropdownOptions: dropdownOptions,
      language: dropdownOptions[0].key.toString(),
      showErrorMessageFile: false,
      showErrorMessageFileType: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.getDropdownLanguageOptions = this.getDropdownLanguageOptions.bind(
      this
    );
    this.onChangeLanguageDropdown = this.onChangeLanguageDropdown.bind(this);
    this.onChangeFileInput = this.onChangeFileInput.bind(this);
    this.onChangeToggle = this.onChangeToggle.bind(this);
  }

  async componentDidMount() {
    this.props.checkUploadedBanners();
    this.isLoading = false;
  }

  public render() {
    return (
      <div>
        {this.isLoading ? (
            <LoadingComponent className="loading-center-in-component" />
        ) : 
        this.props.banners != null && this.props.settings != null ? (
          <div className="bannerSettingsContainer">
            <div className="title">{this.props.t("Settings.Banner.Title")}</div>
            <div className="settingsSection">
              <div className="titleSection">
                <div className="title">
                  {this.props.t("Settings.Banner.IsActive")}
                </div>
                <div>
                  <Toggle
                    id={"banner-active"}
                    onChange={this.onChangeToggle}
                    checked={this.props.settings.isBannerActive}
                    disabled={!this.props.settingsCallCompleted}
                  />
                </div>
              </div>
              <div className="description">
                {this.props.t("Settings.Banner.Description")}
              </div>
            </div>
            <form
              encType="multipart/form-data"
              onSubmit={() => {
                return false;
              }}
              name="fileupload"
              className="row"
            >
              <div className="col-8">
                <div>
                  <label htmlFor="imageUploader">
                    {this.props.t("Settings.Banner.File")}
                  </label>
                </div>
                <div>
                  <input
                  className="w-100"
                    id="imageUploader"
                    type="file"
                    name="file"
                    accept=".png"
                    onChange={this.onChangeFileInput}
                  />
                </div>
                <div className="description">
                  La imágenes deben de ser con el <b>nombre</b>{" "}
                  "desktop_banner.png" o "mobile_banner.png" con <b>tamaño</b>{" "}
                  680 x 245 píxeles. <b>Tipo de archivo aceptado</b>: .png. El{" "}
                  <b>idioma</b> lo determina la configuración actual de
                  PlayQuiz.
                </div>
                {this.state.showErrorMessageFile ||
                this.state.showErrorMessageFileType ? (
                  <div className="errorMessage">
                    {this.state.showErrorMessageFile
                      ? this.props.t("Settings.Banner.ErrorMessageFile")
                      : null}
                    {this.state.showErrorMessageFileType
                      ? this.props.t("Settings.Banner.ErrorMessageFileType")
                      : null}
                  </div>
                ) : null}
              </div>
              <div className="col-4">
                <div className="languageContainer">
                  <Dropdown
                    className="text-uppercase w-fit-content"
                    defaultSelectedKey={this.state.language}
                    options={this.state.dropdownOptions}
                    label={this.props.t("Settings.Banner.Language")}
                    onChange={this.onChangeLanguageDropdown}
                  />
                </div>
              </div>
            </form>
            <div className="buttonContainer">
              <PrimaryButton
                text={this.props.t("Buttons.Submit")}
                onClick={this.onSubmit}
                disabled={!this.props.uploadBannerCallCompleted}
              />
            </div>
            {this.props.banners.allChecked ? (
              <div className="bannerTypeCheck">
                <ul>
                  <li>
                    <Icon
                      iconName={
                        this.props.banners.desktopBannerEs
                          ? "CheckMark"
                          : "Cancel"
                      }
                      className={
                        this.props.banners.desktopBannerEs
                          ? "uploadedBanner"
                          : "notBanner"
                      }
                    />
                    <span>Uploaded Desktop Banner ES</span>
                  </li>
                  <li>
                    <Icon
                      iconName={
                        this.props.banners.desktopBannerEn
                          ? "CheckMark"
                          : "Cancel"
                      }
                      className={
                        this.props.banners.desktopBannerEn
                          ? "uploadedBanner"
                          : "notBanner"
                      }
                    />
                    <span>Uploaded Desktop Banner EN</span>
                  </li>
                  <li>
                    <Icon
                      iconName={
                        this.props.banners.mobileBannerEs
                          ? "CheckMark"
                          : "Cancel"
                      }
                      className={
                        this.props.banners.mobileBannerEs
                          ? "uploadedBanner"
                          : "notBanner"
                      }
                    />
                    <span>Uploaded Mobile Banner ES</span>
                  </li>
                  <li>
                    <Icon
                      iconName={
                        this.props.banners.mobileBannerEn
                          ? "CheckMark"
                          : "Cancel"
                      }
                      className={
                        this.props.banners.mobileBannerEn
                          ? "uploadedBanner"
                          : "notBanner"
                      }
                    />
                    <span>Uploaded Mobile Banner EN</span>
                  </li>
                </ul>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <MessagePanel
            type={MessageAlertType.Warning}
            title={this.props.t("AdminPanel.AccesoDen")}
            message={this.props.t("AdminPanel.AccesoDenMjs")}
          />
        )}
      </div>
    );
  }

  private getDropdownLanguageOptions(): IDropdownOption[] {
    let result: IDropdownOption[] = [];
    this.props.i18n.languages.forEach((element) => {
      result.push({ key: element, text: element.toUpperCase() });
    });
    return result;
  }

  private onSubmit() {
    if (document.forms[0]["file"].files.length > 0) {
      var file = document.forms[0]["file"].files[0];
      if (file.type === "image/png") {
        const formData = new FormData(document.forms[0]);
        formData.append("language", this.state.language);
        this.setState({
          showErrorMessageFile: false,
          showErrorMessageFileType: false,
        });
        this.props.uploadBanner(
          formData,
          this.state.language,
          this.props.t("ToastrMessages.Settings.AddBanner")
        );
      } else {
        this.setState({
          showErrorMessageFile: false,
          showErrorMessageFileType: true,
        });
      }
    } else {
      this.setState({ showErrorMessageFile: true });
    }
  }

  private onChangeLanguageDropdown(
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({
      language: option.key.toString(),
    });
  }

  private onChangeFileInput() {
    if (document.forms[0]["file"].files.length > 0) {
      var file = document.forms[0]["file"].files[0];
      if (file.type === "image/png") {
        this.setState({
          showErrorMessageFile: false,
          showErrorMessageFileType: false,
        });
      } else {
        this.setState({
          showErrorMessageFile: false,
          showErrorMessageFileType: true,
        });
      }
    } else {
      this.setState({ showErrorMessageFile: true });
    }
  }

  private onChangeToggle(
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean
  ) {
    let newSettings = createNewSettingsFromOther(this.props.settings);
    const successMessage = this.props.t(
      "ToastrMessages.Settings.UpdateSettings"
    );
    if (this.props.settings.isBannerActive !== checked) {
      newSettings.isBannerActive = checked;
    }

    this.props.updateSettings(newSettings, successMessage);
  }
}

export const AdminBannerPage = translate("common")(AdminBannerComponent);
