import { userApi } from "../../api/userApi";
import User from "../../models/user";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { loadUsersAction } from "./loadUsers";
import * as toastr from 'toastr';

export const saveUserAction = (user: User, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    userApi.AddUserAsync(user).then((result) => {
        dispatch(loadUsersAction());
        dispatch(saveUserCompleted(result));
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });

    const saveUserCompleted = (result: boolean) => ({
        type: actionsDefinition.SAVE_USER,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}