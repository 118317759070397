import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/layout';
import { LayoutStyler } from './components/layoutStyler'
import { LayoutTeams } from './components/layoutTeams';
import { withAuth } from './common/hoc/auth';
import { HomeContainer } from './components/home/homeContainer';
import { AdminPanelContainer } from './components/adminPanel/adminPanelContainer';
import { SendChallengeContainer } from './components/sendChallenge/sendChallengeContainer';
import { ConfigureContainer } from './components/configure/configureContainer';
import { AdminConsentContainer } from './components/adminConsent/adminConsentContainer';
import { UserConsentContainer } from './components/sso/UserConsent';
import { Login } from './components/sso/Login';
import { LoginEnd } from './components/sso/LoginEnd';
import { InitContainer } from './components/sso/Init';

export const routes = (
	<Switch>
		<Route path="/teams" render={({ location }) => (
			<Switch>
				<Route exact path='/teams/' component={LayoutStyler(HomeContainer, LayoutTeams)} />
				<Route exact path='/teams/configure' strict={false} component={LayoutStyler(ConfigureContainer, LayoutTeams)}></Route>
				<Route exact path='/teams/adminConsent' component={LayoutStyler((AdminConsentContainer), LayoutTeams)}></Route>

				<Route exact path='/teams/run' component={LayoutStyler((InitContainer), LayoutTeams)}></Route>
				<Route exact path='/teams/userConsent' component={LayoutStyler((UserConsentContainer), LayoutTeams)}></Route>
				<Route exact path='/teams/login' component={LayoutStyler((Login), LayoutTeams)}></Route>
				<Route exact path='/teams/loginEnd' component={LayoutStyler((LoginEnd), LayoutTeams)}></Route>
			</Switch>
		)} />
		<Switch>
			<Route exact path='/' strict={false} component={LayoutStyler(withAuth(HomeContainer), Layout)}></Route>
			<Route exact path='/adminConsent' strict={false} component={LayoutStyler(withAuth(AdminConsentContainer), Layout)}></Route>
			<Route exact path='/adminPanel' strict={false} component={LayoutStyler(withAuth(AdminPanelContainer), Layout)}></Route>
			<Route exact path='/sendChallenge' strict={false} component={LayoutStyler(withAuth(SendChallengeContainer), Layout)}></Route>
			<Route exact path='/configure' strict={false} component={LayoutStyler(withAuth(ConfigureContainer), Layout)}></Route>
		</Switch>
	</Switch>
);