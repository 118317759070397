import * as React from "react";
import { ITag } from "@fluentui/react";
import Settings from "../../../models/settings";
import { CategoryItem } from "./categoryItem/categoryItem";
import "./categoryContainer.css";

interface categoriesActive {
    name: string;
    key: string | number;
    active: boolean;
}
interface ICategoriesContainerProps {
    categories: ITag[];
    settings: Settings;
    updateSettings: (settings: Settings, successMessage: any) => void;
    settingsCallCompleted: boolean;
    t: any;
}

export const CategoryContainer = (props: ICategoriesContainerProps) => {

    const getCategories = () => {
        let filterCategories = props.categories;
        if (filterCategories === null) {
            return [];
        }

        filterCategories = filterCategories.map((category) => {
            return {
                key: category.key
                    .toString()
                    .replace(/\s+/g, " ")
                    .replace(/^\s+|\s+$/, ""),
                name: category.name.replace(/\s+/g, " ").replace(/^\s+|\s+$/, ""),
                active: props.settings.categoriesDisabled.indexOf(category.name) < 0,
            } as ITag;
        });

        filterCategories = deleteDuplicatedCategories(filterCategories);
        filterCategories = filterCategories.sort((a, b) => {
            return a.key > b.key ? 1 : -1;
        });

        return filterCategories;

    }
    const deleteDuplicatedCategories = (categories) => {
        const filterCategories: any = new Set([...categories]);
        return [...filterCategories];
    }

    return <div className="categoriesContainer">
        {getCategories().map((categoryItem, index) => {
            return (
                <CategoryItem key={index} settings={props.settings}
                    updateSettings={props.updateSettings}
                    settingsCallCompleted={!props.settingsCallCompleted}
                    t={props.t} categoryItem={categoryItem} />
            );
        })}
    </div>
}