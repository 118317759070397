import { levelsApi } from "../../api/levelsApi";
import { actionsDefinition } from '../../actions/actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import { getLevelsAction } from '../Levels/getLevels';
import { store } from '../../store';
import GenericPayload from '../../models/genericPayload';
import LevelSettings from "../../models/levelSettings";
import * as toastr from 'toastr';

export const updateLevelAction = (successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    dispatch(levelApiCallStart());

    const upsertLevel = store.getState().levels.upsertLevel;
    levelsApi.UpdateLevel(upsertLevel).then((result) => {
        dispatch(getLevelsAction());
        dispatch(httpCallEndAction());
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });
}

const levelApiCallStart = () => ({
    type: actionsDefinition.levels.LEVELS_HTTP_CALL_STARTED
});

export const updateLevelProperty = (property: GenericPayload) => (dispatch) => {
    dispatch(handleLevelProperty(property));
};

const handleLevelProperty = (property: GenericPayload) => ({
    type: actionsDefinition.levels.UPDATE_LEVEL_PROPERTY,
    payload: property,
});

export const loadLevelPropertys = (property: LevelSettings) => (dispatch) => {
    dispatch(handleLoadLevelPropertys(property));
};

const handleLoadLevelPropertys = (property: LevelSettings) => ({
    type: actionsDefinition.levels.LOAD_LEVEL_PROPERTYS,
    payload: property,
});
