import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import '@microsoft/mgt';
import Modal from 'react-bootstrap/Modal';
import UserAdmin from "../../models/userAdmin";
import "./css/style.css";
import { MgtBaseComponent } from '@microsoft/mgt/dist/es6/components/baseComponent';
import helloImg from "../../img/hello.png";
import TeamsFunctions from '../../common/functions/teamsFunctions';
import { appUris } from '../../common/constants/appUris';

MgtBaseComponent.useShadowRoot = false;

declare global {
  /* eslint-disable-next-line */
  namespace JSX {
    interface IntrinsicElements {
      'mgt-login': any;
      'mgt-person': any;
      'mgt-msal-provider': any;
      'mgt-tasks': any;
      'mgt-people-picker': any;
    }
  }
}

export interface IConfigurePageProps extends TransProps {
  sendConfigure: (users: UserAdmin[]) => Promise<boolean>;
  hideConfigure: () => void;
  t: any;
}

export interface IConfigurePageState {
  arrayEmail: UserAdmin[];
  show: boolean;
  t: any;
}


class ConfigurePage extends React.Component<RouteComponentProps & IConfigurePageProps, any> {
  constructor(props: any) {
    super(props);
    this.state = ({
      t: I18nextProvider,
      show: true,
      arrayEmail: []
    });
  }

  public render() {

    if (this.props.hideConfigure == undefined) {
      const redirectUrl = TeamsFunctions.IsInTeams() ? appUris.TEAMS : "/";
      this.props.history.push(redirectUrl);
    }

    return (
      <Modal show={true} onHide={() => { }}>
        <Modal.Body>
          <div className="botonCerrar">
            <button className="btn" onClick={this.props.hideConfigure}><i className="fas fa-times"></i></button>
          </div>
          <div className="popUpImage">
            <div className="d-flex flex-column text-center">
              <figure><img className="Personajes-welcome" src={helloImg}
                alt="Personajes ready" /></figure>
              <span className="QueesPlayQuiz text-left">{this.props.t("Configure.Titulo")}</span>
            </div>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <hr />
            <div className="popUpButtons">
              <div>
                <p>{this.props.t("Configure.Enhorabuena")}</p>
                <p>{this.props.t("Configure.Lista.Titulo")}</p>
                <ul className="pl-5">
                  <li>{this.props.t("Configure.Lista.Articulo1")}</li>
                  <li>{this.props.t("Configure.Lista.Articulo2")}</li>
                </ul>
                <p>{this.props.t("Configure.Invitar")}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>);
  }
}

export const Configure = translate('common')((ConfigurePage));
