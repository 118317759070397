import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { Configure } from './configure';
import { sendConfigureAction } from '../../actions/Admin';
import UserAdmin from "../../models/userAdmin";


const mapStateToProps = (state: IStateReducer) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
    sendConfigure: (users: UserAdmin[]) => dispatch(sendConfigureAction(users))

});

export const ConfigureContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(Configure);