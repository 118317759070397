import { PnPFetchClient, msalInstance } from '../common/msal/msal';
import Alert from '../models/alert';
import { appInsights } from '../common/telemetry/appInsights';
import { utils } from '../common/functions/utils';

const baseURL = process.env.REACT_APP_API;

const GetUserAlerts = async (): Promise<Alert[]> => {
    const getUserAlertsURL = baseURL + `/api/alert`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        }
    }
    return client.fetch(getUserAlertsURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return mapToAlerts(result);
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const GetUserUnreadedAlertsNumber = async (): Promise<number> => {
    const getUserUnreadAlertsURL = baseURL + `/api/alert/unreadAlerts`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        }
    }
    return client.fetch(getUserUnreadAlertsURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return result;
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const UpdateAlert = async (alert: Alert): Promise<boolean> => {
    const getUserAlertsURL = baseURL + `/api/alert`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        },
        body: JSON.stringify(alert)
    }
    return client.fetch(getUserAlertsURL, obj)
        .then(async response => {
            if (response.status === 200) {
                var result = await response.json();
                return result;
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

const mapToAlerts = (response: any[]): Alert[] => {
    let result = response != null ? response.map((e) => mapToAlert(e)) : [];
    return result;
}
const mapToAlert = (response: any): Alert => {
    return {
        id: response.id,
        created: response.created,
        message: response.message,
        readed: response.readed,
        tenantId: response.tenantId,
        type: response.type,
        userOrigin: response.userOrigin,
        userTarget: response.userTarget
    };
}

const SetUserAlertRead = async (): Promise<Boolean> => {
    const getUserAlertsURL = baseURL + `/api/alert/alertmarkread`;
    const client = new PnPFetchClient(msalInstance);
    const obj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Demo': utils.isDemo().toString()
        }
    }
    return client.fetch(getUserAlertsURL, obj)
        .then(async response => {
            if (response.status === 200) {
                return true
            }
            var message = await response.text();
            return Promise.reject(message);
        }).catch(error => {
            appInsights.trackException(error);
            return Promise.reject(error);
        });
}

export const alertsApi = {
    GetUserAlerts,
    GetUserUnreadedAlertsNumber,
    UpdateAlert,
    SetUserAlertRead
}