import { PnPFetchClient, msalInstance } from '../common/msal/msal';

const baseURL = process.env.REACT_APP_API + '/api/auth';

const getTokenAsync = (userId: string, tenantId: string): Promise<string> => {
    const authUrl = `${baseURL}/`;

    const apiKey = btoa(`${userId}:${tenantId}`);
    const requestParams = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        }
    };

    return fetch(authUrl, requestParams).then((response) => {
        if (!response.ok) { throw response }
        return response.json();
    }).then(mapToToken);
};
const mapToToken = (data: any): string => {
    return data.token;
}
const mapToTokenGraph = (data: any): string => {
    return data.access_token;
}

const getTokenGraphAsync = (): Promise<string> => {
    const pnp = new PnPFetchClient(msalInstance);
    const authUrl = `${baseURL}/token`;
    const requestParams = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    return pnp.fetch(authUrl, requestParams).then((response) => {
        if (!response.ok) { throw response }
        return response.json();
    }).then(mapToTokenGraph);
};

const getOnBehalfAccessTokenAsync = async (token: string): Promise<string> => {
    const authUrl = `${baseURL}/teams`;
    const requestParams = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    return fetch(authUrl, requestParams).then((response) => {
        if (!response.ok) {
            throw response;
        }
        return response.json();
    }).then(mapToAccessToken);
}

const mapToAccessToken = (data: any): string => {
    return data.access_token;
}


const getTokenGraph2Async = (token: string): Promise<string> => {
    const pnp = new PnPFetchClient(msalInstance);
    const authUrl = `${baseURL}/tokenteams`;
    const requestParams = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(token)
    };

    return pnp.fetch(authUrl, requestParams).then((response) => {
        if (!response.ok) { throw response }
        return response.json();
    }).then(mapToTokenGraph);
};


export const authAPI = {
    getTokenAsync,
    getTokenGraphAsync,
    getTokenGraph2Async,
    getOnBehalfAccessTokenAsync
};






