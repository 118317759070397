import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminQuestions } from './adminQuestions';
import { loadQuestionsAction, saveQuestionAction, loadQuestionCategoriesAction, editQuestionAction, deleteQuestionAction, uploadQuestionsAction } from '../../actions/Questions';
import { GetPublicTopicsAction, getSettingsAction, GetTopicsAction } from '../../actions/Admin';
import Question from '../../models/question';

const mapStateToProps = (state: IStateReducer) => ({
    questions: state.questions,
    categories: state.categories,
    settings: state.settingsState.settings,
    topics: state.topics.topics
});

const mapDispatchToProps = (dispatch: any) => ({
    getQuestions: () => dispatch(loadQuestionsAction()),
    GetTopics: (isPublic: boolean) => dispatch(GetPublicTopicsAction()),
    getQuestionCategories: () => dispatch(loadQuestionCategoriesAction()),
    addQuestion: (question: Question, successMessage: string) => dispatch(saveQuestionAction(question, successMessage)),
    editQuestion: (question: Question, successMessage: string) => dispatch(editQuestionAction(question, successMessage)),
    deleteQuestion: (questionId: string, successMessage: string) => dispatch(deleteQuestionAction(questionId, successMessage)),
    uploadQuestion: (formdata: FormData, successMessage: string, topic: string) => dispatch(uploadQuestionsAction(formdata, successMessage, topic)),
    getSettings: () => dispatch(getSettingsAction())
});

export const AdminQuestionsContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminQuestions);