import * as React from "react";
import { Icon } from "@fluentui/react";
import TeamsFunctions from "../../common/functions/teamsFunctions";

interface IProps {
    showBanner: boolean;
    isBannerActive: boolean;
    location: string;
    language: string;
    hideBanner: () => void;
}

const blobStorageUrl = process.env.REACT_APP_BLOBSTORAGE_URL;

export class BannerComponent extends React.Component<IProps, {}> {
    public render() {
        if (this.props.showBanner && this.props.isBannerActive && !this.props.location.includes('/teams') && !TeamsFunctions.IsInTeams()) {
            return ( 
                <div className="row bannerContainer">
                    <div className="col col-xs-12">
                        <div>
                            <div className="bannerContent">
                                <a target="_blank" href="http://www.teamschamp.com" rel="noopener noreferrer" >
                                    <img className="bannerTC" src={blobStorageUrl + "/banners/desktop_banner_" + this.props.language + ".png"} alt="Banner TeamsChamp" />
                                    <img className="mobileBannerTC" src={blobStorageUrl + "/banners/mobile_banner_" + this.props.language + ".png"} alt="Banner TeamsChamp" />
                                </a>
                            </div>
                            <div className="closeBanner"><Icon iconName="Cancel" onClick={this.props.hideBanner} /></div>
                        </div>
                    </div>
                </div>
            )
        }

        return <></>
    }
}
