import * as React from 'react';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
export interface ILayoutProps {
	children?: React.ReactNode;
}

export class LayoutTeams extends React.Component<ILayoutProps> {
	public render() {
		return (
			<div className="mainContent">
				{this.props.children}
			</div>

		);
	}
}

export default withAITracking(new ReactPlugin(), LayoutTeams);
