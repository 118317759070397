import * as React from "react";
import User from "../../models/user";
import { translate, TransProps } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import LevelSettings from "../../models/levelSettings";
import { GetUserLevel, getValueLevelPercentage, utils } from "../../common/functions/utils";
import { Person } from 'mgt-react';
import './css/style.css';
import pointIcon from '../../img/points-icon.png';
import illustrationImg from '../../img/illustration-07.png';
import Settings from "../../models/settings";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export interface IProjectsProps extends TransProps {
    top5Users: User[];
    topWeekUsers: User[];
    t: any;
    showSendChallenge: () => void;
    levels: LevelSettings[];
    settings: Settings;
}

export interface IProjectsState {
    showGeneral: boolean;
    showWeekly: boolean;
}

class RankingPage extends React.Component<IProjectsProps, IProjectsState> {

    constructor(props: any) {
        super(props);

        this.state = ({
            showGeneral: true,
            showWeekly: false
        })
    }

    public render() {
        const usersTop = this.state.showGeneral ? this.props.top5Users : this.props.topWeekUsers;
        usersTop.sort((a, b) => {
            if (a.TotalPoints === b.TotalPoints) {
                if (a.Email > b.Email) {
                    return 1;
                }
                if (a.Email < b.Email) {
                    return -1;
                }
            }
            return 0;
        });

        return (
            <>
                <div className="btn-ranking d-inline-block">
                    <button type="button" onClick={this.clickTopGeneral} className={this.state.showGeneral ? 'btn-Active' : 'btn-Disanbled'}>{this.props.t("Home.TopGeneral")}</button>
                    <button type="button" onClick={this.clickTopWeekly} className={this.state.showWeekly ? 'btn-Active' : 'btn-Disanbled'}>{this.props.t("Home.TopSemanal")}</button>
                </div>
                <div className="top5">
                    <div className="invite">
                        {usersTop.length > 0 && this.InvitationAllowed() ? <button className="btn btn-danger" onClick={this.props.showSendChallenge}>{this.props.t("Home.Invitar")}</button> : null}
                    </div>
                    {usersTop.length > 0 ?
                        top5(usersTop, this.props.t, this.props.levels, this.state.showWeekly) : top5Vacio(this.props.showSendChallenge, this.props.t, this.InvitationAllowed())
                    }
                </div>
            </>
        );
    }

    private clickTopGeneral = () => {
        this.setState({
            showGeneral: true,
            showWeekly: false
        });
    }

    private clickTopWeekly = () => {
        this.setState({
            showGeneral: false,
            showWeekly: true
        });
    }

    InvitationAllowed = () => {
        if (this.props.settings == null) {
            return true;
        }
        return this.props.settings.disableInvitation == null || !this.props.settings.disableInvitation;
    }

}

const top5 = (users: User[], t: any, levels: LevelSettings[], showWeekly: boolean) => users.map((user, index) => (

    <div className="user-row" key={index}>
        <div className="row">
            <div className="col-1">
                {index < 3 ? <i className="fas fa-medal"></i> : <div className="fueraRanking">#{index + 1}</div>}
            </div>
            <div className={"col-1 user-image aura-user-" + GetUserLevel(levels, user.TotalPoints).color}>
                {user.UrlImage ?
                    <mgt-person person-query="me" person-image={user.UrlImage}></mgt-person>
                    :

                    <CircularProgressbarWithChildren
                        className={"circular aura-user-" + GetUserLevel(levels, user.TotalPoints).color}
                        strokeWidth={8}
                        value={getValueLevelPercentage(GetUserLevel(levels, user.TotalPoints), user.TotalPoints)}
                    >
                        <Person personDetails={{ displayName: user.Name }}></Person>
                    </CircularProgressbarWithChildren>


                }
            </div>
            <div className="col-6 user-name ">
                <div title={user.Name} className="rnk-user-name">{user.Name}</div>
            </div>
            <div className="col-1 ico-puntos">
                <img src={pointIcon} alt="points" />
            </div>
            <div className="col-3 puntos">
                <div> {showWeekly ? user.WeeklyPoints : user.TotalPoints} {t("Home.Puntos")}</div>
            </div>
        </div>
        {index
            < 9 ? <hr /> : ""}
    </div>
));



const top5Vacio = (showSendChallenge: () => void, t: any, invitationAllowed: boolean) => (
    <div className="col invitar">
        <img src={illustrationImg} alt="GrupoRanking" />
        <p>{t("Home.RetarAmigos")}</p>

        {
            invitationAllowed ? <button className="btn btn-danger" onClick={showSendChallenge}>{t("Home.Invitar")}</button> : <p>{t("Home.InvitationDisabled")}</p>
        }

    </div>
);
export const Ranking = withAITracking(new ReactPlugin(), translate('common')((RankingPage)), 'RankingPage', 'ranking container');