import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { RouteComponentProps } from "react-router";
import { authAPI } from "../../api/authApi";
import { actionsDefinition } from "../../actions/actionsDefinition";
import { appUris } from "../../common/constants/appUris";
import { connect } from "react-redux";
import { apiConfig } from "../../config/headersConfig";
import "./css/init.css";
import installImg from "../../img/sso/init-pq-panel.svg";
import { translate, TransProps } from "react-i18next";
import { LoadingComponent } from "../../common/components/Loading/loading";
import TeamsFunctions from "../../common/functions/teamsFunctions";

export interface IProps extends TransProps {
  saveToken: (token) => void;
  t: any;

}
interface IInitState {
  isLoading: boolean;
}

class InitPage extends React.Component<RouteComponentProps<{}> & IProps, IInitState> {

  constructor(props) {
    super(props);
    this.state = { isLoading: true };

  }

  componentDidMount() {
    this.ShowConsentDlg();
  }

  public render() {

    const initContainer = <div className="container">
      <div className="offset-lg-1 col-lg-10" id="pq-panel">
        <div className="row text-center wzr-header n-mp">
          <div className="col-sm-12 col-md-6  div-install-pq n-mp">
            <p className="install-pq">
              <span>1</span>
              {this.props.t("InitPage.Step1Text")}
            </p>
          </div>
          <div className="col-6 d-none d-md-block">
            <p className="install-pq disabled">
              <span>2</span>
              {this.props.t("InitPage.Step2Text")}
            </p>
          </div>
        </div>
        <div className="row wzr-content">
          <div className="col-6 d-none d-md-block  align-self-center text-center">
            <div className="pt-2-p">
              <img src={installImg} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <h2>
              {this.props.t("InitPage.Title")}
            </h2>
            <p>
              {this.props.t("InitPage.Content-pt1")}
            </p>
            <ul id="lst-pq">
              <li>{this.props.t("InitPage.Content-pt2.li1")} </li>
              <li>{this.props.t("InitPage.Content-pt2.li2")} </li>
            </ul>
            <p className="txt-bold">
              {this.props.t("InitPage.Content-pt3")}
            </p>
            <p className="txt-bold">
              {this.props.t("InitPage.Content-pt4")}
            </p>
            <p dangerouslySetInnerHTML={{ __html: this.props.t("InitPage.Content-pt5") }}>

            </p>
            <p className="txt-italic">
              {this.props.t("InitPage.Content-pt6")}
            </p>
            <button onClick={() => this.ShowConsentDlg()} className="btn btn-danger">
              {this.props.t("InitPage.BtnPermission")}
            </button>
          </div>
        </div>
      </div>
    </div>;

    const loadingComponent = <LoadingComponent />
    if (this.state.isLoading) {
      return loadingComponent;
    }
    else {
      return initContainer;
    }
  }

  _toQueryString(queryParams: any) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
      encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }

    return encodedQueryParams.join("&");
  }
  ShowConsentDlg() {
    try {
      microsoftTeams.initialize();
      microsoftTeams.getContext((context: any) => {
        apiConfig.tenantId = context.tid;
        apiConfig.userId = context.userPrincipalName;
        TeamsFunctions.getTeamsLanguage();
        if (context != null) {
          localStorage.setItem("hostClientType", context.hostClientType);
        }

        microsoftTeams.authentication.getAuthToken({
          successCallback: (token) => {
            localStorage.setItem("adal_44", token);
            this.props.saveToken(token);
            authAPI
              .getOnBehalfAccessTokenAsync(token)
              .then((graphToken) => {
                localStorage.setItem("token", graphToken);
                this.props.history.push("/teams");
              })
              .catch((error) => {
                this.setState({ isLoading: false })
                this.props.history.push(appUris.TEAMS_USER_CONSENT);
              });
            microsoftTeams.appInitialization.notifySuccess();
          },
          failureCallback: (error) => {
            this.setState({ isLoading: false })
            console.log(error);
            microsoftTeams.appInitialization.notifyFailure({
              reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
            });

          },
          resources: [process.env.REACT_APP_MSALCLIENTID_URI],
        });
      });

    } catch (error) {
      console.log(error);
    }
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  saveToken: (token) =>
    dispatch({ type: actionsDefinition.GET_TOKEN, payload: token }),
});

export const InitPages = translate("common")(InitPage);
export const InitContainer: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(InitPages);
