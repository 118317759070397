import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import User from "../../models/user";
import startChallengeImg from "../../img/startChallenge.svg";

export interface IProjectsProps extends TransProps {
    hideStartDailyChallenge: () => void;
    t: any;
    showSurvey: (isExtraQuestions: boolean) => void;
    user: User;
    changeUser: (user: User) => Promise<void>;
    lng: string;
}

export interface IProjectsState {
    t: any;
    showStartChallenge: boolean;
}
class ModalCongratsInviteComponent extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            t: I18nextProvider,
            showStartChallenge: true
        });
    }
    private readonly hideFirstChallenge = (open: boolean) => {
        this.setState({
            showStartChallenge: open
        })
    }

    private readonly showSurvey = () => {
        this.hideFirstChallenge(false)
    }

    componentDidMount() {
        
    }

    public render() {
        return (
            <Modal show={this.state.showStartChallenge} onHide={() => { this.hideFirstChallenge(false) }}>
                <Modal.Body>
                    <div className="botonCerrar float-right">
                        <button className="btn" onClick={() => { this.hideFirstChallenge(false) }}><i className="fas fa-times"></i></button>
                    </div>                    
                    <div className="popUpImage">
                        <div className="d-flex flex-column text-center">
                            <figure>
                                <img className="modal-image" src={startChallengeImg} alt="Personajes bienvenida" />
                            </figure>
                            <span className="QueesPlayQuiz text-left">{this.props.t("CongratsInvite.Titulo")} </span>
                        </div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <hr />
                    <div className="popUpButtons">
                        <div>
                            <p>{this.props.t("CongratsInvite.Descripcion")}</p>
                        </div>
                    </div>                    
                    <div>
                        <div className="footer-buttons center">
                            <div className="">
                                <button type="button" className="btn" onClick={this.showSurvey}>
                                    {this.props.t("CongratsInvite.Aceptar")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export const ModalCongratsInvite = withAITracking(new ReactPlugin(), translate('common')((ModalCongratsInviteComponent)));