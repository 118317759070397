import UserPosition, { createEmptyUserPosition } from './userPosition';
import userConection, { createEmptyUserConnection } from './userConnection';
import userSetting from './userSetting';

export default interface User {
    Id: string;
    TenantId: string;
    Name: string;
    Email: string;
    UrlImage: string;
    Role: string;
    Level: number;
    TotalPoints: number;
    DailyPoints: number;
    WeeklyPoints: number;
    UserPosition?: UserPosition;
    UserConnection?: userConection;
    UserSettings?: userSetting;
}

export const createEmptyUser = () => {
    let newUser: User = {
        Id: '',
        TenantId: '',
        Name: '',
        Email: '',
        UrlImage: '',
        Role: '',
        Level: 1,
        TotalPoints: 0,
        DailyPoints: 0,
        WeeklyPoints: 0,
        UserPosition: createEmptyUserPosition(),
        UserConnection: createEmptyUserConnection(),
        UserSettings: {} as userSetting
    };

    return newUser;
};