import { actionsDefinition } from '../actionsDefinition';

export const saveAdminConsentSelectedAction = () => (dispatch) => {
    dispatch(handleSaveAdminConsentSelected(true));
};

const handleSaveAdminConsentSelected = (optionSelected: boolean) => ({
    type: actionsDefinition.SAVE_ADMIN_CONSENT_OPTION_SELECTED,
    payload: optionSelected,
});
