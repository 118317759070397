import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminBannerPage } from './adminBanner';
import { uploadBannerAction, checkUploadedBannersAction } from '../../actions/Banner';
import { updateSettingsAction } from '../../actions/Admin';
import Settings from '../../models/settings';

const mapStateToProps = (state: IStateReducer) => ({
    uploadBannerCallCompleted: state.settingsState.bannerHttpCallComplete,
    banners: state.uploadedBanners,
    settings: state.settingsState.settings,
    settingsCallCompleted: state.settingsState.settingsHttpCallComplete,
});

const mapDispatchToProps = (dispatch: any) => ({
    uploadBanner: (file: FormData, language: string, successMessage: string) => dispatch(uploadBannerAction(file, language, successMessage)),
    checkUploadedBanners: () => dispatch(checkUploadedBannersAction()),
    updateSettings: (settings: Settings, successMessage: string) =>  dispatch(updateSettingsAction(settings, successMessage))
});

export const AdminBannerContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminBannerPage);