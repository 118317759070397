import { settingsApi } from "../../api/settingsApi";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction, httpCallEndAction } from "../../middlewares/http/actions";
import Settings from "../../models/settings";
import * as toastr from 'toastr';

export const updateSettingsAction = (settings: Settings, successMessage: string) => (dispatch: any) => {
    dispatch(httpCallStartAction());
    dispatch(settingsApiCallStart());

    settingsApi.UpdateSettings(settings).then((result) => {
        dispatch(updateSettingsCompleted(result));
        dispatch(httpCallEndAction());
        toastr.success(successMessage, '', { timeOut: 3000 });
    }).catch(error => {
        toastr.error(error);
    });

}

const updateSettingsCompleted = (settings: Settings) => ({
    type: actionsDefinition.settings.UPDATE_SETTINGS_COMPLETED,
    payload: settings
});

const settingsApiCallStart = () => ({
    type: actionsDefinition.settings.SETTINGS_HTTP_CALL_STARTED
});