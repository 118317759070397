import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { Header } from './header';
import { loadCurrentUserAction } from '../../actions/Home/loadCurrentUser';
import { getUserAlerts, getUserUnreadAlertsNumber, editAlert, setUserAlertReadAction } from '../../actions/Alerts';
import Alert from '../../models/alert';
import { loadDailyQuestionsAction } from '../../actions/Questions';
import { getSettingsAction } from '../../actions/Admin';

const mapStateToProps = (state: IStateReducer) => ({
    user: state.currentUser.currentUser,
    alerts: state.alerts.alerts,
    unreadAlerts: state.alerts.unreadAlerts,
    settings: state.settingsState.settings
});

const mapDispatchToProps = (dispatch: any) => ({
    getUserUnreadAlerts: () => dispatch(getUserUnreadAlertsNumber()),
    getCurrentUser: async () => dispatch(await loadCurrentUserAction()),
    getUserAlerts: () => dispatch(getUserAlerts()),
    updateAlert: (alert: Alert) => dispatch(editAlert(alert)),
    loadDailyQuestionsAction: () => dispatch(loadDailyQuestionsAction()),
    getSettings: () => dispatch(getSettingsAction()),
    markAsRead: () => dispatch(setUserAlertReadAction())
});

export const HeaderContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
