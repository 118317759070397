import { actionsDefinition } from '../actions/actionsDefinition';
import SendChallengeModel, { InitSendChallenge } from '../models/sendChallenge'

export const sendChallengeReducer = (state: SendChallengeModel = InitSendChallenge(), action: any) => {
    switch (action.type) {
        case actionsDefinition.SEND_CHALLENGE:
            return handleSendChallengeCompleted(state, action.payload);     
    }
    return state;
};

const handleSendChallengeCompleted = (state: SendChallengeModel, payload: SendChallengeModel) => {
    return payload;
};