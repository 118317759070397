import { actionsDefinition } from '../actions/actionsDefinition';
import Topic from '../models/topic';


export interface TopicState {
    topics: Topic[];
    isLoading: boolean
}

const initialState: TopicState = {
    topics: [],
    isLoading: true
};


export const TopicsReducer = (state: TopicState = initialState, action: any) => {
    switch (action.type) {
        case actionsDefinition.GET_TOPICS:
            return {
                ...state,
                isLoading: false,
                topics: action.payload
            }
            
        case actionsDefinition.LOADING_TOPICS:
            return {
                ...state,
                isLoading: true
            }

    }
    return state;
};

