import * as React from "react";
import { Icon } from "@fluentui/react";

export interface ITabProps {
  className: string;
  icon: string;
  text: string;
  parent: any;
  page: string;
  claseActiva: any;
  child: any;
}
export const TabComponent = function (props: ITabProps) {
  const changePage = (page: string) => {
    props.parent.setState({
      questionActive: false,
      userActive: false,
      settingsActive: false,
      levelsActive: false,
      bannerActive: false,
      topicActive: false,
      children: null,
    });

    props.parent.setState({
      [page.toLowerCase() + "Active"]: true,
      children: props.child,
    });
  };
  return (
    <li className="nav-item">
      <p
        className={"btn admin-panel-Menu-link " + props.className}
        onClick={(event) => changePage(props.page)}
      >
        {props.icon.length > 0 ? <Icon iconName={props.icon} /> : null}
        {props.text}
      </p>
    </li>
  );
};
