import { questionApi } from "../../api/questionApi";
import Question from "../../models/question";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";

export const loadQuestionsAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());
    questionApi.GetQuestionsAsync().then((result) => {
        dispatch(loadQuestionCompleted(result));
    })

    const loadQuestionCompleted = (result: Question[]) => ({
        type: actionsDefinition.LOAD_QUESTIONS,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}