export default interface SendChallengeModel {
    EmailsChallenge: { Mail: string, Name: string }[];
    Language: string;
}

export const InitSendChallenge = () => {
    let newChallenge: SendChallengeModel = {
        EmailsChallenge: [],
        Language: "en"
    };
    return newChallenge;
};