import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminSettings } from './adminSettings';
import { GetPublicTopicsAction, getSettingsAction, GetTopicsAction, updateSettingsAction } from '../../actions/Admin';
import { loadQuestionCategoriesAction } from '../../actions/Questions';
import Settings from '../../models/settings';


const mapStateToProps = (state: IStateReducer) => ({
    settings: state.settingsState.settings,
    categories: state.categories,
    settingsCallCompleted: state.settingsState.settingsHttpCallComplete,
    topics: state.topics.topics
});

const mapDispatchToProps = (dispatch: any) => ({
    getSettings: async () => await dispatch(getSettingsAction()),
    updateSettings: (settings: Settings, successMessage) => dispatch(updateSettingsAction(settings, successMessage)),
    getQuestionCategories: () => dispatch(loadQuestionCategoriesAction()),
    GetTopics: (isPublic: boolean) => dispatch(GetPublicTopicsAction()),
});

export const AdminSettingsContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminSettings);