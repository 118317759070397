import { actionsDefinition } from '../actions/actionsDefinition';
import User from '../models/user';

export const topWeekUsersReducer = (state: User[] = [], action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_TOPWEEK_USERS:
            return handleTopWeekUsersCompleted(state, action.payload);
    }
    return state;
};

const handleTopWeekUsersCompleted = (state: User[], payload: User[]) => {
    return payload;
};