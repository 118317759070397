import { actionsDefinition } from '../actions/actionsDefinition';
import user from '../models/user';

export const userReducer = (state: user[] = [], action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_USERS:
            return handleLoadUsersCompleted(state, action.payload);
    }
    return state;
};

const handleLoadUsersCompleted = (state: user[], payload: user[]) => {
    return payload;
}