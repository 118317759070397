import * as React from "react";
import './css/style.css';

export interface IMessagePanelProps {
  type: string;
  title: string;
  message: string;
}

export const MessagePanel = function (props: IMessagePanelProps) {
  return (
    <>
      <div className={"admin-no-access alert alert-" + props.type} role="alert">
        <span>{props.title}</span>
        <p>{props.message}</p>
      </div>
    </>
  );
};
