import * as React from "react";
import { DailyQuestionsContainer } from "../dailyQuestions/dailyQuestionsContainer";
import { translate, TransProps, I18nextProvider } from 'react-i18next';
import User from "../../models/user";
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights } from "../../common/telemetry/appInsights";
import playersImg from "../../img/jugadores.png";
import pointIcon from '../../img/points-icon.png';

export interface IProjectsProps extends TransProps {
    completedReto: (points: number, extraPoints: number) => void;
    t: any;
    user: User;
}

export interface IProjectsState {
    showSurvey: boolean;
    t: any;
    allPoints: number;
}

const configQuestionSuccessPoints = parseInt(process.env.REACT_APP_QUESTION_SUCCESS_POINTS);
const configExtraDailyPoints = parseInt(process.env.REACT_APP_QUESTION_EXTRA_DAILY_POINTS);
const configStreakPoints = parseInt(process.env.REACT_APP_QUESTION_STREAK_POINTS);

class EmpezarReto extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: any) {
        super(props);

        this.state = ({
            showSurvey: false,
            t: I18nextProvider,
            allPoints: 0,
        });

        this.closePanel = this.closePanel.bind(this);
        this.showSurvey = this.showSurvey.bind(this);
    }

    private closePanel(points: number, extraPoints: number) {
            this.setState({
                showSurvey: false
            });
            this.props.completedReto(points, extraPoints);
    }

    private cancel() {
        this.setState({
            showSurvey: false
        });
    }

    private calcularPuntos() {
        let points = configQuestionSuccessPoints * 3 + configExtraDailyPoints;
        if (new Date().getDay() === 5 && this.props.user.UserConnection.StreakActive) {
            points += configStreakPoints;
        }
        return points;
    }

    private showSurvey() {
        let startTime = Date.now();
        this.setState({ showSurvey: true })
        let duration = Date.now() - startTime;
        appInsights.trackEvent({ name: "Empezar reto", properties: { user: this.props.user.Name, tenant: this.props.user.TenantId }, measurements: { duration: duration } });
    }
    private esFinde() {
        let dia = new Date();
        return dia.getDay() === 6 || dia.getDay() === 7;
    }
    public render() {
        return (
            <span>
                <div className="row">
                    <div className="col">
                        <img src={playersImg} alt="reto" />
                        {this.esFinde() ? <div><div>{this.props.t("Home.NoDisponible")}</div><div>{this.props.t("Home.NuevoReto")}</div></div>
                            : <button className="btn btn-danger" onClick={() => { this.showSurvey() }}>{this.props.t("Home.Reto")}</button>}
                    </div>
                    {this.esFinde() ? <div></div>
                        :
                        <div className="col-3">
                            <div className="row">
                                <img src={pointIcon} alt="points" />
                                <h4>+{this.calcularPuntos()}</h4>
                            </div>
                        </div>}
                </div>
                {this.state.showSurvey ? <DailyQuestionsContainer showSurvey={this.state.showSurvey} cancel={() => { this.cancel() }} closePanel={(points: number, extraPoints: number) => { this.closePanel(points, extraPoints) }} /> : null}
            </span>
        );
    }
}
export const Reto = withAITracking(new ReactPlugin(), translate('common')((EmpezarReto)), 'EmpezarReto', "empezarReto container");