export const msalAuthConfig = {
	authority: process.env.REACT_APP_MSALAUTHORITY,
	clientId: process.env.REACT_APP_MSALCLIENTID,
	postLogoutRedirectUri: window.location.origin
}
export const msalCacheConfig = {
	storeAuthStateInCookie: true,
	CacheLocation: 'localStorage'
};
export const msalConfig = {
	auth: msalAuthConfig,
	cache: msalCacheConfig
};
