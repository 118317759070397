import * as React from "react";
import { Button } from "../../common/components/button/button";
import i18next from "i18next";
import { LANG_RESOURCES } from "../../i18n";

export const QuestionComponent = function (props) {
  return (
    <>
      <tr className="row">
        <th className="col-6 col-sm-5 col-lg-2">
          <div className="pl-2 questionText">
            <p>{props.question.Title}</p>
          </div>
        </th>
        <th className="col-2 align-items-center d-none d-lg-block">
          <span className="pl-2">{props.question.Topic}</span>
        </th>
        <th className="col-2 align-items-center d-none d-lg-block">
          <span className="pl-2">{props.question.Category}</span>
        </th>
        <th className="col-2 align-items-center d-none d-lg-block">
          <span className="pl-2">{props.question.Difficulty}</span>
        </th>
        <th className="col-1 align-items-center d-none d-lg-block">
          {activeCategoria(props.question, props)}
        </th>
        <th className="col-1 align-items-center d-none d-sm-block  col-sm-1 col-lg-1">
          <div className="d-lg-none ">
            <p className="pl-2">{props.question.Category}</p>
            {activeCategoria(props.question, props)}
            <p className="pl-2">
              {props.text("AdminQuestion.Dificultad.Titulo")}:
              <span> {props.question.Difficulty}</span>
            </p>
          </div>
          <span className="pl-2">
            {LANG_RESOURCES[i18next.language].lng[props.question.Locale?.toLocaleLowerCase() || "en"]}
          </span>
        </th>


        <th className="col-3 d-flex align-items-center  col-sm-4 col-lg-2">
          {props.question.TenantId !== "common" ? (
            <Button
              clickEvent={() => {
                props.showEditQuestionModal(props.question);
              }}
              icon={"fa-edit"}
              title={props.text("AdminQuestion.Botones.Titulos.Editar")}
              text={""}
            />
          ) : (
            <Button
              icon={"fa-edit"}
              title={props.text("AdminQuestion.Botones.Titulos.Editar")}
              text={""}
              className={"disabled"}
            />
          )}
          {props.question.TenantId !== "common" ? (
            <Button
              icon={"fa-power-off"}
              title={
                props.question.Active
                  ? props.text("AdminQuestion.Botones.Titulos.Desactivar")
                  : props.text("AdminQuestion.Botones.Titulos.Activar")
              }
              text={""}
              clickEvent={() => {
                props.showActiveQuestionModal(props.question);
              }}
            />
          ) : (
            <Button
              icon={"fa-power-off"}
              title={
                props.question.Active
                  ? props.text("AdminQuestion.Botones.Titulos.Desactivar")
                  : props.text("AdminQuestion.Botones.Titulos.Activar")
              }
              text={""}
              className={"disabled"}
            />
          )}
          {props.question.TenantId !== "common" ? (
            <Button
              icon={"fa-trash-alt"}
              title={props.text("AdminQuestion.Botones.Titulos.Borrar")}
              text={""}
              clickEvent={() => {
                props.showDeleteQuestionModal(props.question);
              }}
            />
          ) : (
            <Button
              icon={"fa-trash-alt"}
              title={props.text("AdminQuestion.Botones.Titulos.NoBorrar")}
              text={""}
              className={"disabled"}
            />
          )}
        </th>
      </tr>
    </>
  );
};

const activeCategoria = (question, props) => {
  var result: JSX.Element = null;
  if (question.Active) {
    let category = props.settings.categoriesDisabled?.find(
      (e) => e == question.Category//.replace("  ", " ")
    );

    if (category) {
      result = (
        <span className="pl-2 text-warning">
          {props.text("AdminQuestion.Estado.CategoriaDesactivada")}
        </span>
      );
    } else {
      result = (
        <span className="pl-2 text-success">
          {props.text("AdminQuestion.Estado.Activo")}
        </span>
      );
    }
  } else {
    result = (
      <span className="pl-2 text-danger">
        {props.text("AdminQuestion.Estado.Inactivo")}
      </span>
    );
  }

  return result;
};
