import { challengeApi } from "../../api/challengeApi";
import SendChallenge from "../../models/sendChallenge";
import { actionsDefinition } from '../actionsDefinition';

export const sendChallengeAction = (sendChallenge: SendChallenge) => (dispatch: any) => {
    challengeApi.SendChallengeAsync(sendChallenge).then((result) => {
        dispatch(sendChallengeCompleted(result));
    })

    const sendChallengeCompleted = (result: boolean) => ({
        type: actionsDefinition.SEND_CHALLENGE,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}

