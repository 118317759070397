import { connect } from 'react-redux';
import { IStateReducer } from '../../reducers';
import { AdminLevels } from './adminLevels';
import { getLevelsAction } from '../../actions/Levels/getLevels';
import { updateLevelAction, updateLevelProperty, loadLevelPropertys, addLevelAction, deleteLevelAction, clearUpsertLevelAction } from '../../actions/Levels';
import GenericPayload from '../../models/genericPayload';
import LevelSettings from '../../models/levelSettings';

const mapStateToProps = (state: IStateReducer) => ({
    levels: state.levels.levels,
    upsertLevel: state.levels.upsertLevel,
    levelsCallCompleted: state.levels.levelsHttpCallComplete
});

const mapDispatchToProps = (dispatch: any) => ({
    getLevels: () => dispatch(getLevelsAction()),
    updateLevels: (successMessage: string) => dispatch(updateLevelAction(successMessage)),
    addLevels: (successMessage: string) => dispatch(addLevelAction(successMessage)),
    deleteLevels: (levelId: string, successMessage: string) => dispatch(deleteLevelAction(levelId, successMessage)),
    clearUpsertLevel: () => dispatch(clearUpsertLevelAction()),
    updateLevelProperty: (genericPayload: GenericPayload) => dispatch(updateLevelProperty(genericPayload)),
    loadLevelPropertys: (level: LevelSettings) => dispatch(loadLevelPropertys(level))
});

export const AdminLevelsContainer: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminLevels);
