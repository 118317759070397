import { actionsDefinition } from '../actions/actionsDefinition';
import DailyQuestion from '../models/dailyQuestion';

export interface dailyQuestionState {
    dailyQuestion: DailyQuestion[];
    extraQuestions: DailyQuestion[];
    loadingQuestions: boolean;
    isDailyQuestionEmpty: boolean;
}
const initialState: dailyQuestionState = {
    dailyQuestion: [] as DailyQuestion[],
    extraQuestions: [],
    loadingQuestions: true,
    isDailyQuestionEmpty: false
}

export const dailyQuestionReducer = (state: dailyQuestionState = initialState, action: any) => {
    switch (action.type) {
        case actionsDefinition.LOAD_DAILY_QUESTIONS:
            return handleDailyQuestionCompleted(state, action.payload);
    }
    return state;
};

const handleDailyQuestionCompleted = (state: dailyQuestionState, payload: DailyQuestion[]) => {
    return {
        ...state,
        dailyQuestion: payload.slice(0, 3),
        extraQuestions: payload.slice(3, payload.length),
        isDailyQuestionEmpty: payload.length == 0 ,
        loadingQuestions: false
    };
}

