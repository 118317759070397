import { configureApi } from "../../api/configureApi";
import { actionsDefinition } from '../actionsDefinition';
import UserAdmin from "../../models/userAdmin";


export const sendConfigureAction = (admins: UserAdmin[]) => (dispatch: any) => {
    configureApi.SendConfigureAsync(admins).then((result) => {
        dispatch(sendConfigureCompleted(result));
    })

    const sendConfigureCompleted = (result: boolean) => ({
        type: actionsDefinition.SEND_CHALLENGE,
        payload: result,
        meta: {
            httpEnd: true
        }
    });
}
