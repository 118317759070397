import DailyQuestion from "../../models/dailyQuestion";
import { actionsDefinition } from '../actionsDefinition';
import { httpCallStartAction } from "../../middlewares/http/actions";
import { questionApi } from "../../api/questionApi";

export const loadDailyQuestionsAction = () => (dispatch: any) => {
    dispatch(httpCallStartAction());

    questionApi.GetDailyQuestionsAsync().then((result) => {
        if (result.length == 0)
        {
            dispatch(IsDailyQuestionsEmpty(true));
        }
        else{
            dispatch(IsDailyQuestionsEmpty(false));
        }
        dispatch(loadDailyQuestionsCompleted(result));
    }).catch(error => {

    });
}

const loadDailyQuestionsCompleted = (result: DailyQuestion[]) => ({
    type: actionsDefinition.LOAD_DAILY_QUESTIONS,
    payload: result,
    meta: {
        httpEnd: true
    }
});

const IsDailyQuestionsEmpty = (IsDailyQuestionsEmpty: boolean) => ({
    type: actionsDefinition.IS_QUESTION_EMPTY,
    payload: IsDailyQuestionsEmpty,
});