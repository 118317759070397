import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../../common/components/button/button";
import { Input } from "../../../common/components/input/input";
import { Select } from "../../../common/components/select/select";
import Topic from "../../../models/topic";

interface IModalProps {
  isShown: boolean;
  onHide: Function;
  close: Function;
  pageText: any;
  ReturnFn: Function;
  selectedTopic: Topic;
  markedAsDefault: boolean;
  actionButtonText: string;
}

const TopicModal = (props: IModalProps) => {
  const [title, setTitle] = useState("");
  const [isPublic, setIsPublic] = useState("-1");
  const [isByDefault, setIsByDefault] = useState(props.markedAsDefault);

  

  var selectOptions = [
    {
      text: props.pageText.IsPublic,
      value: "1",
    },
    {
      text: props.pageText.IsNotPublic,
      value: "0",
    },
  ];
  const ReturnTopic = () => {
    props.ReturnFn(title || props.selectedTopic.title, isPublic == "-1" ? props.selectedTopic.isPublic ? "1" : "0" : isPublic, isByDefault);
  };

  return (
    <Modal
      show={props.isShown}
      onHide={() => {
        props.onHide(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className=" d-flex justify-content-between w-100">
          <div>{props.pageText.Title}</div>
          <div className="botonCerrar">
            <button
              className="btn"
              onClick={() => {
                props.close();
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-modal-body">
        <div>{props.pageText.Body}</div>

        <div>
          <div>{props.pageText.FieldTitle}</div>
          <Input
            type="text"
            className="form-control"
            changeEvent={(e) => setTitle(e.target.value)}
            value={title || props.selectedTopic.title}
          />
        </div>
        <div>
          <div>{props.pageText.FieldPublic}</div>
          <Select
            lstOptions={selectOptions}
            changeEvent={(e) => setIsPublic(e.target.value)}
            selectedValue={props.selectedTopic.isPublic? "1" :"0"}
            className="form-control"
          />
        </div>

        <div>
          <div>{props.pageText.FieldByDefault}</div>
          <Select
            lstOptions={selectOptions}
            changeEvent={(e) => setIsByDefault(e.target.value == "1")}
            selectedValue={props.markedAsDefault? "1" :"0"}
            className="form-control"
          />
        </div>
        <div>
          <Button
            text={props.actionButtonText}
            className={"btn-primary custom  mb-1"}
            clickEvent={ReturnTopic}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TopicModal;
