export default interface Answer {
    Id: string;
    Value: string;
}

export const initAnswer = (): Answer => {
    return {
        Id: "",
        Value: ""
    }
}