import * as React from "react";
import { Button } from "../../../common/components/button/button";
import { Input } from "../../../common/components/input/input";
import ImagePickerComponent from "../../../common/components/ImagePickerComponent/ImagePickerComponent";
import { ColorPicker, IColor } from "@fluentui/react";
import "./customization.css";
interface ICustomizationProps {
  onCompleteEvent: Function;
  pageText: any;
  logo: string;
  color: string;
}

const Customization = (props: ICustomizationProps) => {
  const [logo, setLogo] = React.useState(props.logo);
  const [color, setColor] = React.useState(props.color);
  const [showCustom, setShowCustom] = React.useState(false);

  const onchangeHeaderColor = (
    ev: React.SyntheticEvent<HTMLElement>,
    color: IColor
  ) => {
    setColor(color.str);
  };
  const UploadedImage = (newLogo) => {
    setLogo(newLogo);
    props.onCompleteEvent("logo", newLogo);
  };

  const GetColorPickerSection = () => {
    return (
      <div className="color-picker-zone">
        <ColorPicker
          showPreview={true}
          alphaType="none"
          color={color}
          onChange={onchangeHeaderColor}
        />
        <div className="color-picker-buttons">
          <Button
            className="btn-primary"
            clickEvent={() => {
              props.onCompleteEvent("color", color);
              setShowCustom(false);
            }}
            text={props.pageText.ApplyButtonText}
          />

          <Button
            className="btn-primary"
            clickEvent={() => setShowCustom(!showCustom)}
            text={props.pageText.CustomColorBtnCancel}
          />
        </div>
      </div>
    );
  };

  const GetDefaultColorSection = () => {
    const defaultColors = [
      "#517631",
      "#78aa3a",
      "#6bac91",
      "#6da7d9",
      "#454f97",
      "#61438e",
      "#8a4d93",
      "#a11c3b",
    ];
    const defaulColorSection = (
      <>
        <div className="default-colors">
          {defaultColors.map((color: string, index: number) => {
            return (
              <div
                key={index}
                className="default-color-item"
                style={{ backgroundColor: color }}
                onClick={() => {
                  props.onCompleteEvent("color", color);
                }}
              ></div>
            );
          })}
        </div>
        <Button
          className="btn-primary"
          clickEvent={() => setShowCustom(!showCustom)}
          text={
            showCustom
              ? props.pageText.CustomColorBtnCancel
              : props.pageText.CustomColorBtn
          }
        />
      </>
    );
    return defaulColorSection;
  };
  return (
    <>
      <div className="settingsSection">
        <div>
          <div className="titleSection">
            <div className="title">{props.pageText.Title}</div>
          </div>
          <div className="description">{props.pageText.Description}</div>

          <div className="mainSection">
            <div className="logo-section">
              <div className="subSectionTitle">{props.pageText.LogoText}</div>
              <div className="description">
                {props.pageText.LogoDescription}
                <br />
                {props.pageText.LogoDetails}
              </div>
              <ImagePickerComponent
                deleteText={props.pageText.DeleteLogoTooltip}
                uploadText={props.pageText.LogoText}
                changeEvent={UploadedImage}
                imgDefault={logo}
                modalText={props.pageText.DeleteModal}
              />
            </div>
            <div>
              <div className="color-section">
                <div className="subSectionTitle">
                  {props.pageText.ColorText}
                </div>
                <div className="description">
                  {props.pageText.ColorDescription}
                </div>
                <div className="colorZone">
                  {showCustom
                    ? GetColorPickerSection()
                    : GetDefaultColorSection()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customization;
