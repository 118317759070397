import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { translate, TransProps, I18nextProvider } from "react-i18next";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import startExtraChallengeImg from "../../img/startExtraChallenge.svg";
import pointIcon from "../../img/points-icon.png";
import ExtraordinaryQuestionsSettings from "../../models/extraordinaryQuestionsSettings";

// const extraAnswerCorrectValue = parseInt(process.env.REACT_APP_EXTRA_QUESTION_SUCCESS_POINTS);
export interface IProjectsProps extends TransProps {
  hideStartExtraChallenge: () => void;
  t: any;
  showSurvey: (isExtraQuestions: boolean) => void;
  questionsNumber: number;
  extraordinaryQuestionsSettings: ExtraordinaryQuestionsSettings;
}

export interface IProjectsState {
  t: any;
  showStartChallenge: boolean;
  hideModalToday: boolean;
}
class StartExtraChallengePage extends React.Component<
  IProjectsProps,
  IProjectsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      t: I18nextProvider,
      showStartChallenge: true,
      hideModalToday: false,
    };
  }
  private readonly hideExtraChallenge = (open: boolean) => {
    this.setState({
      showStartChallenge: open,
    });
  };

  private readonly showSurvey = () => {
    this.hideExtraChallenge(false);
    this.props.showSurvey(true);
  };
  private onChangeCheckbox = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean
  ) => {
    this.setState({ hideModalToday: checked });
  };

  public render() {
    var extraAnswerCorrectValue =
      this.props.extraordinaryQuestionsSettings.extraPointsSuccess;

    return (
      <Modal
        show={this.state.showStartChallenge}
        onHide={() => {
          this.hideExtraChallenge(false);
        }}
      >
        <Modal.Body>
          <div className="botonCerrar float-right">
            <button
              className="btn"
              onClick={() => {
                this.hideExtraChallenge(false);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="popUpImage">
            <div className="d-flex flex-column text-center">
              <figure>
                <img
                  className="modal-image"
                  src={startExtraChallengeImg}
                  alt="Diana"
                />
              </figure>
              <span className="QueesPlayQuizz text-left">
                {this.props.t("StartExtraChallenge.Titulo")}{" "}
              </span>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <hr />
          <div className="popUpButtons">
            <div>
              <p>{this.props.t("StartExtraChallenge.Descripcion")}</p>
            </div>
          </div>
          <div className="daily-points">
            <div className="points-icon">
              <img src={pointIcon} alt="points" />
            </div>
            <span className="points">
              +{this.props.questionsNumber * extraAnswerCorrectValue}
            </span>
          </div>
          <div>
            <div className="footer-buttons center">
              <div className="">
                <button type="button" className="btn" onClick={this.showSurvey}>
                  {this.props.t("StartExtraChallenge.Aceptar")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export const StartExtraChallenge = withAITracking(
  new ReactPlugin(),
  translate("common")(StartExtraChallengePage)
);
